import "./App.css";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AppWork from "./AppWork";
import User from "./models/user";
import localStorageService from "./services/localStorageService";
import httpService from "./services/httpService";
import userService from "./services/userService";
import UserContext from "./contexts/userContext";
import ProtectedRoute from "./components/common/ProtectedRoute";
import Unauthorized from "./pages/Unauthorized";
import Logout from "./pages/Logout";
import ExternalSurveyEdit from "./pages/ExternalSurveryEdit";
import HomeOld from "./pages/HomeOld";
import About from "./pages/About";

const App = () => {
  const [isLoggedin, setIsLoggedin] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [isInitalized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (isInitalized) {
      return;
    }

    console.log("appInit");

    setIsInitialized(true);

    const init = async () => {
      // on app reload (browser refresh) on a different page

      const accessToken = localStorageService.getAccessToken();
      if (accessToken) {
        httpService.setAccessToken(accessToken);

        setIsLoggedin(true);

        // on refresh show an empty screen istead of showing the login screen for a bit until the loggedin user info is loaded
        setIsLoadingUser(true);

        try {
          const user = await userService.getLoggedinUser();
          setUser(user);
        } catch (ex) {
          // got an authorized 403 response as the username was renamed and was still in the localstorage with old username
          userService.logout();
        }

        setIsLoadingUser(false);

        // return to page were it came from on browser refresh
        navigate(location.pathname);
      }
    };

    init();
  }, [isInitalized, location, navigate]);

  const onLoginOk = (user: User) => {
    setIsLoggedin(true);
    httpService.setAccessToken(localStorageService.getAccessToken());
    setUser(user);
  };

  const onLogout = () => {
    setIsLoggedin(false);
  };

  if (isLoadingUser) {
    return <div></div>;
  }

  return (
    <UserContext.Provider value={user}>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<Login onLoginOk={onLoginOk} />} />
        <Route path="/logout" element={<Logout onLogout={onLogout} />} />
        <Route
          path="/work/*"
          element={
            <ProtectedRoute isLoggedin={isLoggedin}>
              <AppWork />
            </ProtectedRoute>
          }
        />
        <Route path="/surveys/:code" element={<ExternalSurveyEdit />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </UserContext.Provider>
  );
};

export default App;
