const dateFormat = "MMM D YYYY";
const dateTimeFormat = "MMM D YYYY h:mm:ss A";

const dateDiffNowUtc = (diffToDateUtc: Date | undefined) => {
  if (!diffToDateUtc) {
    return undefined;
  }

  const date = new Date(diffToDateUtc + "Z").getTime();
  const now = new Date().getTime();

  var diff = date - now;

  var ms = diff;

  var dd = Math.floor(ms / 1000 / 60 / 60 / 24);
  ms -= dd * 1000 * 60 * 60 * 24;
  var hh = Math.floor(ms / 1000 / 60 / 60);
  ms -= hh * 1000 * 60 * 60;
  var mm = Math.floor(ms / 1000 / 60);
  ms -= mm * 1000 * 60;
  var ss = Math.floor(ms / 1000);
  ms -= ss * 1000;

  return `${dd} Days ${hh} Hours ${mm} Minutes`;
  // return `${dd} Days ${hh} Hours ${mm} Minutes ${ss} Seconds`;
};

const isDateTimeValue = (value: any): boolean => {
  // 2022-05-18T15:28:42.8458688
  const dateRegexDateTime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
  const isDateValue = dateRegexDateTime.test(value);

  return isDateValue;
};

const isDateOnlyValue = (value: any): boolean => {
  // 10/02/2022
  const dateRegexDate = /^\d{2}\/\d{2}\/\d{4}/;
  const isDateValue = dateRegexDate.test(value);
  return isDateValue;
};

const dateDiffNowDays = (date: Date) => {
  const dateNow = new Date(Date.now());
  const date1 = Date.UTC(
    dateNow.getFullYear(),
    dateNow.getMonth(),
    dateNow.getDate()
  );
  const date11 = new Date(date1);

  const date21 = new Date(date);

  return Math.round(
    (date21.getTime() - date11.getTime()) / (1000 * 60 * 60 * 24)
  );
};

const dateTimeHelper = {
  dateFormat,
  dateTimeFormat,
  dateDiffNowUtc,
  isDateTimeValue,
  isDateOnlyValue,
  dateDiffNowDays,
};

export default dateTimeHelper;
