const setLoginData = (accessToken: string) => {
  localStorage.setItem("accessToken", accessToken);
  //localStorage.setItem("user", JSON.stringify(user));
};

const getAccessToken: any = () => {
  return localStorage.getItem("accessToken");
};

const clearLoginData = () => {
  localStorage.removeItem("accessToken");
  //localStorage.removeItem("user");
};

//const getUser = () => getObject("user");

const localStorageService = {
  setLoginData,
  getAccessToken,
  clearLoginData,
};

export default localStorageService;
