import { useEffect, useState } from "react";
import MaintenanceCompany from "../../models/maintenanceCompany";
import { useNavigate } from "react-router-dom";
import maintenanceCompanyService from "../../services/maintenanceCompanyService";
//import Address from "../../models/address";

const MaintenanceCompanies: React.FC = () => {
  const navigate = useNavigate();

  const [maintenanceCompanies, setMaintenanceCompanies] = useState<
    MaintenanceCompany[]
  >([]);
  const [allMaintenanceCompanies, setAllMaintenanceCompanies] = useState<
    MaintenanceCompany[]
  >([]);
  const [maintenanceCompanySearch, setMaintenanceCompanySearch] =
    useState<string>("");

  useEffect(() => {
    const init = async () => {
      try {
        const { data: maintenanceCompanies } =
          await maintenanceCompanyService.getMaintenanceCompanies();
        setMaintenanceCompanies(maintenanceCompanies);
        setAllMaintenanceCompanies(maintenanceCompanies);
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    init();
  }, []);

  const onSearchChange = (event: any) => {
    setMaintenanceCompanySearch(event.target.value);
    const searchToken = event.target.value.toLowerCase();
    const newMaintenanceCompanies = allMaintenanceCompanies.filter(
      (a) => a.companyName.toLowerCase().includes(searchToken) //||
      //a.onSitePropertyManager.toLowerCase().includes(searchToken)
    );
    setMaintenanceCompanies(newMaintenanceCompanies);
  };

  const onAddNewClick = () => {
    navigate("/work/maintenanceCompanies/new");
  };

  const onEditClick = (maintenanceCompany: MaintenanceCompany) => {
    navigate(
      `/work/maintenanceCompanies/${maintenanceCompany.idMaintenanceCompany}`
    );
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Maintenance Companies</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <div className="d-flex justify-content-between">
                <div className="search-bar" style={{ maxWidth: "500px" }}>
                  <form className="search-form d-flex align-items-center">
                    <input
                      type="text"
                      name="query"
                      placeholder="Search Maintenance Companies"
                      value={maintenanceCompanySearch}
                      onChange={onSearchChange}
                      title="Enter search keyword"
                    />
                    <button type="submit" title="Search">
                      <i className="bi bi-search"></i>
                    </button>
                  </form>
                </div>
                <div className="nav-item d-block d-lg-none">
                  <button
                    className="nav-link nav-icon search-bar-toggle "
                    title="Search"
                  >
                    <i className="bi bi-search"></i>
                  </button>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => onAddNewClick()}
                  >
                    Add New
                  </button>
                  <div>{`${maintenanceCompanies.length} maintenance company`}</div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Maintenance Company Name</th>
                      {/* <th scope="col">Shipping Address</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Trades</th> */}
                      <th style={{ width: "50px" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {maintenanceCompanies.map(
                      (maintenanceCompany: MaintenanceCompany) => (
                        <tr
                          key={maintenanceCompany.idMaintenanceCompany.toString()}
                        >
                          <td>{maintenanceCompany.companyName}</td>
                          {/* <td>{Address.toString(maintenanceCompany.shippingAddress)}</td>
                        <td>{MaintenanceCompany.emailsToString(maintenanceCompany)}</td>
                        <td>{MaintenanceCompany.phonesToString(maintenanceCompany)}</td>
                        <td>{MaintenanceCompany.tradesToString(maintenanceCompany)}</td> */}
                          <td>
                            <i
                              className="ri-pencil-fill"
                              style={{ fontSize: "20px", cursor: "pointer" }}
                              onClick={() => onEditClick(maintenanceCompany)}
                            ></i>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default MaintenanceCompanies;
