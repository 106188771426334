import { useNavigate } from "react-router-dom";
import Account from "../../models/account";

const AccountLink: React.FC<{ account: Account }> = ({ account }) => {
  const navigate = useNavigate();

  const onLinkClick = () => {
    navigate(`/work/accounts/${account.idAccount}`);
  };

  return (
    <div className="navlink" onClick={() => onLinkClick()}>
      {account.accountName}
    </div>
  );
};

export default AccountLink;
