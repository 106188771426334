import { useEffect, useState, useContext } from "react";
import Account from "../../models/account";
import { useNavigate } from "react-router-dom";
import accountService from "../../services/accountService";
import AccountLink from "../../components/work/AccountLink";
import User from "../../models/user";
import UserContext from "../../contexts/userContext";

const Accounts: React.FC = () => {
  const navigate = useNavigate();
  const user = useContext<User | undefined>(UserContext);

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [allAccounts, setAllAccounts] = useState<Account[]>([]);
  const [accountSearch, setAccountSearch] = useState<string>("");

  useEffect(() => {
    const init = async () => {
      try {
        if (user) {
          var idClientAccount = user.currentClientAccount.idClientAccount;
          const { data: accounts } = await accountService.getAccounts(
            idClientAccount
          );
          setAccounts(accounts);
          setAllAccounts(accounts);
        }
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    init();
  }, [user]);

  const onSearchChange = (event: any) => {
    setAccountSearch(event.target.value);
    const searchToken = event.target.value.toLowerCase();
    const newAccounts = allAccounts.filter(
      (a) =>
        a.accountName.toLowerCase().includes(searchToken) ||
        a.onSitePropertyManager.toLowerCase().includes(searchToken)
    );
    setAccounts(newAccounts);
  };

  const onAddNewClick = () => {
    navigate("/work/accounts/new");
  };

  const onEditClick = (account: Account) => {
    navigate(`/work/accounts/${account.idAccount}`);
  };

  const getSurveysCount = (account: Account) => {
    let surveyCount = 0;

    for (const loc of account.locations) {
      surveyCount += loc.surveys.length;
    }

    return surveyCount;
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Accounts</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <div className="d-flex justify-content-between">
                <div
                  className="search-bar d-flex"
                  style={{ maxWidth: "500px" }}
                >
                  <i
                    style={{
                      fontSize: "60px",
                      color: "var(--accent-color)",
                      marginRight: "10px",
                    }}
                    className="bx bxs-contact"
                  ></i>
                  <form className="search-form d-flex align-items-center">
                    <input
                      type="text"
                      name="query"
                      placeholder="Search Accounts"
                      value={accountSearch}
                      onChange={onSearchChange}
                      title="Enter search keyword"
                    />
                    <button type="submit" title="Search">
                      <i className="bi bi-search"></i>
                    </button>
                  </form>
                </div>
                <div className="nav-item d-block d-lg-none">
                  <button
                    className="nav-link nav-icon search-bar-toggle "
                    title="Search"
                  >
                    <i className="bi bi-search"></i>
                  </button>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => onAddNewClick()}
                  >
                    Add New
                  </button>
                  <div>{`${accounts.length} accounts`}</div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Account</th>
                      <th scope="col">Locations Count</th>
                      <th scope="col">Surveys Count</th>
                      <th style={{ width: "50px" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {accounts.map((account: Account) => (
                      <tr key={account.idAccount.toString()}>
                        <td>
                          <AccountLink account={account} />
                        </td>
                        <td>{account.locations.length}</td>
                        <td>{getSurveysCount(account)}</td>
                        <td>
                          <i
                            className="ri-pencil-fill"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            onClick={() => onEditClick(account)}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Accounts;
