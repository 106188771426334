import { Tooltip } from "bootstrap";

let tooltipList = Array<Tooltip>();

const init = () => {
  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });
};

const dispose = () => {
  tooltipList.forEach((el) => {
    el.dispose();
  });
};

const tooltips = {
  init,
  dispose,
};

export default tooltips;
