import Subscription from "./subscription";

export default class ClientAccount {
  public idClientAccount: number = 0;
  public idClient: number = 0;
  public idSubscription: number = 0;
  public clientAccountName: string = "";
  public affiliateCode: string = "";
  public subscription: Subscription = new Subscription();
}
