import WorkOrder from "../../models/workOrder";
import WorkOrderDetails from "../../components/work-order/WorkWorderDetails";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import workOrderService from "../../services/workOrderService";
import Dispatches from "../../components/work-order/Dispatches";

const WorkOrderView: React.FC = () => {
  const { id: idParam } = useParams();

  const [workOrder, setWorkOrder] = useState<WorkOrder>();

  useEffect(() => {
    const init = async (idWorkOrder: number) => {
      try {
        const workOrder = await workOrderService.getWorkOrderById(idWorkOrder);

        if (workOrder) {
          setWorkOrder(workOrder);
        }
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    const idWorkOrder = Number(idParam);
    init(idWorkOrder);
  }, [idParam]);

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Work Order</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <WorkOrderDetails workOrder={workOrder} />
            </div>
          </div>
        </div>
      </section>

      <div className="pagetitle">
        <h1>Dispatches</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <Dispatches workOrder={workOrder} />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default WorkOrderView;
