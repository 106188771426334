import { useEffect, useState } from "react";
import Trade from "../../models/trade";
import DropdownOption from "./dropdownOption";
import tradeService from "../../services/tradeService";
import CreatableSelect from "react-select/creatable";

const Trades: React.FC<{
  trades: Trade[];
  controlLabel: string;
  onTradeChanged: any;
}> = ({ trades, controlLabel, onTradeChanged }) => {
  const [allTrades, setAllTrades] = useState<Trade[]>([]);
  const [tradesOptions, setTradesOptions] = useState<DropdownOption[]>([]);
  const [selectedTrades, setSelectedTrades] = useState<DropdownOption[]>([]);

  const populateTrades = async () => {
    const dropdownOption: DropdownOption[] = [];
    const { data: trades } = await tradeService.getTrades();
    setAllTrades(trades);

    for (const trade of trades) {
      const newOption = new DropdownOption();
      newOption.value = trade.idTrade;
      newOption.label = trade.tradeName;
      dropdownOption.push(newOption);
    }

    setTradesOptions(dropdownOption);
  };

  const populateSelectedTrades = (trades: Trade[]) => {
    const dropdownOption: DropdownOption[] = [];
    for (const trade of trades) {
      const newOption = new DropdownOption();
      newOption.value = trade.idTrade;
      newOption.label = trade.tradeName;
      dropdownOption.push(newOption);
    }

    setSelectedTrades(dropdownOption);
  };

  useEffect(() => {
    populateTrades();
    populateSelectedTrades(trades);
  }, [trades]);

  const onTradesChange = (event: any) => {
    const newTrades: Trade[] = [];

    for (const item of event) {
      const existingTrade = allTrades.find(
        (t) => t.idTrade === item.value && t.tradeName === item.label
      );
      if (existingTrade) {
        newTrades.push(existingTrade);
      } else {
        const newTrade = new Trade();
        newTrade.tradeName = item.label;
        newTrades.push(newTrade);
      }
    }

    onTradeChanged(newTrades);
    populateSelectedTrades(newTrades);
  };

  return (
    <div>
      <label htmlFor="trade" className="form-label">
        {controlLabel}
      </label>
      <CreatableSelect
        isMulti
        id="trade"
        options={tradesOptions}
        value={selectedTrades}
        onChange={(newValue: any) => onTradesChange(newValue)}
        placeholder="Type a trade and press enter"
      />
    </div>
  );
};

export default Trades;
