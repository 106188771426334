import Address from "./address";
import Location from "./location";

export default class Account {
  public idAccount: number = 0;
  public idClientAccount: number = 0;
  public accountName: string = "";
  public onSitePropertyManager: string = "";
  public onSiteManagerPhoneNumber: string = "";
  public onSiteManagerEmail: string = "";
  public serviceInstructions: string = "";
  public locations: Location[] = [];
  public shippingAddress: Address = new Address();
  public billingAddress: Address = new Address(); //| undefined;
  public isSameShippingBillingAddress: boolean = true;
  public currency: string = "";
}
