import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Trade from "../../models/trade";
import tradeService from "../../services/tradeService";
import formHelper from "../../util/formHelper";
import ModalWindow from "../../components/common/ModalWindow";
import ValidationManager from "../../components/common/validationManager";
import Validator from "../../components/common/Validator";
import { ItemsListEditor } from "../../components/common/ItemsListEditor";
import Problems from "./../../components/common/Problems";
import ProblemEdit from "../../components/common/ProblemEdit";
import Problem from "../../models/problem";

const validator = {
  tradeName: "tradeName",
  // timeZoneSelector: "timeZoneSelector",
  // paymentTerm: "paymentTerm",
  // serviceArea: "serviceArea",
  // dispatchMethod: "dispatchMethod",
  problems: "problems",
};

const TradeEdit: React.FC = () => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>(true);
  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [trade, setTrade] = useState<Trade>(new Trade());
  //const [timeZones, setTmeZones] = useState<string[]>([]);
  const [validationManager] = useState<ValidationManager>(
    new ValidationManager()
  );

  useEffect(() => {
    const initEdit = async (idTrade: number) => {
      try {
        const { data: trade } = await tradeService.getTradeById(idTrade);

        if (trade) {
          setTrade(trade);
        }

        validationManager.setIsValid(validator.tradeName, true);
        validationManager.setIsValid(validator.problems, true);
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    const isNew = idParam === "new";
    setIsNew(isNew);
    //getTimeZones();

    if (!isNew) {
      const idTrade = Number(idParam);
      initEdit(idTrade);
    }
  }, [idParam, validationManager]);

  const onChange = (event: any) => {
    const { id, name, value } = formHelper.getDataFromEvent(event.target);
    const newTrade = { ...trade, [name]: value };
    setTrade(newTrade);

    if (id === validator.tradeName) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.tradeName, isValid);
    }
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setErrorMessage("");

    if (!validationManager.validate()) {
      return;
    }

    try {
      if (isNew) {
        try {
          await tradeService.createTrade(trade);
        } catch (error: any) {
          const errorMessage = error.response.data;
          setErrorMessage(errorMessage);
          return;
        }
      } else {
        await tradeService.updateTrade(trade);
      }
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    navigate("/work/trades");
  };

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = () => {
    setShowDeleteWindow(false);
    onDelete();
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const onDelete = async () => {
    await tradeService.deleteTrade(trade);
    navigate("/work/trades");
  };

  const onCancel = () => {
    navigate("/work/trades");
  };

  const onTredeProblemsChanged = (problems: Problem[]) => {
    const newTrade = { ...trade, problems: problems };
    setTrade(newTrade);
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>{isNew ? "Create" : "Edit"} Trade</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <form
                className="row g-3"
                onSubmit={onSubmit}
                onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
              >
                <div className="col-4">
                  <Validator
                    name={validator.tradeName}
                    errorMessage="Please enter a trade name."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="tradeName" className="form-label">
                        Trade Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="tradeName"
                        name={formHelper.nameOf<Trade>((t) => t.tradeName)}
                        value={trade.tradeName}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>

                <div
                  className="card"
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  <div
                    className="card-header"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    Problems
                  </div>
                  <div className="card-body">
                    <ItemsListEditor
                      title=""
                      fields={trade.problems}
                      fieldsChanged={onTredeProblemsChanged}
                      Component={ProblemEdit}
                      isNew={isNew}
                      validationManager={validationManager}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div>
                      <button type="submit" className="btn btn-primary">
                        {isNew ? "Create" : "Save"}
                      </button>{" "}
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </button>
                    </div>
                    {!isNew && (
                      <div>
                        <button
                          type="reset"
                          className="btn btn-danger"
                          onClick={() => onDeleteConfirm()}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row text-center">
                  {errorMessage !== "" && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>{`Are you sure you want to delete the trade?`}</div>
        </ModalWindow>
      )}
    </main>
  );
};

export default TradeEdit;
