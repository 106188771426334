const LoadingIndicator: React.FC<{ className?: string }> = ({
  className = "",
}) => {
  return (
    <div>
      <div
        className={
          (className !== "" ? className + " " : "") +
          "loading-indicator spinner-border text-primary"
        }
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingIndicator;
