import httpService from "./httpService";
import ServiceProvider from "../models/serviceProvider";

const apiEndpoint = "/serviceProviders";

const getServiceProviders = () => {
  return httpService.get<ServiceProvider[]>(
    `${apiEndpoint}/getServiceProviders`
  );
};

const getServiceProviderById = (idServiceProvider: number) => {
  return httpService.get<ServiceProvider>(
    `${apiEndpoint}/getServiceProviderById?idServiceProvider=${idServiceProvider}`
  );
};

const createServiceProvider = (serviceProvider: ServiceProvider) => {
  return httpService.post<ServiceProvider>(
    `${apiEndpoint}/createServiceProvider`,
    serviceProvider
  );
};

const updateServiceProvider = (serviceProvider: ServiceProvider) => {
  return httpService.post<ServiceProvider>(
    `${apiEndpoint}/updateServiceProvider`,
    serviceProvider
  );
};

const deleteServiceProvider = (serviceProvider: ServiceProvider) => {
  return httpService.delete<ServiceProvider>(
    `${apiEndpoint}/deleteServiceProvider`,
    {
      data: serviceProvider,
    }
  );
};

const serviceProviderService = {
  getServiceProviders,
  getServiceProviderById,
  createServiceProvider,
  updateServiceProvider,
  deleteServiceProvider,
};

export default serviceProviderService;
