import httpService from "./httpService";
import MaintenanceCompany from "../models/maintenanceCompany";

const apiEndpoint = "/maintenanceCompanies";

const getMaintenanceCompanies = () => {
  return httpService.get<MaintenanceCompany[]>(
    `${apiEndpoint}/getMaintenanceCompanies`
  );
};

const getMaintenanceCompanyById = (idMaintenanceCompany: number) => {
  return httpService.get<MaintenanceCompany>(
    `${apiEndpoint}/getMaintenanceCompanyById?idMaintenanceCompany=${idMaintenanceCompany}`
  );
};

const createMaintenanceCompany = (maintenanceCompany: MaintenanceCompany) => {
  return httpService.post<MaintenanceCompany>(
    `${apiEndpoint}/createMaintenanceCompany`,
    maintenanceCompany
  );
};

const updateMaintenanceCompany = (maintenanceCompany: MaintenanceCompany) => {
  return httpService.post<MaintenanceCompany>(
    `${apiEndpoint}/updateMaintenanceCompany`,
    maintenanceCompany
  );
};

const deleteMaintenanceCompany = (maintenanceCompany: MaintenanceCompany) => {
  return httpService.delete<MaintenanceCompany>(
    `${apiEndpoint}/deleteMaintenanceCompany`,
    {
      data: maintenanceCompany,
    }
  );
};

const maintenanceCompanyService = {
  getMaintenanceCompanies,
  getMaintenanceCompanyById,
  createMaintenanceCompany,
  updateMaintenanceCompany,
  deleteMaintenanceCompany,
};

export default maintenanceCompanyService;
