import Location from "./location";
import SurveyStatus from "./surveyStatus";
import SurveyItem from "./surveyItem";

export default class Survey {
  public idSurvey: number = 0;
  public idLocation: number = 0;
  public surveyTitle: string = "";
  public surveyDate: Date | undefined = undefined;
  public surveyAuthor: string = "";
  public surveyCode: string = "";
  public surveyPassCode: string = "";
  public surveyGeneralInstructions: string = "";
  public surveyInstructions: string = "";
  public surveyStatus: SurveyStatus = SurveyStatus.Open;
  public surveySummaryNotes: string = "";
  public reportGeneratedDate: Date | undefined = undefined;
  public reportFileUrl: string = "";
  public resourceId: string = "00000000-0000-0000-0000-000000000000";
  public surveyItems: SurveyItem[] = [];
  public location: Location = new Location();
}
