import SurveyItem from "../../models/surveyItem";

const canNotAddNewSurveyItem = (surveyItems: SurveyItem[]): boolean => {
  const canNotAddNew =
    surveyItems.find(
      (surveyItem) =>
        !validateConditionField(surveyItem) ||
        !validateCommentsField(surveyItem) ||
        !validatePhotosField(surveyItem)
      // TODO add validator for "name" - for othe category or for asset name...
      // TODO add validator for SurveyCategory and SurveyCategoryName
    ) !== undefined;

  return canNotAddNew;
};

const getCommentsFieldId = (surveyItem: SurveyItem) => {
  return "comments_" + surveyItem.resourceId;
};

const validateCommentsField = (surveyItem: SurveyItem) => {
  var isValid =
    surveyItem.comments !== null && surveyItem.comments.trim() !== "";
  return isValid;
};

const getPhotosFieldId = (surveyItem: SurveyItem) => {
  return "photos_" + surveyItem.resourceId;
};

const validatePhotosField = (surveyItem: SurveyItem) => {
  var isValid = surveyItem.files.length !== 0;
  return isValid;
};

const getConditionFieldId = (surveyItem: SurveyItem) => {
  return "condition_" + surveyItem.resourceId;
};

const validateConditionField = (surveyItem: SurveyItem) => {
  var isValid = surveyItem.conditionType !== null;
  return isValid;
};

const surveyItemsEditCommon = {
  canNotAddNewSurveyItem,
  getCommentsFieldId,
  validateCommentsField,
  getPhotosFieldId,
  validatePhotosField,
  getConditionFieldId,
  validateConditionField,
};

export default surveyItemsEditCommon;
