import { useParams } from "react-router-dom";
import Dispatch from "../../models/dispatch";
import { useEffect, useState } from "react";
import Proposals from "../../components/work-order/Proposals";
import DispatchType from "../../models/dispatchType";
import workOrderService from "../../services/workOrderService";
import DispatchDetails from "../../components/work-order/DispatchDetails";

const DispatchView: React.FC = () => {
  const { id: idParam } = useParams();

  const [dispatch, setDispatch] = useState<Dispatch>(new Dispatch());

  useEffect(() => {
    const init = async (idDispatch: number) => {
      try {
        const dispatch = await workOrderService.getDispatchById(idDispatch);

        if (dispatch) {
          setDispatch(dispatch);
        }
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    const idAccount = Number(idParam);
    init(idAccount);
  }, [idParam]);

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Work Order Dispatch</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <DispatchDetails dispatch={dispatch} />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <div className="card-body profile">
                <ul className="nav nav-tabs nav-tabs-bordered">
                  {dispatch.dispatchFrom === DispatchType.Account && (
                    <>
                      <li className="nav-item">
                        <button
                          className="nav-link active"
                          data-bs-toggle="tab"
                          data-bs-target="#accountProposals"
                        >
                          Account Proposals
                        </button>
                      </li>

                      <li className="nav-item">
                        <button
                          className="nav-link"
                          data-bs-toggle="tab"
                          data-bs-target="#accountCommunication"
                        >
                          Account Communication
                        </button>
                      </li>

                      <li className="nav-item">
                        <button
                          className="nav-link"
                          data-bs-toggle="tab"
                          data-bs-target="#accountInvoices"
                        >
                          Account Invoices
                        </button>
                      </li>

                      <li className="nav-item">
                        <button
                          className="nav-link"
                          data-bs-toggle="tab"
                          data-bs-target="#accountFiles"
                        >
                          Account Files
                        </button>
                      </li>
                    </>
                  )}

                  {dispatch.dispatchFrom !== DispatchType.Account && (
                    <>
                      <li className="nav-item">
                        <button
                          className="nav-link active"
                          data-bs-toggle="tab"
                          data-bs-target="#serviceProviderProposals"
                        >
                          Service Provider Proposals
                        </button>
                      </li>

                      <li className="nav-item">
                        <button
                          className="nav-link"
                          data-bs-toggle="tab"
                          data-bs-target="#serviceProviderCommunication"
                        >
                          Service Provider Communication
                        </button>
                      </li>

                      <li className="nav-item">
                        <button
                          className="nav-link"
                          data-bs-toggle="tab"
                          data-bs-target="#serviceProviderInvoices"
                        >
                          Service Provider Invoices
                        </button>
                      </li>

                      <li className="nav-item">
                        <button
                          className="nav-link"
                          data-bs-toggle="tab"
                          data-bs-target="#serviceProviderFiles"
                        >
                          Service Provider Files
                        </button>
                      </li>
                    </>
                  )}
                </ul>

                <div className="tab-content pt-2">
                  {dispatch.dispatchFrom === DispatchType.Account && (
                    <>
                      <div
                        className="tab-pane fade profile-overview active show"
                        id="accountProposals"
                      >
                        <div className="row">
                          <Proposals dispatch={dispatch} />
                        </div>
                      </div>

                      <div
                        className="tab-pane fade pt-3"
                        id="accountCommunication"
                      >
                        TODO
                      </div>

                      <div className="tab-pane fade pt-3" id="accountInvoices">
                        TODO
                      </div>
                    </>
                  )}

                  {dispatch.dispatchFrom !== DispatchType.Account && (
                    <>
                      <div
                        className="tab-pane fade profile-overview active show"
                        id="serviceProviderProposals"
                      >
                        <div className="row">
                          <Proposals dispatch={dispatch} />
                        </div>
                      </div>

                      <div
                        className="tab-pane fade pt-3"
                        id="serviceProviderCommunication"
                      >
                        TODO
                      </div>

                      <div
                        className="tab-pane fade pt-3"
                        id="serviceProviderInvoices"
                      >
                        TODO
                      </div>

                      <div
                        className="tab-pane fade pt-3"
                        id="serviceProviderFiles"
                      >
                        TODO
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default DispatchView;
