import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import accountService from "../../services/accountService";
import Location from "../../models/location";
import AccountLink from "../../components/work/AccountLink";
import LocationLink from "../../components/work/LocationLink";
import User from "../../models/user";
import UserContext from "../../contexts/userContext";

const Locations: React.FC = () => {
  const navigate = useNavigate();
  const user = useContext<User | undefined>(UserContext);

  const [locations, setLocations] = useState<Location[]>([]);
  const [allLocations, setAllLocations] = useState<Location[]>([]);
  const [locationSearch, setLocationSearch] = useState<string>("");

  useEffect(() => {
    const init = async () => {
      try {
        if (user) {
          var idClientAccount = user.currentClientAccount.idClientAccount;
          const { data: locations } = await accountService.getLocations(
            idClientAccount
          );
          setLocations(locations);
          setAllLocations(locations);
        }
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    init();
  }, [user]);

  const onSearchChange = (event: any) => {
    setLocationSearch(event.target.value);
    const searchToken = event.target.value.toLowerCase();
    const newAccounts = allLocations.filter(
      (a) =>
        a.locationName.toLowerCase().includes(searchToken) ||
        a.account.accountName.toLowerCase().includes(searchToken) ||
        a.onSitePropertyManager.toLowerCase().includes(searchToken)
    );
    setLocations(newAccounts);
  };

  const onAddNewClick = () => {
    navigate("/work/locations/new");
  };

  const onEditClick = (location: Location) => {
    navigate(`/work/locations/${location.idLocation}`);
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Locations</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <div className="d-flex justify-content-between">
                <div
                  className="search-bar d-flex"
                  style={{ maxWidth: "500px" }}
                >
                  <i
                    style={{
                      fontSize: "50px",
                      color: "var(--accent-color)",
                      marginRight: "10px",
                      marginTop: "-10px",
                    }}
                    className="bi bi-house-door-fill"
                  ></i>
                  <form className="search-form d-flex align-items-center">
                    <input
                      type="text"
                      name="query"
                      placeholder="Search Locations"
                      title="Enter search keyword"
                      value={locationSearch}
                      onChange={onSearchChange}
                    />
                    <button type="submit" title="Search">
                      <i className="bi bi-search"></i>
                    </button>
                  </form>
                </div>
                <div className="nav-item d-block d-lg-none">
                  <button
                    className="nav-link nav-icon search-bar-toggle "
                    title="Search"
                  >
                    <i className="bi bi-search"></i>
                  </button>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => onAddNewClick()}
                  >
                    Add New
                  </button>
                  <div>{`${locations.length} accounts`}</div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Location</th>
                      <th scope="col">Account</th>
                      <th scope="col">Surveys Count</th>
                      <th style={{ width: "50px" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {locations.map((location: Location) => (
                      <tr key={location.idLocation.toString()}>
                        <td>
                          <LocationLink location={location} />
                        </td>
                        <td>
                          <AccountLink account={location.account} />
                        </td>
                        <td>{location.surveys.length}</td>
                        <td>
                          <i
                            className="ri-pencil-fill"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            onClick={() => onEditClick(location)}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Locations;
