import { useEffect, useState } from "react";
import Address from "../../models/address";
import ValidationManager from "./validationManager";
import Validator from "./Validator";
import formHelper from "../../util/formHelper";
import CountryState from "../../models/countryState";

const AddressEdit: React.FC<{
  inputAddress: Address;
  onChange: any;
  isNew: boolean;
  validationManager: ValidationManager;
  validationId: string;
}> = ({ inputAddress, onChange, isNew, validationManager, validationId }) => {
  const [address, setAddress] = useState<Address>(new Address());

  const [countries, setCountries] = useState<string[]>([]);
  const [countryStates, setCountryStates] = useState<CountryState[]>([]);

  const validator = {
    addressLine1: `${validationId}_addressLine1`,
    city: `${validationId}_city`,
    zip: `${validationId}_zip`,
    country: `${validationId}_country`,
    state: `${validationId}_state`,
  };

  useEffect(() => {
    setAddress(inputAddress);

    const countryStates = getCountryStates();
    setCountryStates(countryStates);

    const countries = getCountries();
    setCountries(countries);

    if (!isNew) {
      validationManager.setIsValid(validator.addressLine1, true);
      validationManager.setIsValid(validator.city, true);
      validationManager.setIsValid(validator.zip, true);
      validationManager.setIsValid(validator.country, true);
      validationManager.setIsValid(validator.state, true);
    }
  }, [
    inputAddress,
    validationManager,
    isNew,
    validator.addressLine1,
    validator.city,
    validator.zip,
    validator.country,
    validator.state,
  ]);

  const getCountries = (): string[] => {
    const countries = ["United States", "Canada"];
    return countries;
  };

  const getCountryStates = (): CountryState[] => {
    const states: [string, string][] = [
      ["Alabama", "AL"],
      ["Alaska", "AK"],
      ["Arizona", "AZ"],
      ["Arkansas", "AR"],
      ["California", "CA"],
      ["Colorado", "CO"],
      ["Connecticut", "CT"],
      ["Delaware", "DE"],
      ["Florida", "FL"],
      ["Georgia", "GA"],
      ["Hawaii", "HI"],
      ["Idaho", "ID"],
      ["Illinois", "IL"],
      ["Indiana", "IN"],
      ["Iowa", "IA"],
      ["Kansas", "KS"],
      ["Kentucky", "KY"],
      ["Louisiana", "LA"],
      ["Maine", "ME"],
      ["Maryland", "MD"],
      ["Massachusetts[B]", "MA"],
      ["Michigan", "MI"],
      ["Minnesota", "MN"],
      ["Mississippi", "MS"],
      ["Missouri", "MO"],
      ["Montana", "MT"],
      ["Nebraska", "NE"],
      ["Nevada", "NV"],
      ["New Hampshire", "NH"],
      ["New Jersey", "NJ"],
      ["New Mexico", "NM"],
      ["New York", "NY"],
      ["North Carolina", "NC"],
      ["North Dakota", "ND"],
      ["Ohio", "OH"],
      ["Oklahoma", "OK"],
      ["Oregon", "OR"],
      ["Pennsylvania[B]", "PA"],
      ["Rhode Island", "RI"],
      ["South Carolina", "SC"],
      ["South Dakota", "SD"],
      ["Tennessee", "TN"],
      ["Texas", "TX"],
      ["Utah", "UT"],
      ["Vermont", "VT"],
      ["Virginia", "VA"],
      ["Washington", "WA"],
      ["West Virginia", "WV"],
      ["Wisconsin", "WI"],
      ["Wyoming", "WY"],
    ];

    const countryStates: CountryState[] = [];

    for (const state of states) {
      const [stateName, stateShortName] = state;

      const countryState = new CountryState();
      countryState.stateName = stateName;
      countryState.stateShortName = stateShortName;

      countryStates.push(countryState);
    }

    return countryStates;
  };

  const onAddressChange = (event: any) => {
    const { id, name, value } = formHelper.getDataFromEvent(event.target);
    let newAddress = { ...address, [name]: value };

    // console.log(id);
    // console.log(value);
    // console.log(name);

    if (id === validator.country) {
      newAddress = { ...newAddress, state: "" };
    }

    setAddress(newAddress);
    onChange(newAddress);

    if (id === validator.addressLine1) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.addressLine1, isValid);
    }

    if (id === validator.city) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.city, isValid);
    }

    if (id === validator.zip) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.zip, isValid);
    }

    if (id === validator.country) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.country, isValid);
    }

    if (id === validator.state) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.state, isValid);
    }
  };

  return (
    <div className="row g-3">
      <div className="col-md-6">
        <Validator
          name={validator.addressLine1}
          errorMessage="Please enter a valid address."
          validationManager={validationManager}
        >
          <div>
            <label
              htmlFor={`${validationId}_addressLine1`}
              className="form-label"
            >
              Address Line 1
            </label>
            <input
              type="text"
              className="form-control"
              id={`${validationId}_addressLine1`}
              name={formHelper.nameOf<Address>((t) => t.addressLine1)}
              value={address.addressLine1}
              onChange={onAddressChange}
            />
          </div>
        </Validator>
      </div>

      <div className="col-md-6">
        <label htmlFor={`${validationId}_addressLine2`} className="form-label">
          Address Line 2
        </label>
        <input
          type="text"
          className="form-control"
          id={`${validationId}_addressLine2`}
          name={formHelper.nameOf<Address>((t) => t.addressLine2)}
          value={address.addressLine2}
          onChange={onAddressChange}
        />
      </div>

      <div className="col-md-6">
        <Validator
          name={validator.city}
          errorMessage="Please enter a city."
          validationManager={validationManager}
        >
          <div>
            <label htmlFor={`${validationId}_city`} className="form-label">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id={`${validationId}_city`}
              name={formHelper.nameOf<Address>((t) => t.city)}
              value={address.city}
              onChange={onAddressChange}
            />
          </div>
        </Validator>
      </div>

      <div className="col-md-6">
        <Validator
          name={validator.zip}
          errorMessage="Please enter a zip code."
          validationManager={validationManager}
        >
          <div>
            <label htmlFor={`${validationId}_zip`} className="form-label">
              Zip/Postal Code
            </label>
            <input
              type="text"
              className="form-control"
              id={`${validationId}_zip`}
              name={formHelper.nameOf<Address>((t) => t.zip)}
              value={address.zip}
              onChange={onAddressChange}
            />
          </div>
        </Validator>
      </div>

      <div className="col-md-6">
        <Validator
          name={validator.country}
          errorMessage="Please enter a country."
          validationManager={validationManager}
        >
          <div>
            <label htmlFor={`${validationId}_country`} className="form-label">
              Country
            </label>
            <select
              className="form-select"
              aria-label="select"
              id={`${validationId}_country`}
              name={formHelper.nameOf<Address>((t) => t.country)}
              value={address.country ?? ""}
              onChange={onAddressChange}
            >
              <option value="" disabled>
                Please select
              </option>
              {countries.map((country, index) => (
                <option key={index.toString()} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
        </Validator>
      </div>

      <div className="col-md-6">
        <Validator
          name={validator.state}
          errorMessage="Please enter a state."
          validationManager={validationManager}
        >
          <div>
            <label htmlFor={`${validationId}_state`} className="form-label">
              State/Province
            </label>
            {address.country === "United States" && (
              <select
                className="form-select"
                aria-label="select"
                id={`${validationId}_state`}
                name={formHelper.nameOf<Address>((t) => t.state)}
                value={address.state ?? ""}
                onChange={onAddressChange}
              >
                <option value="" disabled>
                  Please select
                </option>
                {countryStates.map((countryState, index) => (
                  <option key={index.toString()} value={countryState.stateName}>
                    {countryState.displayName()}
                  </option>
                ))}
              </select>
            )}
            {address.country !== "United States" && (
              <input
                type="text"
                className="form-control"
                id={`${validationId}_state`}
                name={formHelper.nameOf<Address>((t) => t.state)}
                value={address.state}
                onChange={onAddressChange}
              />
            )}
          </div>
        </Validator>
      </div>
    </div>
  );
};

export default AddressEdit;
