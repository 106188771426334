import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ServiceProvider from "../../models/serviceProvider";
import serviceProviderService from "../../services/serviceProviderService";
import timeService from "../../services/timeService";
import formHelper from "../../util/formHelper";
import AddressEdit from "../../components/common/AddressEdit";
import Address from "../../models/address";
import ModalWindow from "../../components/common/ModalWindow";
import ValidationManager from "../../components/common/validationManager";
import Validator from "../../components/common/Validator";
import PhoneNumberEdit from "../../components/common/PhoneNumberEdit";
import PhoneNumber from "../../models/phoneNumber";
import { ItemsListEditor } from "../../components/common/ItemsListEditor";
import EmailAddress from "../../models/emailAddress";
import EmailAddressEdit from "../../components/common/EmailAddressEdit";
import PaymentTerm from "../../models/paymentTerm";
import ServiceArea from "../../models/serviceArea";
import DispatchMethod from "../../models/dispatchMethod";
import Trade from "../../models/trade";
import Trades from "../../components/common/Trades";

const validator = {
  serviceProviderName: "serviceProviderName",
  timeZoneSelector: "timeZoneSelector",
  paymentTerm: "paymentTerm",
  serviceArea: "serviceArea",
  dispatchMethod: "dispatchMethod",
  trades: "trades",
};

const ServiceProviderEdit: React.FC = () => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>(true);
  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [serviceProvider, setServiceProvider] = useState<ServiceProvider>(
    new ServiceProvider()
  );
  const [timeZones, setTmeZones] = useState<string[]>([]);
  const [validationManager] = useState<ValidationManager>(
    new ValidationManager()
  );

  useEffect(() => {
    const getTimeZones = async () => {
      const { data: timeZones } = await timeService.getTimeZones();

      if (timeZones) {
        setTmeZones(timeZones);
      }
    };

    const initEdit = async (idServiceProvider: number) => {
      try {
        const { data: serviceProvider } =
          await serviceProviderService.getServiceProviderById(
            idServiceProvider
          );

        if (serviceProvider) {
          setServiceProvider(serviceProvider);
        }

        validationManager.setIsValid(validator.serviceProviderName, true);
        if (serviceProvider.timeZone) {
          validationManager.setIsValid(validator.timeZoneSelector, true);
        }

        validationManager.setIsValid(validator.paymentTerm, true);
        validationManager.setIsValid(validator.serviceArea, true);
        validationManager.setIsValid(validator.dispatchMethod, true);
        validationManager.setIsValid(validator.trades, true);
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    const isNew = idParam === "new";
    setIsNew(isNew);
    getTimeZones();

    if (!isNew) {
      const idServiceProvider = Number(idParam);
      initEdit(idServiceProvider);
    }
  }, [idParam, validationManager]);

  const onTradesChange = (newTrades: Trade[]) => {
    const newServiceProvider = {
      ...serviceProvider,
      trades: newTrades,
    };
    setServiceProvider(newServiceProvider);
    const isValid = newTrades.length !== 0;
    validationManager.setIsValid(validator.trades, isValid);
  };

  const onChange = (event: any) => {
    const { id, name, value } = formHelper.getDataFromEvent(event.target);
    const newServiceProvider = { ...serviceProvider, [name]: value };
    setServiceProvider(newServiceProvider);

    if (id === validator.serviceProviderName) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.serviceProviderName, isValid);
    }
  };

  const onShippingAddressChange = (address: Address) => {
    const newVector = { ...serviceProvider, shippingAddress: address };
    setServiceProvider(newVector);
  };

  const onBillingAddressChange = (address: Address) => {
    const newServiceProvider = { ...serviceProvider, billingAddress: address };
    setServiceProvider(newServiceProvider);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setErrorMessage("");

    if (!validationManager.validate()) {
      return;
    }

    try {
      if (isNew) {
        try {
          await serviceProviderService.createServiceProvider(serviceProvider);
        } catch (error: any) {
          const errorMessage = error.response.data;
          setErrorMessage(errorMessage);
          return;
        }
      } else {
        await serviceProviderService.updateServiceProvider(serviceProvider);
      }
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    navigate("/work/service-providers");
  };

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = () => {
    setShowDeleteWindow(false);
    onDelete();
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const onDelete = async () => {
    await serviceProviderService.deleteServiceProvider(serviceProvider);
    navigate("/work/service-provider");
  };

  const onCancel = () => {
    navigate("/work/service-provider");
  };

  const onServiceProviderTimeZoneChanged = (event: any) => {
    const newValue = event.target.value;
    if (newValue) {
      const newServiceProvider = {
        ...serviceProvider,
        timeZone: newValue,
      };
      setServiceProvider(newServiceProvider);

      validationManager.setIsValid(validator.timeZoneSelector, true);
    }
  };

  const onServiceProviderPhoneNumbersChanged = (
    phoneNumbers: PhoneNumber[]
  ) => {
    const newServiceProvider = {
      ...serviceProvider,
      phoneNumbers: phoneNumbers,
    };
    setServiceProvider(newServiceProvider);
  };

  const onServiceProviderEmailAddressesChanged = (
    emailAddresses: EmailAddress[]
  ) => {
    const newServiceProvider = {
      ...serviceProvider,
      emailAddresses: emailAddresses,
    };
    setServiceProvider(newServiceProvider);
  };

  const onPaymentTermChanged = (event: any) => {
    const newValue: PaymentTerm = event.target.value;

    if (newValue !== PaymentTerm.None) {
      const newServiceProvider = { ...serviceProvider, paymentTerm: newValue };
      setServiceProvider(newServiceProvider);
    }

    validationManager.setIsValid(validator.paymentTerm, true);
  };

  const onServiceAreaChanged = (event: any) => {
    const newValue: ServiceArea = event.target.value;

    if (newValue !== ServiceArea.None) {
      const newServiceProvider = { ...serviceProvider, serviceArea: newValue };
      setServiceProvider(newServiceProvider);
    }
    validationManager.setIsValid(validator.serviceArea, true);
  };

  const onDispatchMethodChanged = (event: any) => {
    const newValue: DispatchMethod = event.target.value;

    if (newValue !== DispatchMethod.None) {
      const newServiceProvider = {
        ...serviceProvider,
        dispatchMethod: newValue,
      };
      setServiceProvider(newServiceProvider);
    }
    validationManager.setIsValid(validator.dispatchMethod, true);
  };

  const onDispatchInstructionsChange = (event: any) => {
    const { id, name, value } = formHelper.getDataFromEvent(event.target);
    const newServiceProvider = { ...serviceProvider, [name]: value };
    setServiceProvider(newServiceProvider);
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>{isNew ? "Create" : "Edit"} Service Provider</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <form
                className="row g-3"
                onSubmit={onSubmit}
                onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
              >
                <div className="col-4">
                  <Validator
                    name={validator.serviceProviderName}
                    errorMessage="Please enter a Service Provider name."
                    validationManager={validationManager}
                  >
                    <div>
                      <label
                        htmlFor="serviceProviderName"
                        className="form-label"
                      >
                        Service Provider Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="serviceProviderName"
                        name={formHelper.nameOf<ServiceProvider>(
                          (t) => t.serviceProviderName
                        )}
                        value={serviceProvider.serviceProviderName}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>

                <div className="col-md-4">
                  <label htmlFor="serviceProviderNumber" className="form-label">
                    Service Provider Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="serviceProviderNumber"
                    name={formHelper.nameOf<ServiceProvider>(
                      (t) => t.serviceProviderNumber
                    )}
                    value={serviceProvider.serviceProviderNumber}
                    onChange={onChange}
                  />
                </div>

                <div className="col-4">
                  <Validator
                    name={validator.timeZoneSelector}
                    errorMessage="Please select a time zone."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="timeZone" className="form-label">
                        Time Zone
                      </label>
                      <select
                        className="form-select"
                        aria-label="select"
                        id="timeZone"
                        name={formHelper.nameOf<ServiceProvider>(
                          (t) => t.timeZone
                        )}
                        value={
                          serviceProvider.timeZone === ""
                            ? ""
                            : serviceProvider.timeZone
                        }
                        onChange={onServiceProviderTimeZoneChanged}
                      >
                        <option value="" disabled>
                          Please select
                        </option>
                        {timeZones.map((timeZone, index) => (
                          <option key={index.toString()} value={timeZone}>
                            {timeZone}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Validator>
                </div>

                <div
                  className="card"
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  <div
                    className="card-header"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    Shipping Address
                  </div>
                  <div className="card-body">
                    <AddressEdit
                      inputAddress={serviceProvider.shippingAddress}
                      onChange={onShippingAddressChange}
                      isNew={isNew}
                      validationManager={validationManager}
                      validationId="shipping"
                    />
                  </div>
                </div>

                <div className="form-check">
                  <div className="col-12">
                    <label
                      className="form-check-label"
                      htmlFor="isSameShippingBillingAddress"
                    >
                      Same as billing address
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={serviceProvider.isSameShippingBillingAddress}
                      id="isSameShippingBillingAddress"
                      name={formHelper.nameOf<ServiceProvider>(
                        (t) => t.isSameShippingBillingAddress
                      )}
                      onChange={onChange}
                    />
                  </div>
                </div>
                {!serviceProvider.isSameShippingBillingAddress && (
                  <div
                    className="card"
                    style={{
                      marginBottom: "0px",
                    }}
                  >
                    <div
                      className="card-header"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Billing Address
                    </div>
                    <div className="card-body">
                      <AddressEdit
                        inputAddress={serviceProvider.billingAddress}
                        onChange={onBillingAddressChange}
                        isNew={isNew}
                        validationManager={validationManager}
                        validationId="billing"
                      />
                    </div>
                  </div>
                )}
                <div
                  className="card"
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  <div
                    className="card-header"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    Phone Numbers
                  </div>
                  <div className="card-body">
                    <ItemsListEditor
                      title=""
                      fields={serviceProvider.phoneNumbers}
                      fieldsChanged={onServiceProviderPhoneNumbersChanged}
                      Component={PhoneNumberEdit}
                      isNew={isNew}
                      validationManager={validationManager}
                    />
                  </div>
                </div>

                <div
                  className="card"
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  <div
                    className="card-header"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    Email Addresses
                  </div>
                  <div className="card-body">
                    <ItemsListEditor
                      title=""
                      fields={serviceProvider.emailAddresses}
                      fieldsChanged={onServiceProviderEmailAddressesChanged}
                      Component={EmailAddressEdit}
                      isNew={isNew}
                      validationManager={validationManager}
                    />
                  </div>
                </div>

                <div className="col-sm-12">
                  <Validator
                    name={validator.paymentTerm}
                    errorMessage="Please select a payment term."
                    validationManager={validationManager}
                  >
                    <div>
                      {/* <label className="col-sm-2">Payment Term</label> */}
                      <label htmlFor="paymentTerm" className="form-label">
                        Payment Term
                      </label>
                      <div>
                        <select
                          className="form-select"
                          id="paymentTerm"
                          name={formHelper.nameOf<ServiceProvider>(
                            (t) => t.paymentTerm
                          )}
                          aria-label="select"
                          value={
                            serviceProvider.paymentTerm === undefined
                              ? PaymentTerm.None
                              : serviceProvider.paymentTerm
                          }
                          onChange={onPaymentTermChanged}
                        >
                          {/* <option value="" disabled>
                        Please select
                      </option> */}

                          {/* {Object.entries(PaymentTerm).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))} */}
                          {Object.keys(PaymentTerm).map((key, idx) => (
                            <option key={idx} value={key}>
                              {formHelper.getEnumDisplayValue(PaymentTerm, key)}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </Validator>
                </div>
                <div className="col-12">
                  <label htmlFor="dispatchInstructions" className="form-label">
                    Service Instructions
                  </label>
                  <textarea
                    className="form-control"
                    id="dispatchInstructions"
                    name={formHelper.nameOf<ServiceProvider>(
                      (t) => t.dispatchInstructions
                    )}
                    value={
                      serviceProvider.dispatchInstructions == null
                        ? ""
                        : serviceProvider.dispatchInstructions
                    }
                    onChange={onDispatchInstructionsChange}
                    style={{ height: "100px" }}
                  />
                </div>

                <div className="col-sm-6">
                  <Validator
                    name={validator.serviceArea}
                    errorMessage="Please select a service area."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="serviceArea" className="form-label">
                        Service Area
                      </label>
                      <select
                        className="form-select"
                        id="serviceArea"
                        name={formHelper.nameOf<ServiceProvider>(
                          (t) => t.serviceArea
                        )}
                        aria-label="select"
                        value={
                          serviceProvider.serviceArea === undefined
                            ? ServiceArea.None
                            : serviceProvider.serviceArea
                        }
                        onChange={onServiceAreaChanged}
                      >
                        {Object.keys(ServiceArea).map((key, idx) => (
                          <option key={idx} value={key}>
                            {formHelper.getEnumDisplayValue(ServiceArea, key)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Validator>
                </div>

                <div className="col-sm-6">
                  <Validator
                    name={validator.dispatchMethod}
                    errorMessage="Please select a dispatch method."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="dispatchMethod" className="form-label">
                        Dispatch Method
                      </label>
                      <select
                        className="form-select"
                        id="dispatchMethod"
                        name={formHelper.nameOf<ServiceProvider>(
                          (t) => t.dispatchMethod
                        )}
                        aria-label="select"
                        value={
                          serviceProvider.dispatchMethod === undefined
                            ? DispatchMethod.None
                            : serviceProvider.dispatchMethod
                        }
                        onChange={onDispatchMethodChanged}
                      >
                        {Object.keys(DispatchMethod).map((key, idx) => (
                          <option key={idx} value={key}>
                            {formHelper.getEnumDisplayValue(
                              DispatchMethod,
                              key
                            )}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Validator>
                </div>

                <div className="form-check col-sm-6">
                  <label
                    className="form-check-label"
                    htmlFor="isWorkValidationRequired"
                  >
                    Is Work Validation Required
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={serviceProvider.isWorkValidationRequired}
                    id="isWorkValidationRequired"
                    name={formHelper.nameOf<ServiceProvider>(
                      (t) => t.isWorkValidationRequired
                    )}
                    onChange={onChange}
                  />
                </div>
                <div className="form-check col-sm-6">
                  <label
                    className="form-check-label"
                    htmlFor="isMarkAsPreferred"
                  >
                    Is Mark As Preferred
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={serviceProvider.isMarkAsPreferred}
                    id="isMarkAsPreferred"
                    name={formHelper.nameOf<ServiceProvider>(
                      (t) => t.isMarkAsPreferred
                    )}
                    onChange={onChange}
                  />
                </div>
                <div className="form-check col-sm-6">
                  <label
                    className="form-check-label"
                    htmlFor="isAutoAcceptWorkOrderDispatches"
                  >
                    Is AutoAccept WorkOrder Dispatches
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={serviceProvider.isAutoAcceptWorkOrderDispatches}
                    id="isAutoAcceptWorkOrderDispatches"
                    name={formHelper.nameOf<ServiceProvider>(
                      (t) => t.isAutoAcceptWorkOrderDispatches
                    )}
                    onChange={onChange}
                  />
                </div>
                <div className="form-check col-sm-6">
                  <label
                    className="form-check-label"
                    htmlFor="isEnableManualEmailProcess"
                  >
                    Is Enable Manual Email Process
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={serviceProvider.isEnableManualEmailProcess}
                    id="isEnableManualEmailProcess"
                    name={formHelper.nameOf<ServiceProvider>(
                      (t) => t.isEnableManualEmailProcess
                    )}
                    onChange={onChange}
                  />
                </div>
                <div className="form-check col-sm-6">
                  <label
                    className="form-check-label"
                    htmlFor="isUseApplicationDefaultLaborRates"
                  >
                    Is UseApplicationDefaultLaborRates
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={serviceProvider.isUseApplicationDefaultLaborRates}
                    id="isUseApplicationDefaultLaborRates"
                    name={formHelper.nameOf<ServiceProvider>(
                      (t) => t.isUseApplicationDefaultLaborRates
                    )}
                    onChange={onChange}
                  />
                </div>
                <div className="form-check col-sm-6">
                  <label
                    className="form-check-label"
                    htmlFor="isLoadProposalDetailsAndCreateInvoiceOnStatusCompleted"
                  >
                    Is LoadProposalDetailsAndCreateInvoiceOnStatusCompleted
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      serviceProvider.isLoadProposalDetailsAndCreateInvoiceOnStatusCompleted
                    }
                    id="isLoadProposalDetailsAndCreateInvoiceOnStatusCompleted"
                    name={formHelper.nameOf<ServiceProvider>(
                      (t) =>
                        t.isLoadProposalDetailsAndCreateInvoiceOnStatusCompleted
                    )}
                    onChange={onChange}
                  />
                </div>

                <div className="col-12">
                  <Validator
                    name={validator.trades}
                    errorMessage="Please select a trade."
                    validationManager={validationManager}
                  >
                    <div>
                      <Trades
                        trades={serviceProvider.trades}
                        controlLabel="Trades"
                        onTradeChanged={onTradesChange}
                      />
                    </div>
                  </Validator>
                </div>

                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div>
                      <button type="submit" className="btn btn-primary">
                        {isNew ? "Create" : "Save"}
                      </button>{" "}
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </button>
                    </div>
                    {!isNew && (
                      <div>
                        <button
                          type="reset"
                          className="btn btn-danger"
                          onClick={() => onDeleteConfirm()}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row text-center">
                  {errorMessage !== "" && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>{`Are you sure you want to delete the Service Provider?`}</div>
        </ModalWindow>
      )}
    </main>
  );
};

export default ServiceProviderEdit;
