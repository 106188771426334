import { KEY_RETURN } from "keycode-js";

const onFieldChanged = (
  event: any,
  onSetFieldValue: any,
  onClearError: any = undefined
) => {
  const newValue = event.target.value;
  onSetFieldValue(newValue);
  if (onClearError) {
    onClearError();
  }
};

const checkEmpty = (
  fieldValue: string,
  fieldName: string,
  onSetError: any
): boolean => {
  if (!fieldValue) {
    onSetError(`${fieldName} cannot be empty`);
    return true;
  }
  return false;
};

const onEnterKeyDownPreventSubmit = (event: any) => {
  if (event.target.type === "textarea") {
    return;
  }

  const keyCode = event.keyCode || event.which || event.charCode;
  if (keyCode === KEY_RETURN) {
    event.preventDefault();
  }
};

const getDataFromEvent = ({ id, name, type, checked, value }: any) => {
  const fieldValue = type === "checkbox" ? checked : value;
  return {
    value: fieldValue,
    id: id,
    name: name,
  };
};

const getEnumDisplayValue = <T extends Record<string, string>>(
  obj: T,
  key: string
): string => {
  if (key === "None") {
    return "Please select";
  }
  const values = Object.values(obj);
  const indexOfS = Object.keys(obj).indexOf(key);

  return values[indexOfS];
};

function nameOf<T extends object>(nameExtractor: (obj: T) => any): keyof T {
  const proxy = new Proxy({} as T, {
    get(target, prop: string | symbol) {
      return prop;
    },
  });

  return nameExtractor(proxy);
}

const formHelper = {
  onFieldChanged,
  checkEmpty,
  onEnterKeyDownPreventSubmit,
  getEnumDisplayValue,
  getDataFromEvent,
  nameOf,
};

export default formHelper;
