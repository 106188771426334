import { useEffect, useState } from "react";
import UserGroup from "../../models/userGroup";
import DropdownOption from "./dropdownOption";
import userService from "../../services/userService";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const UsersGroups: React.FC<{
  userGroups: UserGroup[];
  controlLabel: string;
  onUserGroupChanged: any;
}> = ({ userGroups, controlLabel, onUserGroupChanged }) => {
  const [allUserGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [userGroupOptions, setUserGroupsOptions] = useState<DropdownOption[]>([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState<DropdownOption[]>([]);

  const populateUserGroups = async () => {
    const dropdownOption: DropdownOption[] = [];
    const { data: userGroups } = await userService.getUserGroups();
    setUserGroups(userGroups);

    for (const userGroup of userGroups) {
      const newOption = new DropdownOption();
      newOption.value = userGroup.idUserGroup;
      newOption.label = userGroup.userGroupName;
      dropdownOption.push(newOption);
    }

    setUserGroupsOptions(dropdownOption);
  };

  const populateSelectedUserGroupss = (userGroups: UserGroup[]) => {
    const dropdownOption: DropdownOption[] = [];
    for (const userGroup of userGroups) {
      const newOption = new DropdownOption();
      newOption.value = userGroup.idUserGroup;
      newOption.label = userGroup.userGroupName;
      dropdownOption.push(newOption);
    }

    setSelectedUserGroups(dropdownOption);
  };

  useEffect(() => {
    populateUserGroups();
    populateSelectedUserGroupss(userGroups);
  }, [userGroups]);

  const onUserGroupsChange = (event: any) => {
    const newUserGroups: UserGroup[] = [];

    for (const item of event) {
      const existingUserGroup = allUserGroups.find(
        (t) => t.idUserGroup === item.value && t.userGroupName === item.label
      );
        if (existingUserGroup) {
          newUserGroups.push(existingUserGroup);
        }

      // if (existingUserGroup) {
      //   newUserGroups.push(existingUserGroup);
      // } else {
      //   const newTrade = new UserGroup();
      //   newTrade.tradeName = item.label;
      //   newUserGroups.push(newTrade);
      // }
    }

    onUserGroupChanged(newUserGroups);
    populateSelectedUserGroupss(newUserGroups);
  };

  return (
    <div>
      <label htmlFor="userGroup" className="form-label">
        {controlLabel}
      </label>
      {/* <CreatableSelect
        isMulti
        id="userGroup"
        options={userGroupOptions}
        value={selectedUserGroups}
        onChange={(newValue: any) => onUserGroupsChange(newValue)}
        placeholder="Type a trade and press enter"
      /> */}
      <Select
        // defaultValue={[colourOptions[2], colourOptions[3]]}
        value={selectedUserGroups}
        isMulti
        name="colors"
        options={userGroupOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={(newValue: any) => onUserGroupsChange(newValue)}
      />
    </div>
  );
};

export default UsersGroups;
