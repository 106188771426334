import Account from "../models/account";
import httpService from "./httpService";
import Location from "../models/location";

const apiEndpoint = "/accounts";

const getAccounts = (idClientAccount: number) => {
  return httpService.get<Account[]>(
    `${apiEndpoint}/getAccounts?idClientAccount=${idClientAccount}`
  );
};

const getAccountById = (idAccount: number) => {
  return httpService.get<Account>(
    `${apiEndpoint}/getAccountById?idAccount=${idAccount}`
  );
};

const createAccount = (account: Account) => {
  return httpService.post<Account>(`${apiEndpoint}/createAccount`, account);
};

const updateAccount = (account: Account) => {
  return httpService.post<Account>(`${apiEndpoint}/updateAccount`, account);
};

const deleteAccount = (account: Account) => {
  return httpService.delete<Account>(`${apiEndpoint}/deleteAccount`, {
    data: account,
  });
};
const getLocations = (idClientAccount: number) => {
  return httpService.get<Location[]>(
    `${apiEndpoint}/getLocations?idClientAccount=${idClientAccount}`
  );
};

const getLocationById = (idLocation: number) => {
  return httpService.get<Location>(
    `${apiEndpoint}/getLocationById?idLocation=${idLocation}`
  );
};

const createLocation = (location: Location) => {
  return httpService.post<Location>(`${apiEndpoint}/createLocation`, location);
};

const updateLocation = (location: Location) => {
  return httpService.post<Location>(`${apiEndpoint}/updateLocation`, location);
};

const deleteLocation = (location: Location) => {
  return httpService.delete<Location>(`${apiEndpoint}/deleteLocation`, {
    data: location,
  });
};

const accountService = {
  getAccounts,
  getAccountById,
  createAccount,
  updateAccount,
  deleteAccount,
  getLocations,
  getLocationById,
  createLocation,
  updateLocation,
  deleteLocation,
};

export default accountService;
