import httpService from "./httpService";
import Trade from "../models/trade";
import Problem from "../models/problem";

const apiEndpoint = "/trades";

const getTrades = () => {
  return httpService.get<Trade[]>(`${apiEndpoint}/getTrades`);
};

const getTradeById = (idTrade: number) => {
  return httpService.get<Trade>(
    `${apiEndpoint}/getTradeById?idTrade=${idTrade}`
  );
};

const getProblems = () => {
  return httpService.get<Problem[]>(`${apiEndpoint}/getProblems`);
};

const createTrade = (trade: Trade) => {
  return httpService.post<Trade>(`${apiEndpoint}/createTrade`, trade);
};

const updateTrade = (trade: Trade) => {
  return httpService.post<Trade>(`${apiEndpoint}/updateTrade`, trade);
};

const deleteTrade = (trade: Trade) => {
  return httpService.delete<Trade>(`${apiEndpoint}/deleteTrade`, {
    data: trade,
  });
};

const updateProblem = (problem: Problem) => {
  return httpService.post<Problem>(`${apiEndpoint}/updateProblem`, problem);
};

const deleteProblem = (problem: Problem) => {
  return httpService.delete<Problem>(`${apiEndpoint}/deleteProblem`, {
    data: problem,
  });
};

const tradeService = {
  getTrades,
  getProblems,
  updateTrade,
  deleteTrade,
  updateProblem,
  deleteProblem,
  createTrade,
  getTradeById,
};

export default tradeService;
