import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DispatchType from "../../models/dispatchType";
import MaintenanceCompany from "../../models/maintenanceCompany";
import workOrderService from "../../services/workOrderService";
import Select from "react-select";
import ServiceProvider from "../../models/serviceProvider";
import dispatchStatusHelper from "../../models/dispatchStatusHelper";
import SelectOption from "../../components/common/selectedOption";

const DispatchNew: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [dispatchFromTypes, setDispatchFromTypes] = useState<DispatchType[]>(
    []
  );
  const [dispatchToTypes, setDispatchToTypes] = useState<DispatchType[]>([]);

  const [dispatchFrom, setDispatchFrom] = useState<DispatchType>(
    DispatchType.Account
  );

  const [dispatchTo, setDispatchTo] = useState<DispatchType>(
    DispatchType.MaintenanceCompany
  );

  const [maintenanceCompanyOptions, setMaintenanceCompanyOptions] = useState<
    SelectOption<MaintenanceCompany>[]
  >([]);

  const [serviceProviderOptions, setServiceProviderOptions] = useState<
    SelectOption<ServiceProvider>[]
  >([]);

  useEffect(() => {
    if (!location.state) {
      navigate("/work/dispatches");
      return;
    }

    const init = async () => {
      const maintenanceCompanies =
        await workOrderService.getMaintenanceCompanies();

      const maintenanceCompaniesOptions: SelectOption<MaintenanceCompany>[] =
        [];

      for (const mc of maintenanceCompanies) {
        maintenanceCompaniesOptions.push({
          value: mc,
          label: mc.companyName,
        });
      }

      setMaintenanceCompanyOptions(maintenanceCompaniesOptions);

      const serviceProviders = await workOrderService.getServiceProviders();

      const serviceProviderOptions: SelectOption<ServiceProvider>[] = [];

      for (const sp of serviceProviders) {
        serviceProviderOptions.push({
          value: sp,
          label: sp.serviceProviderName,
        });
      }

      setServiceProviderOptions(serviceProviderOptions);
    };

    const dispatchFromTypes = [];
    dispatchFromTypes.push(DispatchType.Account);
    dispatchFromTypes.push(DispatchType.MaintenanceCompany);
    setDispatchFromTypes(dispatchFromTypes);

    const dispatchToTypes = [];
    dispatchToTypes.push(DispatchType.MaintenanceCompany);
    dispatchToTypes.push(DispatchType.ServiceProvider);
    dispatchToTypes.push(DispatchType.User);
    setDispatchToTypes(dispatchToTypes);

    //const { workOrder, parentDispatch } = location.state;
    const { parentDispatch } = location.state;

    if (parentDispatch) {
      setDispatchFrom(DispatchType.MaintenanceCompany);
      setDispatchTo(DispatchType.ServiceProvider);
    }

    init();
  }, [location, navigate]);

  if (!location.state) {
    return <></>;
  }

  const { workOrder, parentDispatch } = location.state;

  const getDispatchTypePlural = (dispatchType: DispatchType): string => {
    let plural = "";

    switch (dispatchType) {
      case DispatchType.MaintenanceCompany:
        plural = "Maintenance Companies";
        break;

      case DispatchType.ServiceProvider:
        plural = "Service Providers";
        break;

      case DispatchType.User:
        plural = "Users";
        break;

      default:
        break;
    }

    return plural;
  };

  const onDispatchFromChanged = (event: any) => {
    const newValue: DispatchType = event.target.value;
    setDispatchFrom(newValue);
  };

  const onDispatchToChanged = (event: any) => {
    const newValue: DispatchType = event.target.value;
    setDispatchTo(newValue);
  };

  const onCancel = () => {
    navigate(`/work/work-orders/${workOrder.idWorkOrder}`);
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>{parentDispatch ? "Forward Dispatch" : "New Dispatch"}</h1>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <div className="mb-3">
                <label>Work Order - {workOrder.workOrderNumber}</label>
              </div>

              {parentDispatch && (
                <div className="card p-4 mb-3">
                  <div className="mb-3">
                    <label>
                      <b>Initial Dispatch</b>
                    </label>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-hover table-responsive horizontal-center-td">
                      <thead>
                        <tr>
                          <th scope="col">Dispatch</th>
                          <th scope="col">From</th>
                          <th scope="col">To</th>
                          <th scope="col">To Name</th>
                          <th scope="col">Status</th>
                          <th scope="col">Has Proposal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {parentDispatch.idParentDispatch !== undefined && (
                              <span
                                style={{
                                  display: "inline-block",
                                  width: "30px",
                                }}
                              ></span>
                            )}
                            {parentDispatch.dispatchNumber}
                          </td>
                          <td>{parentDispatch.dispatchFrom}</td>
                          <td>{parentDispatch.dispatchTo}</td>
                          <td>
                            {
                              parentDispatch.maintenanceCompany
                                ?.maintenanceCompanyName
                            }
                          </td>
                          <td>
                            <span
                              className={dispatchStatusHelper.statusToBadgeClass(
                                parentDispatch.dispatchStatus
                              )}
                            >
                              {parentDispatch.dispatchStatus}
                            </span>
                          </td>
                          <td>
                            {parentDispatch.proposals.length === 0
                              ? "False"
                              : "True"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <div className="mb-4">
                <label>
                  <b>Dispatch</b>
                </label>
              </div>

              <div className="row mb-3">
                <label className="col-sm-1">Dispatch From</label>
                <div className="col-sm-11">
                  <select
                    className="form-select"
                    style={{ marginLeft: "10px" }}
                    aria-label="select"
                    value={dispatchFrom}
                    onChange={onDispatchFromChanged}
                  >
                    {dispatchFromTypes.map((dispatchType, index) => (
                      <option key={index.toString()} value={dispatchType}>
                        {dispatchType}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <label className="col-sm-1">Dispatch To</label>
                <div className="col-sm-11">
                  <select
                    className="form-select"
                    style={{ marginLeft: "10px" }}
                    aria-label="select"
                    value={dispatchTo}
                    onChange={onDispatchToChanged}
                  >
                    {dispatchToTypes.map((dispatchType, index) => (
                      <option key={index.toString()} value={dispatchType}>
                        {getDispatchTypePlural(dispatchType)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {dispatchTo === DispatchType.MaintenanceCompany && (
                <Select
                  isMulti
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  name="maintenanceCompanyOptions"
                  options={maintenanceCompanyOptions}
                />
              )}

              {dispatchTo === DispatchType.ServiceProvider && (
                <Select
                  isMulti
                  className="basic-multi-select mb-3"
                  classNamePrefix="select"
                  name="serviceProviderOptions"
                  options={serviceProviderOptions}
                />
              )}

              <div className="row mb-3">
                <div className="col-12">
                  <label htmlFor="scopeOfWork" className="form-label">
                    Scope of Work
                  </label>
                  <textarea
                    className="form-control"
                    id="scopeOfWork"
                    style={{ height: "100px" }}
                  />
                </div>
              </div>

              <div>
                <button className="btn btn-primary" onClick={() => onCancel()}>
                  Dispatch
                </button>{" "}
                <button
                  className="btn btn-secondary"
                  onClick={() => onCancel()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default DispatchNew;
