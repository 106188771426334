import { Helmet } from "react-helmet-async";
import Header from "../components/home/Header";

const About: React.FC = () => {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/app-home.css"
        />
      </Helmet>
      <Header />
      <main className="main">
        {/* <!-- About Section --> */}
        <section id="about" className="about section under-header">
          <div className="container section-title" data-aos="fade-up">
            <p>
              Work Order Express, or WOX, your ultimate companion for property
              maintenance.
            </p>
            <span>
              Our app is designed to streamline the process of inspecting,
              documenting, and managing property maintenance needs,
              <br /> ensuring that every property you care for remains in top
              condition.
            </span>
          </div>
          <div className="container" data-aos="fade-up">
            <div className="row gx-0">
              <div className="col-lg-6 p-2">
                <div className="content">
                  <h3>Who We Are</h3>
                  <h2>Team of experts</h2>
                  <p>
                    At WOX, we are a dedicated team of property management
                    experts, software developers, and customer service
                    professionals who understand the challenges of maintaining
                    properties. Our mission is to provide an intuitive,
                    efficient, and reliable tool that helps property managers,
                    landlords, and maintenance teams conduct thorough
                    inspections and stay on top of maintenance tasks.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 p-2 mt-2">
                <div className="content">
                  <h3>Our Vision</h3>
                  <h2>
                    Connecting Propery Owners, Maintenance Companies and Service
                    Providers Together
                  </h2>
                  <p>
                    We envision a world where property maintenance is no longer
                    a hassle but a seamless and straightforward process. By
                    leveraging technology, we aim to revolutionize the property
                    management industry, making it easier for you to ensure your
                    properties are safe, well-maintained, and compliant with
                    regulations.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 p-2 about-home">
                <div className="content">
                  <h3 style={{ marginBottom: "1rem" }}>What We Offer</h3>
                  <h2 style={{ lineHeight: "0px", marginBottom: "1rem" }}>
                    Complete Solution
                  </h2>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i>{" "}
                      <b>Comprehensive Surveys</b>
                      <br />
                      Conduct detailed property maintenance surveys with ease.
                      Our app guides you through the process, ensuring no detail
                      is overlooked.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i>{" "}
                      <b>Real-time Reporting</b>
                      <br />
                      Generate and share real-time reports with your team or
                      clients. Stay updated on the status of maintenance tasks
                      and inspections.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i>{" "}
                      <b>Photo Documentation</b>
                      <br />
                      Capture and annotate photos directly within the app to
                      document issues or completed work, providing clear visual
                      records.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i>{" "}
                      <b>Cloud Storage</b>
                      <br />
                      Securely store and access your data from anywhere,
                      anytime. Never worry about losing important information
                      again.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 p-2 mt-2">
                <div className="d-flex flex-column justify-content-center">
                  <div className="content mb-3">
                    <h3>Our Commitment</h3>
                    <h2>Continuous improvement and innovation</h2>
                    <p>
                      At WOX, we are committed to continuous improvement and
                      innovation. We listen to our users' feedback and strive to
                      enhance our app to meet your evolving needs. Our support
                      team is always ready to assist you, ensuring you get the
                      most out of WOX.
                    </p>
                  </div>
                  <div className="content">
                    <h3>Join Us</h3>
                    <h2>Be a part of us</h2>
                    <p>
                      Join the growing community of property managers who trust
                      WOX for their maintenance surveys. Experience the
                      difference that a dedicated, user-friendly app can make in
                      managing your properties efficiently and effectively.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container section-title" data-aos="fade-up">
            <p>Thank you for choosing WOX.</p>
            <span>Together, let's keep your properties in perfect shape.</span>
          </div>
        </section>
        {/* <!-- /About Section --> */}
      </main>
    </>
  );
};

export default About;
