import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import workOrderService from "../../services/workOrderService";
import WorkOrder from "../../models/workOrder";
import workOrderStatusHelper from "../../models/workOrderStatusHelper";
import Moment from "react-moment";
import Account from "../../models/account";
import Location from "../../models/location";
import WorkOrderLink from "../../components/work-order/WorkOrderLink";
import AccountLink from "../../components/work/AccountLink";
import LocationLink from "../../components/work/LocationLink";

const WorkOrders: React.FC = () => {
  const navigate = useNavigate();

  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);

  useEffect(() => {
    const init = async () => {
      try {
        const workOrders = await workOrderService.getWorkOrders();
        setWorkOrders(workOrders);
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    init();
  }, []);

  const onAddNewClick = () => {
    navigate("/work/work-orders/new");
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Work Orders</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <div className="d-flex justify-content-between">
                <div
                  className="search-bar d-flex"
                  style={{ maxWidth: "500px" }}
                >
                  <i
                    style={{
                      fontSize: "50px",
                      color: "var(--accent-color)",
                      marginRight: "10px",
                      marginTop: "4px",
                    }}
                    className="bx bxs-notepad"
                  ></i>
                  <form className="search-form d-flex align-items-center">
                    <input
                      type="text"
                      name="query"
                      placeholder="Search Work Orders"
                      title="Enter search keyword"
                    />
                    <button type="submit" title="Search">
                      <i className="bi bi-search"></i>
                    </button>
                  </form>
                </div>
                <div className="nav-item d-block d-lg-none">
                  <button
                    className="nav-link nav-icon search-bar-toggle "
                    title="Search"
                  >
                    <i className="bi bi-search"></i>
                  </button>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => onAddNewClick()}
                  >
                    Add New
                  </button>
                  <div>{`${workOrders.length} work orders`}</div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-responsive horizontal-center-td">
                  <thead>
                    <tr>
                      <th scope="col">WO#</th>
                      <th scope="col">Date</th>
                      <th scope="col">Account</th>
                      <th scope="col">Location</th>
                      <th scope="col">City</th>
                      <th scope="col">Priority</th>
                      <th scope="col">Trade</th>
                      {/* <th scope="col">Problem Code</th> */}
                      <th scope="col">Problem</th>
                      <th scope="col">WO Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Serviced by</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workOrders.map((workOrder: WorkOrder) => (
                      <tr
                        className="with-hover"
                        key={workOrder.idWorkOrder.toString()}
                      >
                        <td>
                          <WorkOrderLink workOrder={workOrder} />
                        </td>
                        <td>
                          <Moment format="MMM D YYYY hh:mm A">
                            {workOrder.callDate}
                          </Moment>
                        </td>
                        <td>
                          <AccountLink account={workOrder.account} />
                        </td>
                        <td>
                          <LocationLink location={workOrder.location} />
                        </td>
                        <td>{workOrder.location.address.city}</td>
                        <td>{workOrder.workOrderPriority}</td>
                        <td>{workOrder.trade.tradeName}</td>
                        {/* <td>{workOrder.problem.problemCode}</td> */}
                        <td>{workOrder.problem.problemName}</td>
                        <td>{workOrder.workOrderType}</td>
                        <td>
                          <span
                            className={workOrderStatusHelper.statusToBadgeClass(
                              workOrder.workOrderStatus
                            )}
                          >
                            {workOrder.workOrderStatus}
                          </span>
                        </td>
                        <td>Service Provider Name</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default WorkOrders;
