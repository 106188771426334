import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import User from "../../models/user";
import userService from "../../services/userService";
//import timeService from "../../services/timeService";
import formHelper from "../../util/formHelper";

import ModalWindow from "../../components/common/ModalWindow";
import ValidationManager from "../../components/common/validationManager";
import Validator from "../../components/common/Validator";
import UserGroup from "../../models/userGroup";
import UsersGroups from "../../components/common/UsersGroups";

const validator = {
  username: "username",
  email: "email",
  password: "password",
  firstName: "firstName",
  lastName: "lastName",
  //name: "name",
  phone: "phone",
  phoneExtension: "phoneExtension",
  title: "title",
  userGroups: "userGroups",
};

const UserEdit: React.FC = () => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>(true);
  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [user, setUser] = useState<User>(new User());
  //const [timeZones, setTmeZones] = useState<string[]>([]);
  const [validationManager] = useState<ValidationManager>(
    new ValidationManager()
  );

  useEffect(() => {
    // const getTimeZones = async () => {
    //   const { data: timeZones } = await timeService.getTimeZones();

    //   if (timeZones) {
    //     setTmeZones(timeZones);
    //   }
    // };

    const initEdit = async (idUser: number) => {
      try {
        const { data: user } = await userService.getUserById(idUser);

        if (user) {
          setUser(user);
        }

        validationManager.setIsValid(validator.username, true);
        validationManager.setIsValid(validator.password, true);
        validationManager.setIsValid(validator.email, true);

        validationManager.setIsValid(validator.firstName, true);
        validationManager.setIsValid(validator.lastName, true);
        validationManager.setIsValid(validator.title, true);
        validationManager.setIsValid(validator.phone, true);
        validationManager.setIsValid(validator.phoneExtension, true);
        validationManager.setIsValid(validator.userGroups, true);
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    const isNew = idParam === "new";
    setIsNew(isNew);
    //getTimeZones();

    if (!isNew) {
      const idUser = Number(idParam);
      initEdit(idUser);
    }
  }, [idParam, validationManager]);

  const onChange = (event: any) => {
    const { id, name, value } = formHelper.getDataFromEvent(event.target);
    const newUser = { ...user, [name]: value };

    setUser(newUser);

    if (id === validator.username) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.username, isValid);
    }

    if (id === validator.password) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.password, isValid);
    }

    if (id === validator.email) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.email, isValid);
    }

    if (id === validator.firstName) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.firstName, isValid);
    }

    if (id === validator.lastName) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.lastName, isValid);
    }

    if (id === validator.title) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.title, isValid);
    }

    if (id === validator.phone) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.phone, isValid);
    }

    if (id === validator.phoneExtension) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.phoneExtension, isValid);
    }
  };

  const onUserGroupsChange = (newUserGroups: UserGroup[]) => {
    const newUser = {
      ...user,
      userGroups: newUserGroups,
    };
    setUser(newUser);
    const isValid = newUserGroups.length !== 0;
    validationManager.setIsValid(validator.userGroups, isValid);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setErrorMessage("");

    if (!validationManager.validate()) {
      return;
    }

    try {
      if (isNew) {
        try {
          await userService.createUser(user);
        } catch (error: any) {
          const errorMessage = error.response.data;
          setErrorMessage(errorMessage);
          return;
        }
      } else {
        await userService.updateUser(user);
      }
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    navigate("/work/users");
  };

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = () => {
    setShowDeleteWindow(false);
    onDelete();
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const onDelete = async () => {
    await userService.deleteUser(user);
    navigate("/work/users");
  };

  const onCancel = () => {
    navigate("/work/users");
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>{isNew ? "Create" : "Edit"} User</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <form
                className="row g-3"
                onSubmit={onSubmit}
                onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
              >
                <div className="col-4">
                  <Validator
                    name={validator.username}
                    errorMessage="Please enter a user name."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="username" className="form-label">
                        User name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        name={formHelper.nameOf<User>((t) => t.username)}
                        value={user.username}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>
                <div className="col-4">
                  <Validator
                    name={validator.password}
                    errorMessage="Please enter a password."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name={formHelper.nameOf<User>((t) => t.password)}
                        value={user.password}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>
                <div className="col-4">
                  <Validator
                    name={validator.email}
                    errorMessage="Please enter the email."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name={formHelper.nameOf<User>((t) => t.email)}
                        value={user.email}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>

                <div className="col-3">
                  <Validator
                    name={validator.firstName}
                    errorMessage="Please enter the first name."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name={formHelper.nameOf<User>((t) => t.firstName)}
                        value={user.firstName}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>
                <div className="col-3">
                  <Validator
                    name={validator.lastName}
                    errorMessage="Please enter the last name."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name={formHelper.nameOf<User>((t) => t.lastName)}
                        value={user.lastName}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>
                <div className="col-3">
                  <Validator
                    name={validator.title}
                    errorMessage="Please enter the title."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="title" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name={formHelper.nameOf<User>((t) => t.title)}
                        value={user.title}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>

                <div className="col-4">
                  <Validator
                    name={validator.phone}
                    errorMessage="Please enter the phone."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="title" className="form-label">
                        Phone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name={formHelper.nameOf<User>((t) => t.phone)}
                        value={user.phone}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>
                <div className="col-4">
                  <Validator
                    name={validator.phoneExtension}
                    errorMessage="Please enter the extension."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="title" className="form-label">
                        Extension
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phoneExtension"
                        name={formHelper.nameOf<User>((t) => t.phoneExtension)}
                        value={user.phoneExtension}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>
                <div className="col-4">
                  <Validator
                    name={validator.userGroups}
                    errorMessage="Please select a user group."
                    validationManager={validationManager}
                  >
                    <div>
                      <UsersGroups
                        userGroups={user.userGroups}
                        controlLabel="User Group"
                        onUserGroupChanged={onUserGroupsChange}
                      />
                    </div>
                  </Validator>
                </div>

                <div className="form-check col-sm-4">
                  <label className="form-check-label" htmlFor="isAdmin">
                    Is Admin
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={user.isAdmin}
                    id="isAdmin"
                    name={formHelper.nameOf<User>((t) => t.isAdmin)}
                    onChange={onChange}
                  />
                </div>
                <div className="form-check col-sm-4">
                  <label className="form-check-label" htmlFor="isDemoUser">
                    Is Demo User
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={user.isDemoUser}
                    id="isDemoUser"
                    name={formHelper.nameOf<User>((t) => t.isDemoUser)}
                    onChange={onChange}
                  />
                </div>
                <div className="form-check col-sm-4">
                  <label className="form-check-label" htmlFor="isPortalAdmin">
                    Is Portal Admin
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={user.isPortalAdmin}
                    id="isPortalAdmin"
                    name={formHelper.nameOf<User>((t) => t.isPortalAdmin)}
                    onChange={onChange}
                  />
                </div>

                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div>
                      <button type="submit" className="btn btn-primary">
                        {isNew ? "Create" : "Save"}
                      </button>{" "}
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </button>
                    </div>
                    {!isNew && (
                      <div>
                        <button
                          type="reset"
                          className="btn btn-danger"
                          onClick={() => onDeleteConfirm()}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row text-center">
                  {errorMessage !== "" && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>{`Are you sure you want to delete the Service Provider?`}</div>
        </ModalWindow>
      )}
    </main>
  );
};

export default UserEdit;
