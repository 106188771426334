import { assertFC } from "./assertFC";
import { useState } from "react";
import DeleteIcon from "./icons/DeleteIcon";
import ValidationManager from "./validationManager";

export type ItemComponent<TModel = {}> = React.FC<ItemComponentProps<TModel>>;

export interface ItemComponentProps<TModel> {
  item: TModel;
  onChange: any;
  isNew: boolean;
  validationManager: ValidationManager;
  validationId: string;
}
interface IProps<TModel> {
  title: string;
  fields: TModel[];
  fieldsChanged: any;
  isNew: boolean;
  validationManager: ValidationManager;
  Component: ItemComponent<TModel>;
}

export type ItemsListEditorProps<T> = IProps<T>;

export function ItemsListEditor<T>(props: ItemsListEditorProps<T>) {
  const { title, fields, fieldsChanged, isNew, validationManager, Component } =
    props;

  const [isNewItem, setIsNewItem] = useState(false);

  const fieldChangedHandler = (index: number, updatedItem: any) => {
    const newFields = fields.map((item, i) =>
      i === index ? updatedItem : item
    );
    fieldsChanged(newFields);
  };

  const deleteFieldHandler = (index: number) => {
    const newFields = fields.filter((item, i) => (i === index ? false : true));
    fieldsChanged(newFields);
  };

  const addFieldHandler = () => {
    setIsNewItem(true);
    const newFields = [...fields, {}];
    fieldsChanged(newFields);
  };

  return (
    <div>
      {title !== "" && title !== undefined && <label>{title}</label>}

      <ul className="list-group mt-2">
        {fields.map((field: any, index: number) => {
          return (
            <li className="row mb-3" key={index.toString()}>
              <Component
                item={field}
                onChange={(pn: any) => fieldChangedHandler(index, pn)}
                isNew={isNewItem}
                validationManager={validationManager}
                validationId={index.toString()}
              />
              <div className="col-md-1 mb-3">
                <button
                  className="btn btn-danger btn-sm"
                  type="button"
                  onClick={() => deleteFieldHandler(index)}
                >
                  <DeleteIcon />
                </button>
              </div>
            </li>
          );
        })}
      </ul>
      <button
        className="btn btn-primary"
        type="button"
        onClick={addFieldHandler}
      >
        Add
      </button>
    </div>
  );
}

assertFC(ItemsListEditor);
