import WorkOrderStatus from "./workOrderStatus";

const statusToBadgeClass = (workOrderStatus: WorkOrderStatus) => {
  let badgeTypeClass = "";

  switch (workOrderStatus) {
    case WorkOrderStatus.NotDispatched:
      badgeTypeClass = "bg-primary";
      break;

    case WorkOrderStatus.Dispatched:
      badgeTypeClass = "bg-info";
      break;

    case WorkOrderStatus.Completed:
      badgeTypeClass = "bg-success";
      break;

    case WorkOrderStatus.Canceled:
      badgeTypeClass = "bg-danger";
      break;

    default:
      break;
  }

  return "badge " + badgeTypeClass;
};

const workOrderStatusHelper = {
  statusToBadgeClass,
};

export default workOrderStatusHelper;
