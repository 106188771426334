import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Account from "../../models/account";
import accountService from "../../services/accountService";
import formHelper from "../../util/formHelper";
import AddressEdit from "../../components/common/AddressEdit";
import Address from "../../models/address";
import ModalWindow from "../../components/common/ModalWindow";
import ValidationManager from "../../components/common/validationManager";
import Validator from "../../components/common/Validator";
import User from "../../models/user";
import UserContext from "../../contexts/userContext";

const validator = {
  accountName: "accountName",
  currency: "currency",
};

const AccountEdit: React.FC = () => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const user = useContext<User | undefined>(UserContext);

  const [isNew, setIsNew] = useState<boolean>(true);
  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [account, setAccount] = useState<Account>(new Account());

  const [currencies, setCurrencies] = useState<string[]>([]);

  const [validationManager] = useState<ValidationManager>(
    new ValidationManager()
  );

  useEffect(() => {
    const initEdit = async (idAccount: number) => {
      try {
        const { data: account } = await accountService.getAccountById(
          idAccount
        );

        if (account) {
          setAccount(account);

          validationManager.setIsValid(validator.accountName, true);
          validationManager.setIsValid(validator.currency, true);
        }
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    const currencies = getCurrencies();
    setCurrencies(currencies);

    const isNew = idParam === "new";
    setIsNew(isNew);

    if (!isNew) {
      const idAccount = Number(idParam);
      initEdit(idAccount);
    }
  }, [idParam, validationManager]);

  const getCurrencies = (): string[] => {
    const currencies = ["USD", "CAD"];
    return currencies;
  };

  const getFieldAndValueByEvent = ({ id, name, type, checked, value }: any) => {
    const fieldValue = type === "checkbox" ? checked : value;
    return {
      value: fieldValue,
      id: id,
    };
  };

  const onChange = (event: any) => {
    const { id, value } = getFieldAndValueByEvent(event.target);
    const newAccount = { ...account, [id]: value };
    setAccount(newAccount);

    if (id === validator.accountName) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.accountName, isValid);
    }
    if (id === validator.currency) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.currency, isValid);
    }
  };

  const onShippingAddressChange = (address: Address) => {
    const newAccount = { ...account, shippingAddress: address };
    setAccount(newAccount);
  };

  const onBillingAddressChange = (address: Address) => {
    const newAccount = { ...account, billingAddress: address };
    setAccount(newAccount);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setErrorMessage("");

    if (!validationManager.validate()) {
      return;
    }

    try {
      if (isNew) {
        setIsBusy(true);

        if (user) {
          var idClientAccount = user.currentClientAccount.idClientAccount;
          const newAccount = { ...account, idClientAccount };
          setAccount(newAccount);

          await accountService.createAccount(newAccount);
          setIsBusy(false);
        }
      } else {
        setIsBusy(true);
        await accountService.updateAccount(account);
        setIsBusy(false);
      }
    } catch (error: any) {
      setIsBusy(false);
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    navigate("/work/accounts");
  };

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = () => {
    setShowDeleteWindow(false);
    onDelete();
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const onDelete = async () => {
    setIsBusy(true);
    await accountService.deleteAccount(account);
    setIsBusy(false);
    navigate("/work/accounts");
  };

  const onCancel = () => {
    navigate("/work/accounts");
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>{isNew ? "Create" : "Edit"} Account</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <form
                className="row g-3"
                onSubmit={onSubmit}
                onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
              >
                <div className="col-md-10">
                  <Validator
                    name={validator.accountName}
                    errorMessage="Please enter an account name."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="accountName" className="form-label">
                        Account Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="accountName"
                        value={account.accountName ?? ""}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>

                <div className="col-md-2">
                  <Validator
                    name={validator.currency}
                    errorMessage="Please enter a currency."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="currency" className="form-label">
                        Currency
                      </label>
                      <select
                        className="form-select"
                        aria-label="select"
                        id="currency"
                        value={account.currency ?? ""}
                        onChange={onChange}
                      >
                        <option value="" disabled>
                          Please select
                        </option>
                        {currencies.map((currency, index) => (
                          <option key={index.toString()} value={currency}>
                            {currency}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Validator>
                </div>

                <div
                  className="card"
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  <div
                    className="card-header"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    Shipping Address
                  </div>
                  <div className="card-body">
                    <AddressEdit
                      inputAddress={account.shippingAddress}
                      onChange={onShippingAddressChange}
                      isNew={isNew}
                      validationManager={validationManager}
                      validationId="shipping"
                    />
                  </div>
                </div>

                <div className="form-check">
                  <div className="col-12">
                    <label
                      className="form-check-label"
                      htmlFor="isSameShippingBillingAddress"
                    >
                      Same as billing address
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={account.isSameShippingBillingAddress}
                      id="isSameShippingBillingAddress"
                      onChange={onChange}
                    />
                  </div>
                </div>
                {!account.isSameShippingBillingAddress && (
                  <div
                    className="card"
                    style={{
                      marginBottom: "0px",
                    }}
                  >
                    <div
                      className="card-header"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Billing Address
                    </div>
                    <div className="card-body">
                      <AddressEdit
                        inputAddress={account.billingAddress}
                        onChange={onBillingAddressChange}
                        isNew={isNew}
                        validationManager={validationManager}
                        validationId="billing"
                      />
                    </div>
                  </div>
                )}

                <div className="col-md-4">
                  <label htmlFor="onSitePropertyManager" className="form-label">
                    On Site Property Manager
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="onSitePropertyManager"
                    value={account.onSitePropertyManager ?? ""}
                    onChange={onChange}
                  />
                </div>

                <div className="col-md-4">
                  <label
                    htmlFor="onSiteManagerPhoneNumber"
                    className="form-label"
                  >
                    On Site Manager Phone #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="onSiteManagerPhoneNumber"
                    value={account.onSiteManagerPhoneNumber ?? ""}
                    onChange={onChange}
                  />
                </div>

                <div className="col-md-4">
                  <label htmlFor="onSiteManagerEmail" className="form-label">
                    On Site Manager Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="onSiteManagerEmail"
                    value={account.onSiteManagerEmail ?? ""}
                    onChange={onChange}
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="serviceInstructions" className="form-label">
                    Service Instructions
                  </label>
                  <textarea
                    className="form-control"
                    id="serviceInstructions"
                    value={account.serviceInstructions ?? ""}
                    onChange={onChange}
                    style={{ height: "100px" }}
                  />
                </div>

                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isBusy}
                      >
                        {isNew ? "Create" : "Save"}
                      </button>{" "}
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </button>
                    </div>
                    {!isNew && (
                      <div>
                        <button
                          type="reset"
                          className="btn btn-danger"
                          onClick={() => onDeleteConfirm()}
                          disabled={isBusy}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row text-center">
                  {errorMessage !== "" && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>{`Are you sure you want to delete the account?`}</div>
        </ModalWindow>
      )}
    </main>
  );
};

export default AccountEdit;
