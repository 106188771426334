import DispatchStatus from "./dispatchStatus";

const statusToBadgeClass = (dispatchStatus: DispatchStatus) => {
  let badgeTypeClass = "";

  switch (dispatchStatus) {
    case DispatchStatus.Requested:
      badgeTypeClass = "bg-primary";
      break;

    case DispatchStatus.InDiscussion:
      badgeTypeClass = "bg-info";
      break;

    case DispatchStatus.Accepted:
      badgeTypeClass = "bg-success";
      break;

    case DispatchStatus.Rejected:
      badgeTypeClass = "bg-danger";
      break;

    default:
      break;
  }

  return "badge " + badgeTypeClass;
};

const dispatchStatusHelper = {
  statusToBadgeClass,
};

export default dispatchStatusHelper;
