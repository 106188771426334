import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import accountService from "../../services/accountService";
import formHelper from "../../util/formHelper";
import AddressEdit from "../../components/common/AddressEdit";
import Address from "../../models/address";
import ModalWindow from "../../components/common/ModalWindow";
import Location from "../../models/location";
import Account from "../../models/account";
import ValidationManager from "../../components/common/validationManager";
import Validator from "../../components/common/Validator";
import User from "../../models/user";
import UserContext from "../../contexts/userContext";

const validator = {
  accountNameSelection: "accountNameSelection",
  locationName: "locationName",
  locationNumber: "locationNumber",
};

const LocationEdit: React.FC = () => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const user = useContext<User | undefined>(UserContext);

  const [isNew, setIsNew] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [validationManager] = useState<ValidationManager>(
    new ValidationManager()
  );

  const [location, setLocation] = useState<Location>(new Location());
  const [accounts, setAccounts] = useState<Account[]>([]);

  useEffect(() => {
    const initEdit = async (idLocation: number) => {
      try {
        const { data: location } = await accountService.getLocationById(
          idLocation
        );

        if (location) {
          setLocation(location);

          validationManager.setIsValid(validator.locationName, true);
          validationManager.setIsValid(validator.locationNumber, true);
        }
      } catch (error) {
        // TODO: show error with toastr
        console.log(error);
      }
    };

    const getAccounts = async () => {
      try {
        if (user) {
          var idClientAccount = user.currentClientAccount.idClientAccount;
          const { data: accounts } = await accountService.getAccounts(
            idClientAccount
          );
          setAccounts(accounts);
        }
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    const isNew = idParam === "new";
    setIsNew(isNew);
    getAccounts();

    if (!isNew) {
      const idLocation = Number(idParam);
      initEdit(idLocation);
    } else {
      validationManager.setIsValid(validator.accountNameSelection, true);
    }
  }, [idParam, validationManager, user]);

  const onChange = (event: any) => {
    const { id, value } = formHelper.getDataFromEvent(event.target);
    const newLocation = { ...location, [id]: value };
    setLocation(newLocation);

    if (id === validator.locationName) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.locationName, isValid);
    }

    if (id === validator.locationNumber) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.locationNumber, isValid);
    }
  };

  const onAccountNameChanged = (event: any) => {
    const newValue = event.target.value;
    const idAccount = Number(newValue);
    const account = accounts.find((a) => a.idAccount === idAccount);
    if (account) {
      const newLocation = {
        ...location,
        idAccount: account.idAccount,
        account: account,
      };
      setLocation(newLocation);

      validationManager.setIsValid(validator.accountNameSelection, true);
    }
  };

  const onAddressChange = (address: Address) => {
    const newLocation = { ...location, address: address };
    setLocation(newLocation);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setErrorMessage("");

    if (!validationManager.validate()) {
      return;
    }

    try {
      if (isNew) {
        setIsBusy(true);
        await accountService.createLocation(location);
        setIsBusy(false);
      } else {
        setIsBusy(true);
        await accountService.updateLocation(location);
        setIsBusy(false);
      }
    } catch (error: any) {
      setIsBusy(false);
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    navigate("/work/locations");
  };

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = () => {
    setShowDeleteWindow(false);
    onDelete();
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const onDelete = async () => {
    setIsBusy(true);
    await accountService.deleteLocation(location);
    setIsBusy(false);
    navigate("/work/locations");
  };

  const onCancel = () => {
    navigate("/work/locations");
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>{isNew ? "Create" : "Edit"} Location</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <form
                className="row g-3"
                onSubmit={onSubmit}
                onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
              >
                {isNew && (
                  <div className="col-12">
                    <Validator
                      name={validator.accountNameSelection}
                      errorMessage="Please select an account."
                      validationManager={validationManager}
                    >
                      <div>
                        <label htmlFor="account" className="form-label">
                          Account
                        </label>
                        <select
                          className="form-select"
                          aria-label="select"
                          id="account"
                          value={
                            location.account.idAccount === 0
                              ? ""
                              : location.account.idAccount
                          }
                          onChange={onAccountNameChanged}
                        >
                          <option value="" disabled>
                            Please select
                          </option>
                          {accounts.map((account, index) => (
                            <option
                              key={index.toString()}
                              value={account.idAccount}
                            >
                              {account.accountName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Validator>
                  </div>
                )}

                {!isNew && (
                  <div className="col-12">
                    <label htmlFor="idAccount" className="form-label">
                      Account Name
                    </label>
                    <div style={{ fontWeight: "bold" }}>
                      {location.account.accountName}
                    </div>
                  </div>
                )}

                <div className="col-6">
                  <Validator
                    name={validator.locationName}
                    errorMessage="Please enter a location name."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="locationName" className="form-label">
                        Location Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="locationName"
                        value={location.locationName}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>

                <div className="col-6">
                  <Validator
                    name={validator.locationNumber}
                    errorMessage="Please enter a location number."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="locationNumber" className="form-label">
                        Location Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="locationNumber"
                        value={location.locationNumber}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>

                <div
                  className="card"
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  <div
                    className="card-header"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    Address
                  </div>
                  <div className="card-body">
                    <AddressEdit
                      inputAddress={location.address}
                      onChange={onAddressChange}
                      isNew={isNew}
                      validationManager={validationManager}
                      validationId="address"
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <label htmlFor="onSitePropertyManager" className="form-label">
                    On Site Property Manager
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="onSitePropertyManager"
                    value={location.onSitePropertyManager ?? ""}
                    onChange={onChange}
                  />
                </div>

                <div className="col-md-4">
                  <label
                    htmlFor="onSiteManagerPhoneNumber"
                    className="form-label"
                  >
                    On Site Manager Phone #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="onSiteManagerPhoneNumber"
                    value={location.onSiteManagerPhoneNumber ?? ""}
                    onChange={onChange}
                  />
                </div>

                <div className="col-md-4">
                  <label htmlFor="onSiteManagerEmail" className="form-label">
                    On Site Manager Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="onSiteManagerEmail"
                    value={location.onSiteManagerEmail ?? ""}
                    onChange={onChange}
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="siteInstructions" className="form-label">
                    Service Instructions
                  </label>
                  <textarea
                    className="form-control"
                    id="siteInstructions"
                    value={location.siteInstructions ?? ""}
                    onChange={onChange}
                    style={{ height: "100px" }}
                  />
                </div>

                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isBusy}
                      >
                        {isNew ? "Create" : "Save"}
                      </button>{" "}
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </button>
                    </div>
                    {!isNew && (
                      <div>
                        <button
                          type="reset"
                          className="btn btn-danger"
                          onClick={() => onDeleteConfirm()}
                          disabled={isBusy}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row text-center">
                  {errorMessage !== "" && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>{`Are you sure you want to delete the location?`}</div>
        </ModalWindow>
      )}
    </main>
  );
};

export default LocationEdit;
