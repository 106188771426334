import { useNavigate } from "react-router-dom";
import WorkOrder from "../../models/workOrder";

const WorkOrderLink: React.FC<{ workOrder: WorkOrder }> = ({ workOrder }) => {
  const navigate = useNavigate();

  const onLinkClick = () => {
    navigate(`/work/work-orders/${workOrder.idWorkOrder}`);
  };

  return (
    <div className="navlink" onClick={() => onLinkClick()}>
      {workOrder.workOrderNumber}
    </div>
  );
};

export default WorkOrderLink;
