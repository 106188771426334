import Account from "./account";
import Trade from "./trade";
import WorkOrderPriority from "./workOrderPriority";
import WorkOrderStatus from "./workOrderStatus";
import Location from "./location";
import Dispatch from "./dispatch";
import WorkOrderType from "./workOrderType";
import Problem from "./problem";

export default class WorkOrder {
  public idWorkOrder: number = 0;
  public idAccount: number = 0;
  public idLocation: number = 0;
  public idTrade: number = 0;
  public idProblem: number = 0;
  public workOrderTitle: string = "";
  public workOrderNumber: string = "";
  public workOrderStatus: WorkOrderStatus = WorkOrderStatus.NotDispatched;
  public workOrderType: WorkOrderType = WorkOrderType.OnDemand;
  public workOrderPriority: WorkOrderPriority = WorkOrderPriority.P224H;
  public callDate: Date = new Date();
  public trade: Trade = new Trade();
  public problem: Problem = new Problem();
  public account: Account = new Account();
  public location: Location = new Location();

  public dispatches: Dispatch[] = [];
}
