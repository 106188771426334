import User from "../models/user";
import UserGroup from "../models/userGroup";
import httpService from "./httpService";
import localStorageService from "./localStorageService";

const apiEndpoint = "/users";

const login = async (username: string, password: string): Promise<any> => {
  const { data }: any = await httpService.post(`${apiEndpoint}/login`, {
    username,
    password,
  });
  if (data.user) {
    Object.setPrototypeOf(data.user, User.prototype);
  }
  return Promise.resolve(data);
};

const getLoggedinUser = async (): Promise<User> => {
  const { data: user } = await httpService.get<any>(
    `${apiEndpoint}/getLoggedinUser`
  );
  if (user) {
    Object.setPrototypeOf(user, User.prototype);
  }
  return Promise.resolve(user);
};

const logout = () => {
  localStorageService.clearLoginData();
  httpService.removeAccessToken();
};

const updateProfile = (userProfile: User) => {
  return httpService.post(`${apiEndpoint}/updateProfile`, userProfile);
};

const changePassword = (oldPassword: string, newPassword: string) => {
  return httpService.post(`${apiEndpoint}/changePassword`, {
    oldPassword,
    newPassword,
  });
};

const getUsers = () => {
  return httpService.get<User[]>(`${apiEndpoint}/getUsers`);
};

const getUserGroups = () => {
  return httpService.get<UserGroup[]>(`${apiEndpoint}/getUserGroups`);
};

const getUserById = (idUser: number) => {
  return httpService.get<User>(`${apiEndpoint}/getUserById?idUser=${idUser}`);
};

const createUser = (user: User) => {
  return httpService.post(`${apiEndpoint}/createUser`, user);
};

const updateUser = (user: User) => {
  return httpService.post(`${apiEndpoint}/updateUser`, user);
};

const deleteUser = (user: User) => {
  return httpService.delete<User>(`${apiEndpoint}/deleteUser`, { data: user });
};

const userService = {
  login,
  getLoggedinUser,
  logout,
  updateProfile,
  changePassword,
  getUsers,
  getUserById,
  createUser,
  updateUser,
  deleteUser,
  getUserGroups,
};

export default userService;
