import React, { useEffect, useState } from "react";
import ValidationManager from "./validationManager";

const Validator: React.FC<{
  name: string;
  errorMessage: string;
  validationManager: ValidationManager;
  children: any;
}> = ({ name, errorMessage, validationManager, children }) => {
  // eslint-disable-next-line
  const [renderCount, setRenderCount] = useState<number>(0);

  const isValid = validationManager.isValid(name);

  const className =
    children.props.className + (isValid === false ? " is-invalid" : "");

  useEffect(() => {
    validationManager.removeValidator(name);
    validationManager.addValidator(name, () => {
      setRenderCount((rc) => rc + 1);
    });
    return () => {
      validationManager.removeValidator(name);
    };
  }, [validationManager, name]);

  return (
    <>
      {React.cloneElement(children, { className: className })}
      <div className="invalid-feedback">{errorMessage}</div>
    </>
  );
};

export default Validator;
