import { useEffect, useState } from "react";
import Trade from "../../models/trade";
import { useNavigate } from "react-router-dom";
import tradeService from "../../services/tradeService";
//import Address from "../../models/address";

const Trades: React.FC = () => {
  const navigate = useNavigate();

  const [trades, setTrades] = useState<Trade[]>([]);
  const [allTrades, setAllTrades] = useState<Trade[]>([]);
  const [tradeSearch, setTradeSearch] = useState<string>("");

  useEffect(() => {
    const init = async () => {
      try {
        const { data: trades } = await tradeService.getTrades();
        setTrades(trades);
        setAllTrades(trades);
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    init();
  }, []);

  const onSearchChange = (event: any) => {
    setTradeSearch(event.target.value);
    const searchToken = event.target.value.toLowerCase();
    const newTrades = allTrades.filter((a) =>
      a.tradeName.toLowerCase().includes(searchToken)
    );
    setTrades(newTrades);
  };

  const onAddNewClick = () => {
    navigate("/work/trades/new");
  };

  const onEditClick = (trade: Trade) => {
    navigate(`/work/trades/${trade.idTrade}`);
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Trades</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <div className="d-flex justify-content-between">
                <div className="search-bar" style={{ maxWidth: "500px" }}>
                  <form className="search-form d-flex align-items-center">
                    <input
                      type="text"
                      name="query"
                      placeholder="Search Trades"
                      value={tradeSearch}
                      onChange={onSearchChange}
                      title="Enter search keyword"
                    />
                    <button type="submit" title="Search">
                      <i className="bi bi-search"></i>
                    </button>
                  </form>
                </div>
                <div className="nav-item d-block d-lg-none">
                  <button
                    className="nav-link nav-icon search-bar-toggle "
                    title="Search"
                  >
                    <i className="bi bi-search"></i>
                  </button>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => onAddNewClick()}
                  >
                    Add New
                  </button>
                  <div>{`${trades.length} Trade`}</div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Trade Name</th>
                      {/* <th scope="col">Shipping Address</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Trades</th> */}
                      <th style={{ width: "50px" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {trades.map((trade: Trade) => (
                      <tr key={trade.idTrade.toString()}>
                        <td>{trade.tradeName}</td>
                        {/* <td>{Address.toString(maintenanceCompany.shippingAddress)}</td>
                        <td>{MaintenanceCompany.emailsToString(maintenanceCompany)}</td>
                        <td>{MaintenanceCompany.phonesToString(maintenanceCompany)}</td>
                        <td>{MaintenanceCompany.tradesToString(maintenanceCompany)}</td> */}
                        <td>
                          <i
                            className="ri-pencil-fill"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            onClick={() => onEditClick(trade)}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Trades;
