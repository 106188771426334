import { useContext, useEffect, useState } from "react";
import UserContext from "../../contexts/userContext";
import User from "../../models/user";
import userService from "../../services/userService";

const Profile: React.FC = () => {
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [oldPassword, setOldPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>();

  const [updateProfileOk, setUpdateProfileOk] = useState<boolean>(false);
  const [changePasswordOk, setChangePasswordOk] = useState<boolean>(false);

  const [userOverview, setUserOverview] = useState<User>(new User());

  const [errorMessageUpdateProfile, setErrorMessageUpdateProfile] =
    useState<string>("");
  const [errorMessageChangePassword, setErrorMessageChangePassword] =
    useState<string>("");

  const user = useContext<User | undefined>(UserContext);

  useEffect(() => {
    if (!user) {
      return;
    }

    // for edit
    setFirstName(user.firstName);
    setLastName(user.lastName);

    // for overview
    const newUserOverview = {
      ...new User(),
      firstName: user.firstName,
      lastName: user.lastName,
    };
    setUserOverview(newUserOverview);
  }, [user]);

  const onFirstNameChanged = (event: any) => {
    clearMessages();
    const newValue = event.target.value;
    setFirstName(newValue);
  };

  const onLastNameChanged = (event: any) => {
    clearMessages();
    const newValue = event.target.value;
    setLastName(newValue);
  };

  const onOldPasswordChanged = (event: any) => {
    clearMessages();
    const newValue = event.target.value;
    setOldPassword(newValue);
  };

  const onNewPasswordChanged = (event: any) => {
    clearMessages();
    const newValue = event.target.value;
    setNewPassword(newValue);
  };

  const onNewPasswordConfirmChanged = (event: any) => {
    clearMessages();
    const newValue = event.target.value;
    setNewPasswordConfirm(newValue);
  };

  const onUpdateProfileSubmit = async (event: any) => {
    event.preventDefault();

    clearMessages();

    if (!firstName) {
      setErrorMessageUpdateProfile("First Name cannot be empty.");
      return;
    }

    if (!lastName) {
      setErrorMessageUpdateProfile("Last Name cannot be empty.");
      return;
    }

    try {
      const userProfile = new User();
      userProfile.firstName = firstName;
      userProfile.lastName = lastName;

      await userService.updateProfile(userProfile);
    } catch (error: any) {
      let errorMessage = error.response.data;

      if (error.response.data.errors) {
        errorMessage = Object.values(error.response.data.errors)[0];
      }

      setErrorMessageUpdateProfile(errorMessage);
      return;
    }

    if (user) {
      user.firstName = firstName;
      user.lastName = lastName;

      // for overview
      const newUserOverview = {
        ...userOverview,
        firstName: user.firstName,
        lastName: user.lastName,
      };
      setUserOverview(newUserOverview);

      // for header
      if (user.onUserInfoChanged) {
        user.onUserInfoChanged();
      }
    }

    setUpdateProfileOk(true);
  };

  const onChangePasswordSubmit = async (event: any) => {
    event.preventDefault();

    clearMessages();

    if (!oldPassword) {
      setErrorMessageChangePassword("Old Password cannot be empty.");
      return;
    }

    if (!newPassword) {
      setErrorMessageChangePassword("New Password cannot be empty.");
      return;
    }

    if (!newPasswordConfirm) {
      setErrorMessageChangePassword("Confirm New Password cannot be empty.");
      return;
    }

    if (newPassword !== newPasswordConfirm) {
      setErrorMessageChangePassword(
        "New Password and Confirm New Password don't match."
      );
      return;
    }

    try {
      await userService.changePassword(oldPassword, newPassword);
    } catch (error: any) {
      let errorMessage = error.response.data;

      if (error.response.data.errors) {
        errorMessage = Object.values(error.response.data.errors)[0];
      }

      setErrorMessageChangePassword(errorMessage);
      return;
    }

    setChangePasswordOk(true);
  };

  const clearMessages = () => {
    setErrorMessageUpdateProfile("");
    setErrorMessageChangePassword("");
    setUpdateProfileOk(false);
    setChangePasswordOk(false);
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Profile</h1>
      </div>

      <section className="section profile">
        <div className="card">
          <div className="card-body pt-3">
            <ul className="nav nav-tabs nav-tabs-bordered">
              <li className="nav-item">
                <button
                  className="nav-link active"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-overview"
                >
                  Overview
                </button>
              </li>

              <li className="nav-item">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-edit"
                >
                  Edit Profile
                </button>
              </li>

              <li className="nav-item">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-change-password"
                >
                  Change Password
                </button>
              </li>
            </ul>
            <div className="tab-content pt-2">
              <div
                className="tab-pane fade show active profile-overview"
                id="profile-overview"
              >
                <h5 className="card-title">Profile Details</h5>

                <div className="row">
                  <div className="col-sm-2 label">Full Name</div>
                  <div className="col-sm-10">{`${userOverview.firstName} ${userOverview.lastName}`}</div>
                </div>

                <div className="row">
                  <div className="col-sm-2 label">Email</div>
                  <div className="col-sm-10">{user?.email}</div>
                </div>
              </div>

              <div
                className="tab-pane fade profile-edit pt-3"
                id="profile-edit"
              >
                <form onSubmit={onUpdateProfileSubmit}>
                  <div className="row mb-3">
                    <label
                      htmlFor="firstName"
                      className="col-sm-2 col-form-label"
                    >
                      First Name
                    </label>
                    <div className="col-sm-10">
                      <input
                        name="firstName"
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={firstName ?? ""}
                        onChange={onFirstNameChanged}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label
                      htmlFor="lastName"
                      className="col-sm-2 col-form-label"
                    >
                      Last Name
                    </label>
                    <div className="col-sm-10">
                      <input
                        name="lastName"
                        type="text"
                        className="form-control"
                        id="lastName"
                        value={lastName ?? ""}
                        onChange={onLastNameChanged}
                      />
                    </div>
                  </div>

                  <div>
                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>

                  <div className="col-md-12 text-center mt-3">
                    {errorMessageUpdateProfile !== "" && (
                      <div className="error-message">
                        {errorMessageUpdateProfile}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 text-center mt-3">
                    {updateProfileOk && (
                      <div className="submit-ok">Profile updated.</div>
                    )}
                  </div>
                </form>
              </div>

              <div className="tab-pane fade pt-3" id="profile-change-password">
                <form onSubmit={onChangePasswordSubmit}>
                  <div className="row mb-3">
                    <label
                      htmlFor="currentPassword"
                      className="col-sm-2 col-form-label"
                    >
                      Current Password
                    </label>
                    <div className="col-sm-10">
                      <input
                        name="password"
                        type="password"
                        className="form-control"
                        id="currentPassword"
                        value={oldPassword ?? ""}
                        onChange={onOldPasswordChanged}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label
                      htmlFor="newPassword"
                      className="col-sm-2 col-form-label"
                    >
                      New Password
                    </label>
                    <div className="col-sm-10">
                      <input
                        name="newpassword"
                        type="password"
                        className="form-control"
                        id="newPassword"
                        value={newPassword ?? ""}
                        onChange={onNewPasswordChanged}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label
                      htmlFor="renewPassword"
                      className="col-sm-2 col-form-label"
                    >
                      Re-enter New Password
                    </label>
                    <div className="col-sm-10">
                      <input
                        name="renewpassword"
                        type="password"
                        className="form-control"
                        id="renewPassword"
                        value={newPasswordConfirm ?? ""}
                        onChange={onNewPasswordConfirmChanged}
                      />
                    </div>
                  </div>

                  <div>
                    <button type="submit" className="btn btn-primary">
                      Change Password
                    </button>
                  </div>

                  <div className="col-md-12 text-center mt-3">
                    {errorMessageChangePassword !== "" && (
                      <div className="error-message">
                        {errorMessageChangePassword}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 text-center mt-3">
                    {changePasswordOk && (
                      <div className="submit-ok">
                        Password changed successfully.
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Profile;
