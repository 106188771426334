import { useNavigate } from "react-router-dom";
import WorkOrder from "../../models/workOrder";
import Dispatch from "../../models/dispatch";
import dispatchStatusHelper from "../../models/dispatchStatusHelper";

const Dispatches: React.FC<{
  workOrder: WorkOrder | undefined;
}> = ({ workOrder }) => {
  const navigate = useNavigate();

  // const onAddNewClick = (dispatch: Dispatch) => {
  //   if (!workOrder) {
  //     return;
  //   }

  //   navigate("/work/dispatches/new", {
  //     state: {
  //       workOrder: workOrder,
  //       parentDispatch: dispatch,
  //     },
  //   });
  // };

  const onViewClick = (dispatch: Dispatch) => {
    navigate(`/work/dispatches/${dispatch.idDispatch}`);
  };

  if (!workOrder) {
    return <div>No work order</div>;
  }

  return (
    <>
      <div className="d-flex justify-content-end align-items-center">
        <div>{`${workOrder?.dispatches.length} dispatches`}</div>{" "}
      </div>

      <div className="table-responsive">
        <table className="table table-hover table-responsive horizontal-center-td">
          <thead>
            <tr>
              <th scope="col">Dispatch</th>
              <th scope="col">From</th>
              <th scope="col">To</th>
              <th scope="col">From Name</th>
              <th scope="col">To Name</th>
              <th scope="col">Status</th>
              <th scope="col">Has Proposal</th>
            </tr>
          </thead>
          <tbody>
            {workOrder?.dispatches.map((dispatch: Dispatch) => (
              <tr
                key={
                  workOrder.idWorkOrder.toString() +
                  "_details_" +
                  dispatch.idDispatch.toString()
                }
              >
                <td>
                  {dispatch.idParentDispatch !== undefined && (
                    <span
                      style={{
                        display: "inline-block",
                        width: "30px",
                      }}
                    ></span>
                  )}
                  <span
                    className="navlink"
                    onClick={() => onViewClick(dispatch)}
                  >
                    {dispatch.dispatchNumber}
                  </span>
                </td>
                <td>{dispatch.dispatchFrom}</td>
                <td>{dispatch.dispatchTo}</td>
                <td>{"Account 1 (TODO)"}</td>
                <td>{dispatch.maintenanceCompany?.companyName}</td>
                <td>
                  <span
                    className={dispatchStatusHelper.statusToBadgeClass(
                      dispatch.dispatchStatus
                    )}
                  >
                    {dispatch.dispatchStatus}
                  </span>
                </td>
                <td>{dispatch.proposals.length === 0 ? "False" : "True"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Dispatches;
