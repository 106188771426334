import DispatchStatus from "./dispatchStatus";
import DispatchType from "./dispatchType";
import MaintenanceCompany from "./maintenanceCompany";
import Proposal from "./proposal";
import ServiceProvider from "./serviceProvider";
import WorkOrder from "./workOrder";

export default class Dispatch {
  public idDispatch: number = 0;
  public idWorkOrder: number = 0;

  public dispatchNumber: string = "";

  public idServiceProvider: number | undefined;
  public idMaintenanceCompany: number | undefined;
  public idEmployee: number | undefined; // TODO: Rename to IDUser

  public dispatchFrom: DispatchType = DispatchType.None;
  public dispatchTo: DispatchType = DispatchType.None;

  public dispatchStatus: DispatchStatus = DispatchStatus.Requested;

  public idParentDispatch: number | undefined;
  public parentDispatch: Dispatch | undefined;

  public workOrder: WorkOrder = new WorkOrder();

  public serviceProvider: ServiceProvider | undefined;
  public maintenanceCompany: MaintenanceCompany | undefined;
  //public Employee: employee | undefined;

  public proposals: Proposal[] = [];
}
