import { useLocation, useNavigate } from "react-router-dom";
import User from "../../models/user";
import { useContext } from "react";
import UserContext from "../../contexts/userContext";
import SubscriptionType from "../../models/subscriptionType";

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useContext<User | undefined>(UserContext);

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item" style={{ cursor: "pointer" }}>
          <div
            className={
              location.pathname === "/work" ? "nav-link" : "nav-link collapsed"
            }
            onClick={() => navigate("/work")}
          >
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </div>
        </li>

        <li className="nav-item" style={{ cursor: "pointer" }}>
          <div
            className={
              location.pathname === "/work/surveys"
                ? "nav-link"
                : "nav-link collapsed"
            }
            onClick={() => navigate("/work/surveys")}
          >
            <i className="bi bi-card-checklist"></i>
            <span>Surveys</span>
          </div>
        </li>

        {user?.currentClientAccount.subscription.subscriptionType ===
          SubscriptionType.Dev && (
          <li className="nav-item" style={{ cursor: "pointer" }}>
            <div
              className={
                location.pathname.includes("/work/work-orders") ||
                location.pathname.includes("/work/dispatches")
                  ? "nav-link"
                  : "nav-link collapsed"
              }
              onClick={() => navigate("/work/work-orders")}
            >
              <i className="bx bxs-notepad"></i>
              <span>Work Orders</span>
            </div>
          </li>
        )}

        <li className="nav-item" style={{ cursor: "pointer" }}>
          <div
            className={
              location.pathname === "/work/accounts"
                ? "nav-link"
                : "nav-link collapsed"
            }
            onClick={() => navigate("/work/accounts")}
          >
            <i className="bx bxs-contact"></i>
            <span>Accounts</span>
          </div>
        </li>

        <li className="nav-item" style={{ cursor: "pointer" }}>
          <div
            className={
              location.pathname === "/work/locations"
                ? "nav-link"
                : "nav-link collapsed"
            }
            onClick={() => navigate("/work/locations")}
          >
            <i className="bi bi-house-door-fill"></i>
            <span>Locations</span>
          </div>
        </li>

        {user?.currentClientAccount.subscription.subscriptionType ===
          SubscriptionType.Dev && (
          <li className="nav-item" style={{ cursor: "pointer" }}>
            <div
              className={
                location.pathname === "/work/service-provider"
                  ? "nav-link"
                  : "nav-link collapsed"
              }
              onClick={() => navigate("/work/service-provider")}
            >
              <i className="bx bxs-briefcase"></i>
              <span>Service Providers</span>
            </div>
          </li>
        )}

        {user?.currentClientAccount.subscription.subscriptionType ===
          SubscriptionType.Dev && (
          <li className="nav-item" style={{ cursor: "pointer" }}>
            <div
              className={
                location.pathname === "/work/maintenanceCompanies"
                  ? "nav-link"
                  : "nav-link collapsed"
              }
              onClick={() => navigate("/work/maintenanceCompanies")}
            >
              <i className="bx bxs-building"></i>
              <span>Maintenance Companies</span>
            </div>
          </li>
        )}

        {/* <li className="nav-item">
          <a className="nav-link collapsed" href="pages-contact.html">
            <i className="bx bxs-building"></i>
            <span>Maintenance Companies</span>
          </a>
        </li> */}

        {user?.currentClientAccount.subscription.subscriptionType ===
          SubscriptionType.Dev && (
          <li className="nav-item" style={{ cursor: "pointer" }}>
            <div
              className={
                location.pathname === "/work/users"
                  ? "nav-link"
                  : "nav-link collapsed"
              }
              onClick={() => navigate("/work/users")}
            >
              <i className="bi bi-person-badge-fill"></i>
              <span>Users</span>
            </div>
          </li>
        )}

        {/* <li className="nav-item">
          <a className="nav-link collapsed" href="pages-register.html">
            <i className="bi bi-person-badge-fill"></i>
            <span>Users</span>
          </a>
        </li> */}

        {user?.currentClientAccount.subscription.subscriptionType ===
          SubscriptionType.Dev && (
          <>
            <li className="nav-heading">Portal Admins</li>

            <li className="nav-item" style={{ cursor: "pointer" }}>
              <div
                className={
                  location.pathname === "/work/trades"
                    ? "nav-link"
                    : "nav-link collapsed"
                }
                onClick={() => navigate("/work/trades")}
              >
                <i className="bi bi-hammer"></i>
                <span>Trades</span>
              </div>
            </li>
          </>
        )}

        {/* <li className="nav-item">
          <a className="nav-link collapsed" href="pages-register.html">
            <i className="bi bi-hammer"></i>
            <span>Trades</span>
          </a>
        </li> */}
      </ul>
    </aside>
  );
};

export default SideBar;
