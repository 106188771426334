const eventListeners = [];

function init() {
  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  /**
   * Easy event listener function
   */

  const on = (type, el, listener, all = false) => {
    if (all) {
      select(el, true).forEach((e) => {
        e.addEventListener(type, listener);
        eventListeners.push({ el: e, type, listener });
      });
    } else {
      const element = select(el, false);
      element.addEventListener(type, listener);
      eventListeners.push({ el: element, type, listener });
    }
  };

  /**
   * Easy on scroll event listener
   */
  const onscroll = (el, listener) => {
    el.addEventListener("scroll", listener);
    eventListeners.push({ el, type: "scroll", listener });
  };

  /**
   * Sidebar toggle
   */
  if (select(".toggle-sidebar-btn")) {
    on("click", ".toggle-sidebar-btn", function (e) {
      e.stopPropagation();
      select("body").classList.toggle("toggle-sidebar");
    });
  }

  let isMobile = false;

  function onMediaQuery(mediaQuery) {
    if (mediaQuery.matches) {
      // If media query matches
      //document.body.style.backgroundColor = "yellow";
      isMobile = true;
    } else {
      //document.body.style.backgroundColor = "pink";
      isMobile = false;
    }
  }

  // Create a MediaQueryList object
  var mediaQuery = window.matchMedia("(max-width: 600px)");

  // Call listener function at run time
  onMediaQuery(mediaQuery);

  // Attach listener function on state changes
  mediaQuery.addEventListener("change", function () {
    onMediaQuery(mediaQuery);
  });

  // if (select(".sidebar")) {
  //   on("click", ".sidebar", function (e) {
  //     if (isMobile) {
  //       select("body").classList.toggle("toggle-sidebar");
  //     }
  //   });
  // }

  on("click", "body", function (e) {
    if (isMobile) {
      select("body").classList.remove("toggle-sidebar");
    }
  });

  /**
   * Search bar toggle
   */
  on(
    "click",
    ".search-bar-toggle",
    function (e) {
      getClosestElement(e.target, ".search-bar").classList.toggle(
        "search-bar-show"
      );
    },
    true
  );

  var getClosestElement = (elem, selector) => {
    let found = false;
    let currentElement = elem;

    while (!found) {
      const childElement = currentElement.querySelector(selector);
      if (childElement) {
        found = true;
        currentElement = childElement;
      } else {
        currentElement = currentElement.parentElement;
      }
    }

    return currentElement;
  };

  /**
   * Navbar links active state on scroll
   */
  let navbarlinks = select("#navbar .scrollto", true);
  const navbarlinksActive = () => {
    let position = window.scrollY + 200;
    navbarlinks.forEach((navbarlink) => {
      if (!navbarlink.hash) return;
      let section = select(navbarlink.hash);
      if (!section) return;
      if (
        position >= section.offsetTop &&
        position <= section.offsetTop + section.offsetHeight
      ) {
        navbarlink.classList.add("active");
      } else {
        navbarlink.classList.remove("active");
      }
    });
  };
  window.addEventListener("load", navbarlinksActive);
  eventListeners.push({ el: window, type: "load", navbarlinksActive });
  onscroll(document, navbarlinksActive);

  /**
   * Toggle .header-scrolled class to #header when page is scrolled
   */
  let selectHeader = select("#header");
  if (selectHeader) {
    const headerScrolled = () => {
      if (window.scrollY > 100) {
        selectHeader.classList.add("header-scrolled");
      } else {
        selectHeader.classList.remove("header-scrolled");
      }
    };
    window.addEventListener("load", headerScrolled);
    eventListeners.push({ el: window, type: "load", headerScrolled });
    onscroll(document, headerScrolled);
  }

  /**
   * Back to top button
   */
  let backtotop = select(".back-to-top");
  if (backtotop) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add("active");
      } else {
        backtotop.classList.remove("active");
      }
    };
    window.addEventListener("load", toggleBacktotop);
    eventListeners.push({ el: window, type: "load", toggleBacktotop });
    onscroll(document, toggleBacktotop);
  }

  /**
   * Initiate Bootstrap validation check
   */
  var needsValidation = document.querySelectorAll(".needs-validation");

  Array.prototype.slice.call(needsValidation).forEach(function (form) {
    function onFormSubmit(event) {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }

      form.classList.add("was-validated");
    }
    form.addEventListener("submit", onFormSubmit, false);
    eventListeners.push({ el: form, type: "submit", onFormSubmit });
  });
}

function dispose() {
  eventListeners.forEach((el) => {
    el.el.removeEventListener(el.type, el.listener);
  });
}

const template = {
  init,
  dispose,
};

export default template;
