import { useEffect, useState } from "react";
import SurveyItem from "../../models/surveyItem";
import surveyService from "../../services/surveyService";
import ModalWindow from "./ModalWindow";
import SurveyItemType from "../../models/surveyItemType";
import ConditionType from "../../models/conditionType";
import File from "../../models/file";
import SurveyCategory from "../../models/surveyCategory";
import React from "react";
import SurveyCategoryItem from "../../models/surveyCategoryItem";
import SurveyCategoryItemInstruction from "../../models/surveyCategoryItemInstruction";
import surveyItemsEditCommon from "./surveyItemsEditCommon";
import Validator from "./Validator";
import ValidationManager from "./validationManager";

type SurveyItemFile = {
  surveyItem: SurveyItem;
  file: File;
};

const SurveyItemsEdit: React.FC<{
  surveyItems: SurveyItem[];
  surveyResourceId: string;
  validationManager: ValidationManager;
  onSurveyItemsChanged?: any;
}> = ({
  surveyItems: inputSurveyItems,
  surveyResourceId,
  validationManager,
  onSurveyItemsChanged,
}) => {
  const [showDeleteItemWindow, setShowDeleteItemWindow] = useState<
    SurveyItem | undefined
  >(undefined);

  const [showDeletePhotoWindow, setShowDeletePhotoWindow] = useState<
    SurveyItemFile | undefined
  >(undefined);

  const [surveyItems, setSurveyItems] = useState<SurveyItem[]>([]);

  const [surveyCategories, setSurveyCategories] = useState<SurveyCategory[]>(
    []
  );

  const [assetTypes, setAssetTypes] = useState<string[]>([]);
  const [conditionTypes, setConditionTypes] = useState<ConditionType[]>([]);

  useEffect(() => {
    if (onSurveyItemsChanged) {
      onSurveyItemsChanged(surveyItems);
    }
  }, [surveyItems, onSurveyItemsChanged]);

  useEffect(() => {
    const setDefaultSurveyItemTypes = (surveyItems: SurveyItem[]) => {
      for (const surveyItem of surveyItems) {
        setDefaultSurveyItemType(surveyItem);
      }
    };

    setDefaultSurveyItemTypes(inputSurveyItems);

    setSurveyItems(inputSurveyItems);

    const assetTypes = getAssetTypes();
    setAssetTypes(assetTypes);

    const conditionTypes = [
      ConditionType.Good,
      ConditionType.Fair,
      ConditionType.Poor,
      ConditionType.MustBeReplaced,
      ConditionType.UnderConstruction,
    ];
    setConditionTypes(conditionTypes);

    const init = async () => {
      const { data: surveyCategories } =
        await surveyService.getSurveyCategories();

      const otherOption = new SurveyCategoryItem();
      otherOption.categoryItemName = "Other";

      let categoryItemInstruction = new SurveyCategoryItemInstruction();
      categoryItemInstruction.idSurveyCategoryItemInstruction = 1;
      categoryItemInstruction.index = 0;
      categoryItemInstruction.categoryItemInstruction =
        "Name the item accordingly.";
      otherOption.surveyCategoryItemInstructions.push(categoryItemInstruction);

      categoryItemInstruction = new SurveyCategoryItemInstruction();
      categoryItemInstruction.idSurveyCategoryItemInstruction = 2;
      categoryItemInstruction.index = 1;
      categoryItemInstruction.categoryItemInstruction =
        "Capture photos of any damage or areas of concern.";
      otherOption.surveyCategoryItemInstructions.push(categoryItemInstruction);

      categoryItemInstruction = new SurveyCategoryItemInstruction();
      categoryItemInstruction.idSurveyCategoryItemInstruction = 3;
      categoryItemInstruction.index = 2;
      categoryItemInstruction.categoryItemInstruction =
        "Take detailed notes of all observations and issues.";
      otherOption.surveyCategoryItemInstructions.push(categoryItemInstruction);

      for (const surveyCategory of surveyCategories) {
        surveyCategory.surveyCategoryItems.push(otherOption);
      }

      setSurveyCategories(surveyCategories);
    };

    init();
  }, [inputSurveyItems]);

  const setDefaultSurveyItemType = (surveyItem: SurveyItem) => {
    if (!surveyItem.surveyItemType) {
      surveyItem.surveyItemType = SurveyItemType.Category;
    }
  };

  const getAssetTypes = (): string[] => {
    return [
      "RTU",
      "Condensing Unit",
      "Hot Water Heater",
      "HVAC Equipment",
      "Appliance",
      "Other",
    ];
  };

  const onDeleteItemConfirm = (surveyItem: SurveyItem) => {
    setShowDeleteItemWindow(surveyItem);
  };

  const onDeleteItemOk = async () => {
    const surveyItem = showDeleteItemWindow;

    setShowDeleteItemWindow(undefined);

    if (surveyItem) {
      await onDeleteSurveyItem(surveyItem);
    }
  };

  const onDeleteItemCancel = () => {
    setShowDeleteItemWindow(undefined);
  };

  const onDeletePhotoConfirm = (
    event: any,
    surveyItem: SurveyItem,
    file: File
  ) => {
    setShowDeletePhotoWindow({ surveyItem, file });
  };

  const onDeletePhotoOk = async () => {
    if (!showDeletePhotoWindow) {
      return;
    }

    const { surveyItem, file } = showDeletePhotoWindow;

    setShowDeletePhotoWindow(undefined);

    if (file) {
      await onDeletePhoto(surveyItem, file);
    }
  };

  const onDeletePhotoCancel = () => {
    setShowDeletePhotoWindow(undefined);
  };

  const onSurveyItemAssetMakeChanged = (event: any, surveyItem: SurveyItem) => {
    const newValue = event.target.value;

    const existingSurveyItem = surveyItems.find(
      (si) => si.resourceId === surveyItem.resourceId
    );

    if (existingSurveyItem) {
      existingSurveyItem.assetMake = newValue;
    }

    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);
  };

  const onSurveyItemAssetModelChanged = (
    event: any,
    surveyItem: SurveyItem
  ) => {
    const newValue = event.target.value;

    const existingSurveyItem = surveyItems.find(
      (si) => si.resourceId === surveyItem.resourceId
    );

    if (existingSurveyItem) {
      existingSurveyItem.assetModel = newValue;
    }

    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);
  };

  const onSurveyItemAssetSerialChanged = (
    event: any,
    surveyItem: SurveyItem
  ) => {
    const newValue = event.target.value;

    const existingSurveyItem = surveyItems.find(
      (si) => si.resourceId === surveyItem.resourceId
    );

    if (existingSurveyItem) {
      existingSurveyItem.assetSerial = newValue;
    }

    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);
  };

  const onSurveyItemNameChanged = (event: any, surveyItem: SurveyItem) => {
    const newValue = event.target.value;

    const existingSurveyItem = surveyItems.find(
      (si) => si.resourceId === surveyItem.resourceId
    );

    if (existingSurveyItem) {
      existingSurveyItem.name = newValue;
    }

    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);
  };

  const onSurveyItemFieldsLostFocus = async (surveyItem: SurveyItem) => {
    await surveyService.updateSurveyItemFields(surveyItem);
  };

  const getCommentsFieldId = (surveyItem: SurveyItem) => {
    return surveyItemsEditCommon.getCommentsFieldId(surveyItem);
  };

  const onSurveyItemCommentsChanged = (
    event: any,
    surveyItem: SurveyItem,
    id: string
  ) => {
    const newValue = event.target.value;

    validationManager.setIsValid(
      id,
      surveyItemsEditCommon.validateCommentsField(surveyItem)
    );

    const existingSurveyItem = surveyItems.find(
      (si) => si.resourceId === surveyItem.resourceId
    );

    if (existingSurveyItem) {
      existingSurveyItem.comments = newValue;
    }

    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);
  };

  const onAddNewSurveyItemClick = async () => {
    const { data: surveyItem } = await surveyService.createSurveyItem(
      surveyResourceId
    );

    setDefaultSurveyItemType(surveyItem);

    const newSurveyItems = [...surveyItems];
    newSurveyItems.push(surveyItem);
    setSurveyItems(newSurveyItems);
  };

  const onDeleteSurveyItem = async (surveyItem: SurveyItem) => {
    const { data: surveyItems } = await surveyService.deleteSurveyItem(
      surveyResourceId,
      surveyItem.resourceId
    );

    setSurveyItems(surveyItems);
  };

  const onMoveSurveyItemUpDownClick = async (
    surveyItem: SurveyItem,
    moveUp: boolean
  ) => {
    const { data: surveyItems } = await surveyService.moveSurveyItemUpDown(
      surveyResourceId,
      surveyItem.resourceId,
      moveUp
    );

    setSurveyItems(surveyItems);

    setScrollToElementId(surveyItem.resourceId);
  };

  const [scollToElementId, setScrollToElementId] = useState<string>();

  useEffect(() => {
    if (scollToElementId) {
      //document.getElementById(scollToElementId)?.scrollIntoView({});
      scrollToTargetAdjusted(scollToElementId);

      setScrollToElementId(undefined);
    }
  }, [scollToElementId]);

  function scrollToTargetAdjusted(elementId: any) {
    var element: any = document.getElementById(elementId);
    var headerOffset = 55;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  const onFileUpload = async (
    { target: { files } }: { target: any },
    surveyItem: SurveyItem,
    existingFile: File | undefined
  ) => {
    const selectedFile = files[0];

    if (!selectedFile) {
      return;
    }

    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("surveyResourceId", surveyResourceId);
    formData.append("surveyItemResourceId", surveyItem.resourceId);

    let surveyItemFileResourceId = "";
    if (existingFile) {
      surveyItemFileResourceId = existingFile.resourceId;
    }
    formData.append("surveyItemFileResourceId", surveyItemFileResourceId);

    const { data: file } = await surveyService.uploadFile(formData);

    const existingSurveyItem = surveyItems.find(
      (si) => si.resourceId === surveyItem.resourceId
    );

    if (existingSurveyItem) {
      const existingSurveyItemFile = existingSurveyItem.files.find(
        (f) => f.resourceId === file.resourceId
      );

      if (existingSurveyItemFile) {
        // change this to make it look new to avoid file download caching
        existingSurveyItemFile.fileUrl =
          existingSurveyItemFile.fileUrl + "?" + Date.now();
      } else {
        existingSurveyItem.files.push(file);
      }
    }

    // refresh
    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);
  };

  const onDeletePhoto = async (surveyItem: SurveyItem, file: File) => {
    const { data: updatedSurveyItem } =
      await surveyService.deleteSurveyItemFile(
        surveyResourceId,
        surveyItem.resourceId,
        file.resourceId
      );

    surveyItem.files = updatedSurveyItem.files;

    // refresh
    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);
  };

  const onMoveSurveyPictureUpDownClick = async (
    surveyItem: SurveyItem,
    file: File,
    moveUp: boolean
  ) => {
    const { data: updatedSurveyItem } =
      await surveyService.moveSurveyItemFileUpDown(
        surveyResourceId,
        surveyItem.resourceId,
        file.resourceId,
        moveUp
      );

    surveyItem.files = updatedSurveyItem.files;

    // refresh
    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);

    setScrollToElementId(file.resourceId);
  };

  const onSurveyItemTypeChanged = (event: any, surveyItem: SurveyItem) => {
    let surveyItemTypeValue = event.target.value;
    let surveyItemType = event.target.value;

    if (surveyItemType !== SurveyItemType.Asset) {
      surveyItemType = SurveyItemType.Category;
    }

    const existingSurveyItem = surveyItems.find(
      (si) => si.resourceId === surveyItem.resourceId
    );

    if (existingSurveyItem) {
      existingSurveyItem.surveyItemType = surveyItemType;

      if (surveyItemType === SurveyItemType.Category) {
        existingSurveyItem.surveyCategory = surveyItemTypeValue;
        existingSurveyItem.surveyCategoryItem = "";
      }

      onSurveyItemFieldsLostFocus(existingSurveyItem);
    }

    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);
  };

  const onSurveyCategoryItemChanged = (event: any, surveyItem: SurveyItem) => {
    const newValue = event.target.value;

    const existingSurveyItem = surveyItems.find(
      (si) => si.resourceId === surveyItem.resourceId
    );

    if (existingSurveyItem) {
      existingSurveyItem.surveyCategoryItem = newValue;
      onSurveyItemFieldsLostFocus(existingSurveyItem);
    }

    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);
  };

  const onAssetTypeChanged = (event: any, surveyItem: SurveyItem) => {
    const newValue = event.target.value;

    const existingSurveyItem = surveyItems.find(
      (si) => si.resourceId === surveyItem.resourceId
    );

    if (existingSurveyItem) {
      existingSurveyItem.assetType = newValue;
      onSurveyItemFieldsLostFocus(existingSurveyItem);
    }

    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);
  };

  const onSurveyItemConditionTypeChanged = (
    event: any,
    surveyItem: SurveyItem
  ) => {
    const newValue: ConditionType = event.target.value;

    const existingSurveyItem = surveyItems.find(
      (si) => si.resourceId === surveyItem.resourceId
    );

    if (existingSurveyItem) {
      existingSurveyItem.conditionType = newValue;
      onSurveyItemFieldsLostFocus(existingSurveyItem);
    }

    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);
  };

  const onShowChangePictureOptions = (file: File) => {
    file.showChangeFileOptions = !file.showChangeFileOptions;

    //refresh
    const newSurveyItems = [...surveyItems];
    setSurveyItems(newSurveyItems);
  };

  const getSurveyCategoryInstructions = (surveyItem: SurveyItem) => {
    const surveyCategory = surveyCategories.find(
      (category) => category.categoryName === surveyItem.surveyCategory
    );

    if (!surveyCategory) {
      return [];
    }

    return surveyCategory.surveyCategoryInstructions;
  };

  const getSurveyCategoryItemInstructions = (surveyItem: SurveyItem) => {
    const surveyCategory = surveyCategories.find(
      (category) => category.categoryName === surveyItem.surveyCategory
    );

    if (!surveyCategory) {
      return [];
    }

    const surveryCategoryItem = surveyCategory.surveyCategoryItems.find(
      (categoryItem) =>
        categoryItem.categoryItemName === surveyItem.surveyCategoryItem
    );

    if (!surveryCategoryItem) {
      return [];
    }

    return surveryCategoryItem.surveyCategoryItemInstructions;
  };

  const canNotAddNew =
    surveyItemsEditCommon.canNotAddNewSurveyItem(surveyItems);

  return (
    <>
      {surveyItems.map((surveyItem: SurveyItem) => (
        <div
          className="card p-2 p-lg-4"
          key={surveyItem.resourceId}
          id={surveyItem.resourceId}
        >
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <div className="me-2">#{surveyItem.index.toString()}</div>
              <div
                className="me-2"
                style={{ cursor: "pointer" }}
                onClick={() => onMoveSurveyItemUpDownClick(surveyItem, true)}
              >
                <i
                  className="bi bi-arrow-up-square-fill"
                  style={{ fontSize: "30px", color: "var(--accent-color)" }}
                ></i>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => onMoveSurveyItemUpDownClick(surveyItem, false)}
              >
                <i
                  className="bi bi-arrow-down-square-fill"
                  style={{ fontSize: "30px", color: "var(--accent-color)" }}
                ></i>
              </div>
            </div>
            <div>
              <button
                type="reset"
                className="btn btn-danger"
                onClick={(event) => onDeleteItemConfirm(surveyItem)}
              >
                Delete
              </button>
            </div>
          </div>
          <label className="mb-1">
            Select a category based on the nature of the item you want to
            inspect
          </label>
          <div
            className="mb-3"
            onChange={(event) => onSurveyItemTypeChanged(event, surveyItem)}
          >
            {surveyCategories.map((surveyCategory: SurveyCategory) => (
              <React.Fragment key={surveyCategory.idSurveyCategory}>
                <input
                  type="radio"
                  className="btn-check"
                  name={"optionsItemTypes_" + surveyItem.resourceId}
                  id={`surveyTypeCategory_${surveyCategory.idSurveyCategory}_${surveyItem.resourceId}`}
                  autoComplete="off"
                  value={surveyCategory.categoryName}
                  defaultChecked={
                    surveyItem.surveyItemType !== SurveyItemType.Asset &&
                    surveyItem.surveyCategory === surveyCategory.categoryName
                  }
                />
                <label
                  className="btn btn-outline-primary me-2"
                  htmlFor={`surveyTypeCategory_${surveyCategory.idSurveyCategory}_${surveyItem.resourceId}`}
                >
                  {surveyCategory.categoryName}
                </label>
              </React.Fragment>
            ))}
            <input
              type="radio"
              className="btn-check"
              name={"optionsItemTypes_" + surveyItem.resourceId}
              id={"surveyTypeAsset_" + surveyItem.resourceId}
              autoComplete="off"
              value={SurveyItemType.Asset}
              defaultChecked={
                surveyItem.surveyItemType === SurveyItemType.Asset
              }
            />
            <label
              className="btn btn-outline-primary"
              htmlFor={"surveyTypeAsset_" + surveyItem.resourceId}
            >
              Asset Data Collection
            </label>
          </div>
          {surveyItem.surveyItemType === SurveyItemType.Category &&
            surveyItem.surveyCategory && (
              <>
                {getSurveyCategoryInstructions(surveyItem).length > 0 && (
                  <ul className="mb-3">
                    {getSurveyCategoryInstructions(surveyItem).map(
                      (areaInstruction) => (
                        <li key={areaInstruction.idSurveyCategoryInstruction}>
                          {areaInstruction.categoryInstruction}
                        </li>
                      )
                    )}
                  </ul>
                )}
                {surveyCategories
                  .filter(
                    (surveyCategory) =>
                      surveyCategory.categoryName === surveyItem.surveyCategory
                  )
                  .map((surveyCategory) => (
                    <div key={surveyCategory.idSurveyCategory}>
                      <div>
                        {/* <label
                          htmlFor={"surveyCategoryItem_" + surveyItem.resourceId}
                          className="form-label"
                        >
                          Category
                        </label> */}
                        <select
                          className="form-select mb-3"
                          aria-label="select"
                          id={"surveyCategoryItem_" + surveyItem.resourceId}
                          value={surveyItem.surveyCategoryItem ?? ""}
                          onChange={(event) =>
                            onSurveyCategoryItemChanged(event, surveyItem)
                          }
                        >
                          <option value="" disabled>
                            Please select
                          </option>
                          {surveyCategory.surveyCategoryItems.map(
                            (zone, index) => (
                              <option
                                key={index.toString()}
                                value={zone.categoryItemName}
                              >
                                {zone.categoryItemName}
                              </option>
                            )
                          )}
                        </select>
                        {getSurveyCategoryItemInstructions(surveyItem).length >
                          0 && (
                          <ul className="mb-3">
                            {getSurveyCategoryItemInstructions(surveyItem).map(
                              (categoryItemInstruction) => (
                                <li
                                  key={
                                    categoryItemInstruction.idSurveyCategoryItemInstruction
                                  }
                                >
                                  {
                                    categoryItemInstruction.categoryItemInstruction
                                  }
                                </li>
                              )
                            )}
                          </ul>
                        )}
                      </div>
                    </div>
                  ))}
              </>
            )}
          {surveyItem.surveyItemType === SurveyItemType.Asset && (
            <div className="mb-3">
              <div>
                <label
                  htmlFor={"assetType_" + surveyItem.resourceId}
                  className="form-label"
                >
                  Asset
                </label>
                <select
                  className="form-select"
                  aria-label="select"
                  id={"assetType_" + surveyItem.resourceId}
                  value={surveyItem.assetType ?? ""}
                  onChange={(event) => onAssetTypeChanged(event, surveyItem)}
                >
                  <option value="" disabled>
                    Please select
                  </option>
                  {assetTypes.map((itemType, index) => (
                    <option key={index.toString()} value={itemType}>
                      {itemType}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {((surveyItem.surveyItemType === SurveyItemType.Category &&
            surveyItem.surveyCategoryItem === "Other") ||
            (surveyItem.surveyItemType === SurveyItemType.Asset &&
              (surveyItem.assetType === "Other" ||
                surveyItem.assetType === "Appliance"))) && (
            <div className="mb-3">
              <label
                htmlFor={"name_" + surveyItem.resourceId}
                className="form-label"
              >
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id={"name_" + surveyItem.resourceId}
                value={surveyItem.name ?? ""}
                onChange={(event) => onSurveyItemNameChanged(event, surveyItem)}
                onBlur={() => onSurveyItemFieldsLostFocus(surveyItem)}
              />
            </div>
          )}
          {surveyItem.surveyItemType === SurveyItemType.Asset && (
            <>
              <div className="mb-3">
                <label
                  htmlFor={"assetMake_" + surveyItem.resourceId}
                  className="form-label"
                >
                  Make
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={"assetMake_" + surveyItem.resourceId}
                  value={surveyItem.assetMake ?? ""}
                  onChange={(event) =>
                    onSurveyItemAssetMakeChanged(event, surveyItem)
                  }
                  onBlur={() => onSurveyItemFieldsLostFocus(surveyItem)}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor={"assetModel_" + surveyItem.resourceId}
                  className="form-label"
                >
                  Model
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={"assetModel_" + surveyItem.resourceId}
                  value={surveyItem.assetModel ?? ""}
                  onChange={(event) =>
                    onSurveyItemAssetModelChanged(event, surveyItem)
                  }
                  onBlur={() => onSurveyItemFieldsLostFocus(surveyItem)}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor={"assetSerial_" + surveyItem.resourceId}
                  className="form-label"
                >
                  Serial
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={"assetSerial_" + surveyItem.resourceId}
                  value={surveyItem.assetSerial ?? ""}
                  onChange={(event) =>
                    onSurveyItemAssetSerialChanged(event, surveyItem)
                  }
                  onBlur={() => onSurveyItemFieldsLostFocus(surveyItem)}
                />
              </div>
            </>
          )}
          <div className="mb-3">
            <Validator
              name={surveyItemsEditCommon.getPhotosFieldId(surveyItem)}
              errorMessage="Please add photo."
              validationManager={validationManager}
            >
              <div>
                <label className="form-label">
                  Photo/photos of the inspected item
                </label>
                {surveyItem.files.map((file, index) => (
                  <div
                    key={file.resourceId}
                    id={file.resourceId}
                    className="mb-2"
                  >
                    <div className="image-container mb-2">
                      <img className="image" src={file.fileUrl} alt="" />
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ marginTop: "-3px" }}
                    >
                      <button
                        className="btn btn-primary btn-sm me-2"
                        onClick={() => onShowChangePictureOptions(file)}
                      >
                        Change Photo
                      </button>
                      {file.showChangeFileOptions && (
                        <>
                          <div
                            className="d-flex align-items-center me-2"
                            style={{
                              cursor: "pointer",
                              height: "30px",
                            }}
                            onClick={() =>
                              onMoveSurveyPictureUpDownClick(
                                surveyItem,
                                file,
                                true
                              )
                            }
                          >
                            <i
                              className="bi bi-arrow-up-square-fill"
                              style={{
                                fontSize: "30px",
                                color: "var(--accent-color)",
                              }}
                            ></i>
                          </div>
                          <div
                            className="d-flex align-items-center me-2"
                            style={{
                              cursor: "pointer",
                              height: "30px",
                            }}
                            onClick={() =>
                              onMoveSurveyPictureUpDownClick(
                                surveyItem,
                                file,
                                false
                              )
                            }
                          >
                            <i
                              className="bi bi-arrow-down-square-fill"
                              style={{
                                fontSize: "30px",
                                color: "var(--accent-color)",
                              }}
                            ></i>
                          </div>
                          <div>
                            <label
                              className="btn btn-primary btn-sm me-2"
                              title="Upload photo"
                            >
                              <i className="bi bi-upload" />
                              <input
                                type="file"
                                className="form-control"
                                multiple={false}
                                onChange={(event) =>
                                  onFileUpload(event, surveyItem, file)
                                }
                                hidden
                              />
                            </label>
                            <button
                              className="btn btn-danger btn-sm"
                              title="Delete photo"
                              onClick={(event) =>
                                onDeletePhotoConfirm(event, surveyItem, file)
                              }
                            >
                              <i className="bi bi-trash" />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
                <div className="d-flex align-items-center">
                  <div style={{ marginRight: "10px" }}>Add New Photo</div>
                  <label
                    className="btn btn-primary btn-sm"
                    title="Upload photo"
                  >
                    <i className="bi bi-upload" />
                    <input
                      type="file"
                      className="form-control"
                      multiple={false}
                      onChange={(event) =>
                        onFileUpload(event, surveyItem, undefined)
                      }
                      hidden
                    />
                  </label>
                </div>
              </div>
            </Validator>
          </div>
          <div className="mb-3">
            <Validator
              name={surveyItemsEditCommon.getConditionFieldId(surveyItem)}
              errorMessage="Please select condition."
              validationManager={validationManager}
            >
              <div>
                <label className="form-label">
                  Condition of the inspected item
                </label>
                <div
                  onChange={(event) =>
                    onSurveyItemConditionTypeChanged(event, surveyItem)
                  }
                >
                  {conditionTypes.map((conditionType, index) => (
                    <span
                      key={`condition_${conditionType}_${surveyItem.resourceId}`}
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name={`optionsConditions_${surveyItem.resourceId}`}
                        id={`condition_${conditionType}_${surveyItem.resourceId}`}
                        autoComplete="off"
                        value={conditionType}
                        defaultChecked={
                          conditionType === surveyItem.conditionType
                        }
                      />
                      <label
                        className="btn btn-outline-primary me-2"
                        htmlFor={`condition_${conditionType}_${surveyItem.resourceId}`}
                      >
                        {conditionType}
                      </label>
                    </span>
                  ))}
                </div>
              </div>
            </Validator>
          </div>
          <div className="mb-3">
            <Validator
              name={getCommentsFieldId(surveyItem)}
              errorMessage="Please enter comments."
              validationManager={validationManager}
            >
              <div>
                <label
                  htmlFor={getCommentsFieldId(surveyItem)}
                  className="form-label"
                >
                  Detail of problem/item
                </label>
                <textarea
                  style={{ height: "100px" }}
                  className="form-control"
                  id={getCommentsFieldId(surveyItem)}
                  value={surveyItem.comments ?? ""}
                  onChange={(event) =>
                    onSurveyItemCommentsChanged(
                      event,
                      surveyItem,
                      getCommentsFieldId(surveyItem)
                    )
                  }
                  onBlur={() => onSurveyItemFieldsLostFocus(surveyItem)}
                />
              </div>
            </Validator>
          </div>
        </div>
      ))}
      {canNotAddNew && (
        <>
          <label style={{ color: "red", fontWeight: "bold" }} className="mb-1">
            All fields are mandatory. Complete all before adding a new item
          </label>
          <br />
        </>
      )}
      <label className="mb-1">
        Click the button below to add a new item to your survey
      </label>
      <div className="mb-3">
        <button
          className="btn btn-primary"
          onClick={() => onAddNewSurveyItemClick()}
          disabled={canNotAddNew}
        >
          Add New Item
        </button>
      </div>
      {showDeleteItemWindow && (
        <ModalWindow
          title="Delete"
          onOk={onDeleteItemOk}
          onCancel={onDeleteItemCancel}
        >
          <div>{`Are you sure you want to delete the survey item #${showDeleteItemWindow.index}?`}</div>
        </ModalWindow>
      )}
      {showDeletePhotoWindow && (
        <ModalWindow
          title="Delete"
          onOk={onDeletePhotoOk}
          onCancel={onDeletePhotoCancel}
        >
          <div>{`Are you sure you want to delete the photo?`}</div>
        </ModalWindow>
      )}
    </>
  );
};

export default SurveyItemsEdit;
