import { Modal } from "bootstrap";
import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const portalElement: any = document.getElementById("overlays");

const ModalWindow: React.FC<{
  title: string;
  isLarge?: boolean;
  isShowOk?: boolean;
  isOkEnabled?: boolean;
  onOk?: any;
  isShowCancel?: boolean;
  onCancel?: any;
  isErrorWindow?: boolean;
  children: any;
}> = ({
  title,
  isLarge = false,
  isShowOk = true,
  isOkEnabled = true,
  onOk,
  isShowCancel = true,
  onCancel,
  isErrorWindow = false,
  children,
}) => {
  const modalRef = useRef<any>();

  const showModal = () => {
    const modalElement = modalRef.current;

    let modal = Modal.getInstance(modalElement);
    if (!modal) {
      modal = new Modal(modalElement, {
        backdrop: "static",
        keyboard: false,
      });
    }

    modal.show();
  };

  const hideModal = () => {
    const modalElement = modalRef.current;
    const modal = Modal.getInstance(modalElement);
    modal?.hide();
  };

  const cancelHandler = () => {
    hideModal();
    if (isShowCancel) {
      onCancel();
    } else {
      onOk();
    }
  };

  const okHandler = () => {
    hideModal();
    onOk();
  };

  useEffect(() => {
    showModal();
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <div className="modal fade" ref={modalRef} tabIndex={-1}>
          <div className={"modal-dialog " + (isLarge ? "modal-lg" : "")}>
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex">
                  {isErrorWindow && (
                    <i
                      style={{
                        fontSize: "40px",
                        color: "#ee2020",
                        marginTop: "-8px",
                        marginRight: "10px",
                      }}
                      className="bx bxs-error"
                    ></i>
                  )}
                  <h5 className="modal-title">{title}</h5>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  onClick={cancelHandler}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">{children}</div>
              <div className="modal-footer">
                {isShowCancel && (
                  <button
                    type="reset"
                    className="btn btn-secondary"
                    onClick={cancelHandler}
                  >
                    Cancel
                  </button>
                )}
                {isShowOk && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!isOkEnabled}
                    onClick={okHandler}
                  >
                    Ok
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>,
        portalElement
      )}
    </>
  );
};

export default ModalWindow;
