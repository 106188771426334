import { useEffect, useState } from "react";
import ServiceProvider from "../../models/serviceProvider";
import { useNavigate } from "react-router-dom";
import serviceProviderService from "../../services/serviceProviderService";
import Address from "../../models/address";

const ServiceProviders: React.FC = () => {
  const navigate = useNavigate();

  const [serviceProviders, setServiceProviders] = useState<ServiceProvider[]>(
    []
  );
  const [allServiceProviders, setAllServiceProviders] = useState<
    ServiceProvider[]
  >([]);
  const [serviceProviderSearch, setServiceProviderSearch] =
    useState<string>("");

  useEffect(() => {
    const init = async () => {
      try {
        const { data: serviceProviders } =
          await serviceProviderService.getServiceProviders();
        setServiceProviders(serviceProviders);
        setAllServiceProviders(serviceProviders);
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    init();
  }, []);

  const onSearchChange = (event: any) => {
    setServiceProviderSearch(event.target.value);
    const searchToken = event.target.value.toLowerCase();
    const newServiceProviders = allServiceProviders.filter(
      (a) => a.serviceProviderName.toLowerCase().includes(searchToken) //||
      //a.onSitePropertyManager.toLowerCase().includes(searchToken)
    );
    setServiceProviders(newServiceProviders);
  };

  const onAddNewClick = () => {
    navigate("/work/service-providers/new");
  };

  const onEditClick = (serviceProviders: ServiceProvider) => {
    navigate(`/work/service-providers/${serviceProviders.idServiceProvider}`);
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Service Providers</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <div className="d-flex justify-content-between">
                <div className="search-bar" style={{ maxWidth: "500px" }}>
                  <form className="search-form d-flex align-items-center">
                    <input
                      type="text"
                      name="query"
                      placeholder="Search Service Providers"
                      value={serviceProviderSearch}
                      onChange={onSearchChange}
                      title="Enter search keyword"
                    />
                    <button type="submit" title="Search">
                      <i className="bi bi-search"></i>
                    </button>
                  </form>
                </div>
                <div className="nav-item d-block d-lg-none">
                  <button
                    className="nav-link nav-icon search-bar-toggle "
                    title="Search"
                  >
                    <i className="bi bi-search"></i>
                  </button>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => onAddNewClick()}
                  >
                    Add New
                  </button>
                  <div>{`${serviceProviders.length} Service Providers`}</div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Service Provider Name</th>
                      <th scope="col">Shipping Address</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Trades</th>
                      <th style={{ width: "50px" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {serviceProviders.map(
                      (serviceProvider: ServiceProvider) => (
                        <tr key={serviceProvider.idServiceProvider.toString()}>
                          <td>{serviceProvider.serviceProviderName}</td>
                          <td>
                            {Address.toString(serviceProvider.shippingAddress)}
                          </td>
                          <td>
                            {ServiceProvider.emailsToString(serviceProvider)}
                          </td>
                          <td>
                            {ServiceProvider.phonesToString(serviceProvider)}
                          </td>
                          <td>
                            {ServiceProvider.tradesToString(serviceProvider)}
                          </td>
                          <td>
                            <i
                              className="ri-pencil-fill"
                              style={{ fontSize: "20px", cursor: "pointer" }}
                              onClick={() => onEditClick(serviceProvider)}
                            ></i>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ServiceProviders;
