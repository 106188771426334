import ValidationState from "./validationState";

export default class ValidationManager {
  private validationStates: ValidationState[] = [];

  public addValidator(validatorName: string, renderCallback: any) {
    const validatorState = new ValidationState();
    validatorState.name = validatorName;
    validatorState.renderCallback = renderCallback;
    this.validationStates.push(validatorState);
  }

  public removeValidator(validatorName: string) {
    this.validationStates = this.validationStates.filter(
      (vs) => vs.name !== validatorName
    );
  }

  public isValid(validatorName: string): boolean | undefined {
    const validationState = this.validationStates.find(
      (v) => v.name === validatorName
    );

    if (!validationState) {
      return true;
    }

    return validationState.isValid;
  }

  public setIsValid(validatorName: string, isValid: boolean | undefined) {
    const validationState = this.validationStates.find(
      (v) => v.name === validatorName
    );

    if (!validationState) {
      throw new Error(`Validator not found - ${validatorName}`);
    }

    validationState.isValid = isValid;
    validationState.renderCallback();
  }

  public validate(): boolean {
    let isValid = true;

    for (const validationState of this.validationStates) {
      if (!validationState.isValid) {
        isValid = false;
        validationState.isValid = false;
      }
      validationState.renderCallback();
    }

    return isValid;
  }
}
