import { useNavigate } from "react-router-dom";
import Location from "../../models/location";

const LocationLink: React.FC<{ location: Location }> = ({ location }) => {
  const navigate = useNavigate();

  const onLinkClick = () => {
    navigate(`/work/locations/${location.idLocation}`);
  };

  return (
    <div className="navlink" onClick={() => onLinkClick()}>
      {location.locationName}
    </div>
  );
};

export default LocationLink;
