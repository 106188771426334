import { useEffect, useState, useContext } from "react";
import Survey from "../../models/survey";
import { useNavigate } from "react-router-dom";
import surveyService from "../../services/surveyService";
//import AccountLink from "../../components/work/AccountLink";
import User from "../../models/user";
import UserContext from "../../contexts/userContext";
import Moment from "react-moment";
import dateTimeHelper from "../../util/datetimeHelper";
import AccountLink from "../../components/work/AccountLink";
import surveyStatusHelper from "../../models/surveyStatusHelper";

const Surveys: React.FC = () => {
  const navigate = useNavigate();
  const user = useContext<User | undefined>(UserContext);

  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [allSurveys, setAllSurveys] = useState<Survey[]>([]);
  const [surveySearch, setSurveySearch] = useState<string>("");

  useEffect(() => {
    const init = async () => {
      try {
        if (user) {
          var idClientAccount = user.currentClientAccount.idClientAccount;
          const { data: surveys } = await surveyService.getSurveys(
            idClientAccount
          );
          setSurveys(surveys);
          setAllSurveys(surveys);
        }
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    init();
  }, [user]);

  const onSearchChange = (event: any) => {
    setSurveySearch(event.target.value);
    const searchToken = event.target.value.toLowerCase();
    const newSurveys = allSurveys.filter(
      (a) =>
        a.surveyTitle.toLowerCase().includes(searchToken) ||
        a.surveyDate?.toString().toLowerCase().includes(searchToken) ||
        a.location.locationName.toLowerCase().includes(searchToken) ||
        a.surveyCode.toLowerCase().includes(searchToken)
    );
    setSurveys(newSurveys);
  };

  const onAddNewClick = () => {
    navigate("/work/surveys/new");
  };

  const onEditClick = (survey: Survey) => {
    navigate(`/work/surveys/${survey.idSurvey}`);
  };

  const getSiteUrl = () => {
    return document.location.protocol + "//" + document.location.host;
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Surveys</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <div className="d-flex justify-content-between">
                <div
                  className="search-bar d-flex"
                  style={{ maxWidth: "500px" }}
                >
                  <i
                    style={{
                      fontSize: "60px",
                      color: "var(--accent-color)",
                      marginRight: "10px",
                    }}
                    className="bi bi-card-checklist"
                  ></i>
                  <form className="search-form d-flex align-items-center">
                    <input
                      type="text"
                      name="query"
                      placeholder="Search Surveys"
                      value={surveySearch}
                      onChange={onSearchChange}
                      title="Enter search keyword"
                    />
                    <button type="submit" title="Search">
                      <i className="bi bi-search"></i>
                    </button>
                  </form>
                </div>
                <div className="nav-item d-block d-lg-none">
                  <button
                    className="nav-link nav-icon search-bar-toggle "
                    title="Search"
                  >
                    <i className="bi bi-search"></i>
                  </button>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => onAddNewClick()}
                  >
                    Add New
                  </button>
                  <div>{`${surveys.length} surveys`}</div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Survey</th>
                      <th scope="col">Code</th>
                      <th scope="col">Date</th>
                      {/* <th scope="col">Account</th> */}
                      <th scope="col">Location</th>
                      <th scope="col">Status</th>
                      <th style={{ width: "50px" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {surveys.map((survey: Survey) => (
                      <tr key={survey.idSurvey.toString()}>
                        <td>{survey.surveyTitle}</td>
                        <td>
                          <a
                            className="navlink"
                            href={
                              getSiteUrl() + "/surveys/" + survey.surveyCode
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {survey.surveyCode}
                          </a>
                        </td>
                        <td>
                          <Moment format={dateTimeHelper.dateFormat}>
                            {survey.surveyDate}
                          </Moment>
                        </td>
                        <td>{survey.location.locationName}</td>
                        {/* <td>
                          <AccountLink account={survey.location.account} />
                        </td> */}
                        <td>
                          <span
                            className={surveyStatusHelper.statusToBadgeClass(
                              survey.surveyStatus
                            )}
                          >
                            {survey.surveyStatus}
                          </span>
                        </td>
                        <td>
                          <i
                            className="ri-pencil-fill"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            onClick={() => onEditClick(survey)}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Surveys;
