import { useEffect } from "react";
import ValidationManager from "./validationManager";
import Validator from "./Validator";
import formHelper from "../../util/formHelper";
import Problem from "../../models/problem";

const ProblemEdit: React.FC<{
  item: Problem;
  onChange: any;
  isNew: boolean;
  validationManager: ValidationManager;
  validationId: string;
}> = ({ item: problem, onChange, isNew, validationManager, validationId }) => {
  const validator = {
    problemName: `${validationId}_problemName`,
    //problemTitle: `${validationId}_problemTitle`,
    //problemCode: `${validationId}_problemCode`,
  };

  useEffect(() => {
    const prob = problem;
    if (!isNew) {
      //validationManager.setIsValid(validator.problemCode, true);
      validationManager.setIsValid(validator.problemName, true);
    }
  }, [
    problem,
    validationManager,
    isNew,
    //validator.problemCode,
    validator.problemName,
  ]);

  const onProblemChange = (event: any) => {
    const { id, name, value } = formHelper.getDataFromEvent(event.target);
    const newProblem = { ...problem, [name]: value };
    onChange(newProblem);

    if (id === validator.problemName) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.problemName, isValid);
    }

    // if (id === validator.problemCode) {
    //   const isValid = value.length !== 0;
    //   validationManager.setIsValid(validator.problemCode, isValid);
    // }
  };

  return (
    <>
      <div className="col-md-7 mb-3">
        <Validator
          name={validator.problemName}
          errorMessage="Please enter a problem title."
          validationManager={validationManager}
        >
          <div>
            <input
              type="text"
              className="form-control"
              id={`${validationId}_problemName`}
              name={formHelper.nameOf<Problem>((t) => t.problemName)}
              value={problem.problemName}
              onChange={onProblemChange}
            />
          </div>
        </Validator>
      </div>
      {/* <div className="col-md-4 mb-3">
        <Validator
          name={validator.problemCode}
          errorMessage="Please enter a code."
          validationManager={validationManager}
        >
          <div>
            <input
              type="text"
              className="form-control"
              id={`${validationId}_problemCode`}
              name={formHelper.nameOf<Problem>((t) => t.problemCode)}
              value={problem.problemCode}
              onChange={onProblemChange}
            />
          </div>
        </Validator>
      </div> */}
    </>
  );
};

export default ProblemEdit;
