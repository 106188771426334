import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import Message1 from "../../assets/img/messages-1.jpg";
import Message2 from "../../assets/img/messages-2.jpg";
import Message3 from "../../assets/img/messages-3.jpg";
import { useContext, useEffect, useState } from "react";
import User from "../../models/user";
import UserContext from "../../contexts/userContext";
import SubscriptionType from "../../models/subscriptionType";

const Header: React.FC = () => {
  const navigate = useNavigate();

  const user = useContext<User | undefined>(UserContext);

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();

  useEffect(() => {
    if (!user) {
      return;
    }

    setFirstName(user.firstName);
    setLastName(user.lastName);

    user.onUserInfoChanged = () => {
      setFirstName(user.firstName);
      setLastName(user.lastName);
    };
  }, [user]);

  const onLogoClick = () => {
    navigate("/");
  };

  const onLogout = () => {
    navigate("/logout");
  };

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <div
          className="logo d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={onLogoClick}
        >
          <img src={Logo} alt="" />
          <span className="d-none d-lg-block">Work Order Express</span>
        </div>
        <i className="bi bi-list toggle-sidebar-btn"></i>
      </div>

      {user?.currentClientAccount.subscription.subscriptionType ===
        SubscriptionType.Dev && (
        <div className="search-bar search-bar-padding">
          <form className="search-form d-flex align-items-center">
            <input
              type="text"
              name="query"
              placeholder="Search"
              title="Enter search keyword"
            />
            <button type="submit" title="Search">
              <i className="bi bi-search"></i>
            </button>
          </form>
        </div>
      )}

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          {user?.currentClientAccount.subscription.subscriptionType ===
            SubscriptionType.Dev && (
            <li className="nav-item d-block d-lg-none">
              <div className="nav-link nav-icon search-bar-toggle ">
                <i className="bi bi-search"></i>
              </div>
            </li>
          )}

          {user?.currentClientAccount.subscription.subscriptionType ===
            SubscriptionType.Dev && (
            <li className="nav-item dropdown">
              <div className="nav-link nav-icon" data-bs-toggle="dropdown">
                <i className="bi bi-bell"></i>
                <span className="badge bg-primary badge-number">4</span>
              </div>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                <li className="dropdown-header">
                  {" You have 4 new notifications "}
                  <div style={{ display: "inline-block", cursor: "pointer" }}>
                    <span className="badge rounded-pill bg-primary p-2 ms-2">
                      View all
                    </span>
                  </div>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-exclamation-circle text-warning"></i>
                  <div>
                    <h4>Lorem Ipsum</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>30 min. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-x-circle text-danger"></i>
                  <div>
                    <h4>Atque rerum nesciunt</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>1 hr. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-check-circle text-success"></i>
                  <div>
                    <h4>Sit rerum fuga</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>2 hrs. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-info-circle text-primary"></i>
                  <div>
                    <h4>Dicta reprehenderit</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>4 hrs. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="dropdown-footer">
                  <div className="link">Show all notifications</div>
                </li>
              </ul>
            </li>
          )}

          {user?.currentClientAccount.subscription.subscriptionType ===
            SubscriptionType.Dev && (
            <li className="nav-item dropdown">
              <div className="nav-link nav-icon" data-bs-toggle="dropdown">
                <i className="bi bi-chat-left-text"></i>
                <span className="badge bg-success badge-number">3</span>
              </div>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                <li className="dropdown-header">
                  {" You have 3 new messages "}
                  <div style={{ display: "inline-block", cursor: "pointer" }}>
                    <span className="badge rounded-pill bg-primary p-2 ms-2">
                      View all
                    </span>
                  </div>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="message-item">
                  <div className="link">
                    <img src={Message1} alt="" className="rounded-circle" />
                    <div>
                      <h4>Maria Hudson</h4>
                      <p>
                        Velit asperiores et ducimus soluta repudiandae labore
                        officia est ut...
                      </p>
                      <p>4 hrs. ago</p>
                    </div>
                  </div>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="message-item">
                  <div className="link">
                    <img src={Message2} alt="" className="rounded-circle" />
                    <div>
                      <h4>Anna Nelson</h4>
                      <p>
                        Velit asperiores et ducimus soluta repudiandae labore
                        officia est ut...
                      </p>
                      <p>6 hrs. ago</p>
                    </div>
                  </div>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="message-item">
                  <div className="link">
                    <img src={Message3} alt="" className="rounded-circle" />
                    <div>
                      <h4>David Muldon</h4>
                      <p>
                        Velit asperiores et ducimus soluta repudiandae labore
                        officia est ut...
                      </p>
                      <p>8 hrs. ago</p>
                    </div>
                  </div>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="dropdown-footer">
                  <div className="link">Show all messages</div>
                </li>
              </ul>
            </li>
          )}

          <li className="nav-item dropdown pe-3">
            <div
              className="nav-link nav-profile d-flex align-items-center pe-0"
              data-bs-toggle="dropdown"
              style={{ cursor: "pointer" }}
            >
              {/* <img
                src={ProfileImage}
                alt="Profile"
                className="rounded-circle"
              /> */}
              <i
                className="bx bxs-user-circle"
                style={{ fontSize: "35px" }}
              ></i>
              <span className="d-none d-md-block dropdown-toggle ps-2">
                {`${firstName} ${lastName}`}
              </span>
            </div>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6>{`${firstName} ${lastName}`}</h6>
                <span>
                  Subscription Type:{" "}
                  {user?.currentClientAccount.subscription.subscriptionType}
                </span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <div
                  className="dropdown-item d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/work/profile")}
                >
                  <i className="bi bi-person"></i>
                  <span>My Profile</span>
                </div>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              {user?.currentClientAccount.subscription.subscriptionType ===
                SubscriptionType.Dev && (
                <>
                  <li>
                    <div
                      className="dropdown-item d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-gear"></i>
                      <span>Account Settings</span>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                </>
              )}

              {user?.currentClientAccount.subscription.subscriptionType ===
                SubscriptionType.Dev && (
                <>
                  <li>
                    <div
                      className="dropdown-item d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-question-circle"></i>
                      <span>Need Help?</span>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                </>
              )}

              <li>
                <div
                  className="dropdown-item d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => onLogout()}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Sign Out</span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
