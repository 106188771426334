import { useNavigate } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import { useState } from "react";
import userService from "../services/userService";
import localStorageService from "../services/localStorageService";
import LoadingIndicator from "../components/common/LoadingIndicator";
import { Helmet } from "react-helmet-async";

const Login: React.FC<{ onLoginOk: any }> = ({ onLoginOk }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const [isBusy, setIsBusy] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const onEmailChange = (event: any) => {
    const newValue = event.target.value;
    setEmail(newValue);
  };

  const onPasswordChange = (event: any) => {
    const newValue = event.target.value;
    setPassword(newValue);
  };

  const onLogoClick = () => {
    navigate("/");
  };

  const clearError = () => {
    setErrorMessage("");
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    clearError();

    try {
      setIsBusy(true);
      const data: any = await userService.login(email, password);
      setIsBusy(false);

      if (!data.success) {
        const errorMessage = data.error;
        setErrorMessage(errorMessage);

        return;
      }

      localStorageService.setLoginData(data.accessToken);

      onLoginOk(data.user);

      navigate("/work");
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
    }
  };

  return (
    <div className="container">
      <Helmet
        onChangeClientState={() => setTimeout(() => setIsLoading(false), 500)}
      >
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/app-work.css"
        />
      </Helmet>
      {isLoading && <div></div>}
      {!isLoading && (
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <div
                    className="logo d-flex align-items-center w-auto"
                    style={{ cursor: "pointer" }}
                    onClick={onLogoClick}
                  >
                    <img src={Logo} alt="" />
                    <span className="d-none d-lg-block">
                      Work Order Express
                    </span>
                  </div>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Login to Your Account
                      </h5>
                      <p className="text-center small">
                        Enter your email & password to login
                      </p>
                    </div>

                    <form
                      className="row g-3 needs-validation"
                      noValidate
                      onSubmit={onSubmit}
                    >
                      <div className="col-12">
                        <label htmlFor="yourUsername" className="form-label">
                          Email
                        </label>
                        <div className="input-group has-validation">
                          <span
                            className="input-group-text"
                            id="inputGroupPrepend"
                          >
                            @
                          </span>
                          <input
                            type="text"
                            name="username"
                            className="form-control"
                            id="yourUsername"
                            required
                            autoComplete="autoComplete"
                            value={email}
                            onChange={onEmailChange}
                          />
                          <div className="invalid-feedback">
                            Please enter your email.
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          id="yourPassword"
                          required
                          autoComplete="autoComplete"
                          value={password}
                          onChange={onPasswordChange}
                        />
                        <div className="invalid-feedback">
                          Please enter your password!
                        </div>
                      </div>

                      <div className="col-12 mt-4">
                        <button
                          disabled={isBusy}
                          className="btn btn-primary w-100"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                      {isBusy && (
                        <div className="text-center mt-3">
                          <LoadingIndicator />
                        </div>
                      )}
                      {errorMessage !== "" && (
                        <div className="col-md-12 text-center mt-3 mb-3">
                          <div className="error-message">{errorMessage}</div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Login;
