import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Survey from "../../models/survey";
import surveyService from "../../services/surveyService";
import formHelper from "../../util/formHelper";
import ModalWindow from "../../components/common/ModalWindow";
import ValidationManager from "../../components/common/validationManager";
import Validator from "../../components/common/Validator";
import accountService from "../../services/accountService";
import Location from "../../models/location";
import User from "../../models/user";
import UserContext from "../../contexts/userContext";
import SurveyItemsEdit from "../../components/common/SurveyItemsEdit";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Account from "./../../models/account";
import SurveyStatus from "../../models/surveyStatus";
import SelectOption from "../../components/common/selectedOption";
import Select from "react-select";

const validator = {
  surveyTitle: "surveyTitle",
  locationNameSelection: "locationNameSelection",
};

const SurveyEdit: React.FC = () => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const user = useContext<User | undefined>(UserContext);

  const [isNew, setIsNew] = useState<boolean>(true);
  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [isBusyCreatePdfReport, setIsBusyCreatePdfReport] =
    useState<boolean>(false);

  const [survey, setSurvey] = useState<Survey>(new Survey());

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [account, setAccount] = useState<Account>(new Account());

  const [allLocations, setAllLocations] = useState<Location[]>([]);
  const [locationsOptions, setLocationsOptions] = useState<
    SelectOption<Location>[]
  >([]);

  const [surveyStatusList, setSurveyStatusList] = useState<SurveyStatus[]>([]);

  const [validationManager] = useState<ValidationManager>(
    new ValidationManager()
  );

  const [validationManagerItems] = useState<ValidationManager>(
    new ValidationManager()
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    const isNew = idParam === "new";
    setIsNew(isNew);

    const surveyStatusList: SurveyStatus[] = [
      SurveyStatus.Open,
      SurveyStatus.Completed,
    ];
    setSurveyStatusList(surveyStatusList);

    const init = async (isNew: boolean) => {
      const { data: accounts } = await accountService.getAccounts(
        user.currentClientAccount.idClientAccount
      );
      setAccounts(accounts);

      const { data: locations } = await accountService.getLocations(
        user.currentClientAccount.idClientAccount
      );
      setAllLocations(locations);

      if (isNew) {
        const { data: generatedSurveyCode } =
          await surveyService.generateSurveyCode();
        const { data: generatedSurveyPassCode } =
          await surveyService.generateSurveyPassCode();

        const newSurvey = {
          ...new Survey(),
          surveyCode: generatedSurveyCode,
          surveyPassCode: generatedSurveyPassCode,
        };
        setSurvey(newSurvey);
      } else {
        const idSurvey = Number(idParam);
        const { data: survey } = await surveyService.getSurveyById(idSurvey);

        if (survey) {
          setSurvey(survey);

          validationManager.setIsValid(validator.surveyTitle, true);
        }
      }
    };

    init(isNew);
  }, [idParam, validationManager, user]);

  const getFieldAndValueByEvent = ({ id, name, type, checked, value }: any) => {
    const fieldValue = type === "checkbox" ? checked : value;
    return {
      value: fieldValue,
      id: id,
    };
  };

  const onChange = (event: any) => {
    const { id, value } = getFieldAndValueByEvent(event.target);
    const newSurvey = { ...survey, [id]: value };
    setSurvey(newSurvey);

    if (id === validator.surveyTitle) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.surveyTitle, isValid);
    }
  };

  const onAccountNameChanged = (event: any) => {
    const newValue = event.target.value;
    const idAccount = Number(newValue);
    const account = accounts.find((a) => a.idAccount === idAccount);

    if (account) {
      setAccount(account);
    }

    const filteredLocations = allLocations.filter(
      (l) => l.idAccount === account?.idAccount
    );

    if (filteredLocations) {
      const locationsOptions: SelectOption<Location>[] = [];

      for (const l of filteredLocations) {
        locationsOptions.push({
          value: l,
          label: l.locationName,
        });
      }

      setLocationsOptions(locationsOptions);
    }
  };

  const onLocationNameChanged = (event: any) => {
    const newValue: SelectOption<Location> = event;
    const idLocation = Number(newValue.value.idLocation);
    const location = allLocations.find((l) => l.idLocation === idLocation);
    if (location) {
      const newSurvey = {
        ...survey,
        idLocation: location.idLocation,
        location: location,
      };

      setSurvey(newSurvey);
      validationManager.setIsValid(validator.locationNameSelection, true);
    }
  };

  const onSurveyDateChanged = (date: Date) => {
    // note that date from react DatePicker is local time, and we need and save utc
    // else, if we leave it like that, the date when it's sent to the server, the server thinks it's utc and converts it to local (practically 2x conv) and it's a mess

    let newDate = date;

    if (date) {
      newDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
    }

    const newSurvey = { ...survey, surveyDate: newDate };
    setSurvey(newSurvey);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setErrorMessage("");

    if (!validationManager.validate()) {
      return;
    }

    try {
      if (isNew) {
        setIsBusy(true);
        await surveyService.createSurvey(survey);
        setIsBusy(false);
      } else {
        setIsBusy(true);
        await surveyService.updateSurvey(survey);
        setIsBusy(false);
      }
    } catch (error: any) {
      setIsBusy(false);
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    navigate("/work/surveys");
  };

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = () => {
    setShowDeleteWindow(false);
    onDelete();
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const onDelete = async () => {
    setIsBusy(true);
    await surveyService.deleteSurvey(survey);
    setIsBusy(false);
    navigate("/work/surveys");
  };

  const onCancel = () => {
    navigate("/work/surveys");
  };

  const onCreatePdf = async (event: any) => {
    event.preventDefault();
    setErrorMessage("");

    try {
      setIsBusyCreatePdfReport(true);
      const { data: reportFileUrl } = await surveyService.createPdfSurvey(
        survey.idSurvey
      );
      setIsBusyCreatePdfReport(false);

      const newSurvey = { ...survey, reportFileUrl: reportFileUrl };
      setSurvey(newSurvey);
    } catch (error: any) {
      setIsBusyCreatePdfReport(false);
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }
  };

  const onSurveyStatusChanged = (event: any) => {
    const newValue: SurveyStatus = event.target.value;
    const newSurvey = { ...survey, surveyStatus: newValue };
    setSurvey(newSurvey);
  };

  const onGenerateSurveyCode = async () => {
    const { data: generatedSurveyCode } =
      await surveyService.generateSurveyCode();

    const newSurvey = {
      ...survey,
      surveyCode: generatedSurveyCode,
    };
    setSurvey(newSurvey);
  };

  const onGenerateSurveyPassCode = async () => {
    const { data: generatedSurveyPassCode } =
      await surveyService.generateSurveyPassCode();

    const newSurvey = {
      ...survey,
      surveyPassCode: generatedSurveyPassCode,
    };
    setSurvey(newSurvey);
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>{isNew ? "Create" : "Edit"} Survey</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              {!isNew && (
                <div
                  style={{
                    position: "absolute",
                    right: "6px",
                    top: "6px",
                    fontSize: "10px",
                  }}
                >
                  ResourceId: {survey.resourceId}
                </div>
              )}
              <form
                className="row g-3"
                onSubmit={onSubmit}
                onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
              >
                {isNew && (
                  <>
                    <div className="col-12">
                      <div>
                        <label htmlFor="account" className="form-label">
                          Account
                        </label>
                        <select
                          className="form-select"
                          aria-label="select"
                          id="account"
                          value={
                            account?.idAccount === 0 ? "" : account?.idAccount
                          }
                          onChange={onAccountNameChanged}
                        >
                          <option value="" disabled>
                            Please select
                          </option>
                          {accounts.map((account, index) => (
                            <option
                              key={index.toString()}
                              value={account.idAccount}
                            >
                              {account.accountName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <Validator
                        name={validator.locationNameSelection}
                        errorMessage="Please select a location."
                        validationManager={validationManager}
                      >
                        <div>
                          <Select
                            placeholder="Please select"
                            classNamePrefix="select"
                            name="locationsOptions"
                            options={locationsOptions}
                            onChange={onLocationNameChanged}
                          />
                        </div>
                      </Validator>
                    </div>
                  </>
                )}
                {!isNew && (
                  <>
                    <div className="col-12">
                      <label className="form-label">Account Name</label>
                      <div style={{ fontWeight: "bold" }}>
                        {survey.location.account.accountName}
                      </div>
                    </div>
                    <div className="col-12">
                      <label className="form-label">Location Name</label>
                      <div style={{ fontWeight: "bold" }}>
                        {survey.location.locationName}
                      </div>
                    </div>
                  </>
                )}
                <div className="col-12">
                  <Validator
                    name={validator.surveyTitle}
                    errorMessage="Please enter an survey title."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="surveyTitle" className="form-label">
                        Survey Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="surveyTitle"
                        value={survey.surveyTitle}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>

                <div className="col-12">
                  <label htmlFor="surveyCode" className="form-label">
                    Survey Code
                  </label>
                  <div className="input-group" role="group">
                    <input
                      type="text"
                      className="form-control"
                      id="surveyCode"
                      value={survey.surveyCode ?? ""}
                      onChange={onChange}
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={onGenerateSurveyCode}
                    >
                      Generate
                    </button>
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="surveyPassCode" className="form-label">
                    Survey Pass Code
                  </label>
                  <div className="input-group" role="group">
                    <input
                      type="password"
                      className="form-control"
                      id="surveyPassCode"
                      autoComplete="new-password"
                      value={survey.surveyPassCode ?? ""}
                      onChange={onChange}
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={onGenerateSurveyPassCode}
                    >
                      Generate
                    </button>
                  </div>
                </div>
                <div style={{ marginTop: "6px" }}>
                  <input
                    type="checkbox"
                    id="showPassCode"
                    onChange={(e) => {
                      const passEl: any =
                        document.getElementById("surveyPassCode");
                      if (passEl) {
                        passEl.type = e.target.checked ? "text" : "password";
                      }
                    }}
                  />
                  <label
                    style={{ marginLeft: "4px" }}
                    htmlFor="showPassCode"
                    className="form-label"
                  >
                    Show Pass Code
                  </label>
                </div>

                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <label className="form-check-label" htmlFor="surveyDate">
                      Survey Date
                    </label>
                    <div className="ms-2">
                      <DatePicker
                        id="surveyDate"
                        selected={survey.surveyDate}
                        onChange={(date: Date) => onSurveyDateChanged(date)}
                        dateFormat="MMM do yyyy"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="surveyAuthor" className="form-label">
                    Survey Author
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="surveyAuthor"
                    value={survey.surveyAuthor ?? ""}
                    onChange={onChange}
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="surveyInstructions" className="form-label">
                    Survey Instructions
                  </label>
                  <textarea
                    className="form-control"
                    id="surveyInstructions"
                    value={survey.surveyInstructions ?? ""}
                    onChange={onChange}
                    style={{ height: "100px" }}
                  />
                </div>

                {!isNew && (
                  <div className="mb-3">
                    <div>
                      <label htmlFor="surveyStatus" className="form-label">
                        Status
                      </label>
                      <select
                        className="form-select"
                        aria-label="select"
                        id="surveyStatus"
                        value={survey.surveyStatus}
                        onChange={(event) => onSurveyStatusChanged(event)}
                      >
                        {surveyStatusList.map((itemType, index) => (
                          <option key={index.toString()} value={itemType}>
                            {itemType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isBusy}
                      >
                        {isNew ? "Create" : "Save"}
                      </button>{" "}
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </button>
                    </div>
                    {!isNew && (
                      <div>
                        <button
                          type="reset"
                          className="btn btn-danger"
                          onClick={() => onDeleteConfirm()}
                          disabled={isBusy}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row text-center">
                  {errorMessage !== "" && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </div>
              </form>
              {!isNew && (
                <>
                  <hr
                    style={{
                      marginTop: "23px",
                      marginBottom: "-5px",
                      borderTop: "2px solid #5d5a71",
                    }}
                  />
                  <h3 className="mt-3 mb-3">PDF Report</h3>
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-primary"
                      onClick={(event) => onCreatePdf(event)}
                      disabled={isBusy || isBusyCreatePdfReport}
                    >
                      Create PDF
                    </button>
                    {isBusyCreatePdfReport && (
                      <div className="ms-2">
                        Report generation in progress.. Please wait...
                        <span
                          className="spinner-border spinner-border-sm ms-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    )}
                    {!isBusyCreatePdfReport && survey.reportFileUrl && (
                      <a
                        className="ms-2"
                        href={survey.reportFileUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download PDF
                      </a>
                    )}
                  </div>
                  <hr
                    style={{
                      marginTop: "23px",
                      marginBottom: "-5px",
                      borderTop: "2px solid #5d5a71",
                    }}
                  />
                  <h3 className="mt-3 mb-3">Survey Items</h3>
                  <div>
                    <SurveyItemsEdit
                      surveyItems={survey.surveyItems}
                      surveyResourceId={survey.resourceId}
                      validationManager={validationManagerItems}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>{`Are you sure you want to delete the survey?`}</div>
        </ModalWindow>
      )}
    </main>
  );
};

export default SurveyEdit;
