import { useEffect } from "react";
import ValidationManager from "./validationManager";
import Validator from "./Validator";
import formHelper from "../../util/formHelper";
import EmailAddress from "../../models/emailAddress";
import EmailType from "../../models/emailType";

const EmailAddressEdit: React.FC<{
  item: EmailAddress;
  onChange: any;
  isNew: boolean;
  validationManager: ValidationManager;
  validationId: string;
}> = ({
  item: emailAddress,
  onChange,
  isNew,
  validationManager,
  validationId,
}) => {
  const validator = {
    emailType: `${validationId}_emailType`,
    email: `${validationId}_email`,
  };
  useEffect(() => {
    if (!isNew) {
      validationManager.setIsValid(validator.email, true);
      validationManager.setIsValid(validator.emailType, true);
    }
  }, [
    emailAddress,
    validationManager,
    isNew,
    validator.email,
    validator.emailType,
  ]);

  const onEmailAddressChange = (event: any) => {
    const { id, name, value } = formHelper.getDataFromEvent(event.target);
    const newEmailAddress = { ...emailAddress, [name]: value };

    onChange(newEmailAddress);

    if (id === validator.email) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.email, isValid);
    }
  };

  const onEmailTypeChanged = (event: any) => {
    const newValue = event.target.value;
    if (newValue) {
      const newEmailAddress = {
        ...emailAddress,
        emailType: newValue,
      };
      //setEmailAddress(newEmailAddress);
      onChange(newEmailAddress);

      validationManager.setIsValid(validator.emailType, true);
    }
  };

  return (
    <>
      <div className="col-md-2 mb-3">
        <Validator
          name={validator.emailType}
          errorMessage="Please select the email type."
          validationManager={validationManager}
        >
          <div>
            <select
              className="form-select"
              aria-label="select"
              id={`${validationId}_emailType`}
              name={formHelper.nameOf<EmailAddress>((t) => t.emailType)}
              value={
                emailAddress.emailType === undefined
                  ? ""
                  : emailAddress.emailType
              }
              onChange={onEmailTypeChanged}
            >
              <option value="" disabled>
                Please select
              </option>
              {Object.keys(EmailType).map((key, idx) => (
                <option key={idx} value={key}>
                  {key}
                </option>
              ))}
            </select>
          </div>
        </Validator>
      </div>

      <div className="col-md-9 mb-3">
        <Validator
          name={validator.email}
          errorMessage="Please enter an email."
          validationManager={validationManager}
        >
          <div>
            <input
              type="text"
              className="form-control"
              id={`${validationId}_email`}
              name={formHelper.nameOf<EmailAddress>((t) => t.email)}
              value={emailAddress.email}
              onChange={onEmailAddressChange}
            />
          </div>
        </Validator>
      </div>
    </>
  );
};

export default EmailAddressEdit;
