import httpService from "./httpService";
const apiEndpoint = "/time";

const getTimeZones = () => {
  return httpService.get<string[]>(`${apiEndpoint}/getTimeZones`);
};

const timeService = {
  getTimeZones,
};

export default timeService;
