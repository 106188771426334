import { useNavigate } from "react-router-dom";
import WorkOrder from "../../models/workOrder";
import workOrderStatusHelper from "../../models/workOrderStatusHelper";
import AccountLink from "../work/AccountLink";
import LocationLink from "../work/LocationLink";

const WorkOrderDetails: React.FC<{
  workOrder: WorkOrder | undefined;
}> = ({ workOrder }) => {
  const navigate = useNavigate();

  const onAddNewClick = () => {
    if (!workOrder) {
      return;
    }

    navigate("/work/dispatches/new", {
      state: {
        workOrder: workOrder,
        parentDispatch: undefined,
      },
    });
  };

  const onEditClick = (workOrder: WorkOrder) => {
    navigate(`/work/work-orders/edit/${workOrder.idWorkOrder}`);
  };

  if (!workOrder) {
    return <div>No work order</div>;
  }

  return (
    <>
      <div className="mb-3">
        <div className="d-flex align-items-center">
          <div>
            <i
              style={{
                fontSize: "50px",
                color: "var(--accent-color)",
                marginTop: "4px",
              }}
              className="bx bxs-notepad"
            ></i>
          </div>

          <div style={{ fontSize: "36px" }}>{workOrder.workOrderNumber}</div>

          <div className="align-self-start">
            <i
              className="ri-pencil-fill"
              style={{
                marginLeft: "2px",
                fontSize: "20px",
                cursor: "pointer",
              }}
              onClick={() => onEditClick(workOrder)}
            ></i>
          </div>

          <div>
            <button
              className="btn btn-primary"
              style={{ marginLeft: "20px" }}
              onClick={() => onAddNewClick()}
            >
              Dispatch
            </button>
          </div>
        </div>

        <span
          className={workOrderStatusHelper.statusToBadgeClass(
            workOrder.workOrderStatus
          )}
        >
          {workOrder.workOrderStatus}
        </span>
      </div>

      <div className="card-body profile">
        {/* Tabs Definition */}

        <ul className="nav nav-tabs nav-tabs-bordered">
          <li className="nav-item">
            <button
              className="nav-link active"
              data-bs-toggle="tab"
              data-bs-target="#work-order-overview"
            >
              Overview
            </button>
          </li>

          <li className="nav-item">
            <button
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#work-order-files"
            >
              Files
            </button>
          </li>
        </ul>

        {/* Tabs Content */}

        <div className="tab-content pt-2">
          <div
            className="tab-pane fade profile-overview active show"
            id="work-order-overview"
          >
            <div className="row">
              <div className="col-md-6">
                <h5 className="card-title">General Details</h5>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Account</div>
                  <div className="col-lg-9 col-md-8">
                    <AccountLink account={workOrder.account} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Location</div>
                  <div className="col-lg-9 col-md-8">
                    <LocationLink location={workOrder.location} />
                    <div>Address</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Caller Name</div>
                  <div className="col-lg-9 col-md-8">TODO</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">
                    Caller Phone / Ext
                  </div>
                  <div className="col-lg-9 col-md-8">TODO</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Caller Email</div>
                  <div className="col-lg-9 col-md-8">TODO</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Priority</div>
                  <div className="col-lg-9 col-md-8">
                    {workOrder.workOrderPriority}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Trade</div>
                  <div className="col-lg-9 col-md-8">
                    {workOrder.trade.tradeName}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Type</div>
                  <div className="col-lg-9 col-md-8">
                    {workOrder.workOrderType}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <h5 className="card-title">Additional Details</h5>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Completed Date</div>
                  <div className="col-lg-9 col-md-8">-</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">DNE Amount</div>
                  <div className="col-lg-9 col-md-8">TODO</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Due Date</div>
                  <div className="col-lg-9 col-md-8">TODO</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">
                    Reference Number
                  </div>
                  <div className="col-lg-9 col-md-8">TODO</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">
                    Additional Details
                  </div>
                  <div className="col-lg-9 col-md-8">-</div>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-pane fade pt-3" id="work-order-files">
            TODO
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkOrderDetails;
