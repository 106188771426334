import { useContext, useEffect, useState } from "react";
import User from "../../models/user";
import UserContext from "../../contexts/userContext";
import clientAccountService from "../../services/clientAccountService";
import ClientAccountStats from "../../models/clientAccountStats";

const Dashboard: React.FC = () => {
  const user = useContext<User | undefined>(UserContext);

  const [clientAccountStats, setClientAccountStats] =
    useState<ClientAccountStats>(new ClientAccountStats());

  useEffect(() => {
    const init = async () => {
      if (user) {
        var idClientAccount = user.currentClientAccount.idClientAccount;

        const { data: clientAccountStats } =
          await clientAccountService.getClientAccountStats(idClientAccount);

        setClientAccountStats(clientAccountStats);
      }
    };

    init();
  }, []);

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-xxl-4 col-md-6">
            <div className="card info-card sales-card">
              <div className="card-body">
                <h5 className="card-title">
                  Surveys <span>| Total</span>
                </h5>
                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i className="bi bi-card-checklist"></i>
                  </div>
                  <div className="ps-3">
                    <h6>{clientAccountStats.surveysCount}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-md-6">
            <div className="card info-card sales-card">
              <div className="card-body">
                <h5 className="card-title">
                  Accounts <span>| Total</span>
                </h5>
                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i className="bx bxs-contact"></i>
                  </div>
                  <div className="ps-3">
                    <h6>{clientAccountStats.accountsCount}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-md-6">
            <div className="card info-card sales-card">
              <div className="card-body">
                <h5 className="card-title">
                  Locations <span>| Total</span>
                </h5>
                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i className="bi bi-house-door-fill"></i>
                  </div>
                  <div className="ps-3">
                    <h6>{clientAccountStats.locationsCount}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Dashboard;
