import { useEffect } from "react";
import ValidationManager from "./validationManager";
import Validator from "./Validator";
import formHelper from "../../util/formHelper";
import PhoneNumber from "../../models/phoneNumber";
import NumberType from "../../models/numberType";

const PhoneNumberEdit: React.FC<{
  item: PhoneNumber;
  onChange: any;
  isNew: boolean;
  validationManager: ValidationManager;
  validationId: string;
}> = ({
  item: phoneNumber,
  onChange,
  isNew,
  validationManager,
  validationId,
}) => {
  const validator = {
    numberType: `${validationId}_numberType`,
    number: `${validationId}_number`,
    extension: `${validationId}_extension`,
  };

  useEffect(() => {
    if (!isNew) {
      validationManager.setIsValid(validator.extension, true);
      validationManager.setIsValid(validator.number, true);
      validationManager.setIsValid(validator.numberType, true);
    }
  }, [
    phoneNumber,
    validationManager,
    isNew,
    validator.extension,
    validator.number,
    validator.numberType,
  ]);

  const onPhoneNumberChange = (event: any) => {
    const { id, name, value } = formHelper.getDataFromEvent(event.target);
    const newPhoneNumber = { ...phoneNumber, [name]: value };
    onChange(newPhoneNumber);

    if (id === validator.extension) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.extension, isValid);
    }

    if (id === validator.number) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.number, isValid);
    }
  };

  const onNumberTypeChanged = (event: any) => {
    const newValue = event.target.value;

    if (newValue) {
      const newPhoneNumber = {
        ...phoneNumber,
        numberType: newValue,
      };
      onChange(newPhoneNumber);

      validationManager.setIsValid(validator.numberType, true);
    }
  };

  return (
    <>
      <div className="col-md-2 mb-3">
        <Validator
          name={validator.numberType}
          errorMessage="Please select the number type."
          validationManager={validationManager}
        >
          <div>
            <select
              className="form-select"
              aria-label="select"
              id={`${validationId}_numberType`}
              name={formHelper.nameOf<PhoneNumber>((t) => t.numberType)}
              value={
                phoneNumber.numberType === undefined
                  ? ""
                  : phoneNumber.numberType
              }
              onChange={onNumberTypeChanged}
            >
              <option value="" disabled>
                Please select
              </option>
              {Object.keys(NumberType).map((key, idx) => (
                <option key={idx} value={key}>
                  {key}
                </option>
              ))}
            </select>
          </div>
        </Validator>
      </div>
      <div className="col-md-7 mb-3">
        <Validator
          name={validator.number}
          errorMessage="Please enter a number."
          validationManager={validationManager}
        >
          <div>
            <input
              type="text"
              className="form-control"
              id={`${validationId}_number`}
              name={formHelper.nameOf<PhoneNumber>((t) => t.number)}
              value={phoneNumber.number}
              onChange={onPhoneNumberChange}
            />
          </div>
        </Validator>
      </div>
      <div className="col-md-2 mb-3">
        <Validator
          name={validator.extension}
          errorMessage="Please enter an extension."
          validationManager={validationManager}
        >
          <div>
            <input
              type="text"
              className="form-control"
              id={`${validationId}_extension`}
              name={formHelper.nameOf<PhoneNumber>((t) => t.extension)}
              value={phoneNumber.extension}
              onChange={onPhoneNumberChange}
            />
          </div>
        </Validator>
      </div>
    </>
  );
};

export default PhoneNumberEdit;
