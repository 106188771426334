import SurveyStatus from "./surveyStatus";

const statusToBadgeClass = (status: SurveyStatus) => {
  let badgeTypeClass = "";

  switch (status) {
    case SurveyStatus.Open:
      badgeTypeClass = "bg-primary";
      break;

    case SurveyStatus.Completed:
      badgeTypeClass = "bg-success";
      break;

    default:
      break;
  }

  return "badge " + badgeTypeClass;
};

const surveyStatusHelper = {
  statusToBadgeClass,
};

export default surveyStatusHelper;
