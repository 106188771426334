//import "./AppWork.css";

import React, { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./pages/work/Dashboard";
import Header from "./components/work/Header";
import SideBar from "./components/work/Sidebar";
import { useEffect } from "react";
import tooltips from "./util/tooltips";
import template from "./assets/js/main";
import Footer from "./components/work/Footer";
import Accounts from "./pages/work/Accounts";
import AccountEdit from "./pages/work/AccountEdit";
import ServiceProviders from "./pages/work/ServiceProviders";
import ServiceProviderEdit from "./pages/work/ServiceProviderEdit";
import Trades from "./pages/work/Trades";
import TradeEdit from "./pages/work/TradeEdit";
import Users from "./pages/work/Users";
import UserEdit from "./pages/work/UserEdit";
import MaintenanceCompanies from "./pages/work/MaintenanceCompanies";
import MaintenanceCompanyEdit from "./pages/work/MaintenanceCompanyEdit";
import Locations from "./pages/work/Locations";
import LocationEdit from "./pages/work/LocationEdit";
import WorkOrders from "./pages/work/WorkOrders";
import WorkExecutions from "./pages/work/WorkExecutions";
import WorkOrder from "./models/workOrder";
import DispatchNew from "./pages/work/DispatchNew";
import DispatchView from "./pages/work/DispatchView";
import WorkOrderView from "./pages/work/WorkOrderView";
import WorkOrderEdit from "./pages/work/WorkOrderEdit";
import Surveys from "./pages/work/Surveys";
import SurveyEdit from "./pages/work/SurveyEdit";
import Profile from "./pages/work/Profile";
import { Helmet } from "react-helmet-async";

const AppWork: React.FC = () => {
  const location = useLocation();

  //const [workOrder, setWorkOrder] = useState<WorkOrder | undefined>();
  const [workOrder] = useState<WorkOrder | undefined>();

  useEffect(() => {
    tooltips.init();
    template.init();

    return () => {
      tooltips.dispose();
      template.dispose();
    };
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/app-work.css"
        />
      </Helmet>
      <Header />
      <SideBar />
      <Routes location={location} key={location.pathname}>
        <Route path="/profile" element={<Profile />} />
        <Route path="/work-orders/edit/:id" element={<WorkOrderEdit />} />
        <Route path="/work-orders/new" element={<WorkOrderEdit />} />
        <Route path="/work-orders/:id" element={<WorkOrderView />} />
        <Route path="/work-orders" element={<WorkOrders />} />
        <Route path="/dispatches/new" element={<DispatchNew />} />
        <Route path="/dispatches/:id" element={<DispatchView />} />
        <Route
          path="/work-executions"
          element={<WorkExecutions workOrder={workOrder} />}
        />
        <Route path="/accounts/:id" element={<AccountEdit />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/surveys/:id" element={<SurveyEdit />} />
        <Route path="/surveys" element={<Surveys />} />
        <Route
          path="/service-providers/:id"
          element={<ServiceProviderEdit />}
        />
        <Route path="/service-providers" element={<ServiceProviders />} />
        <Route path="/users/:id" element={<UserEdit />} />
        <Route path="/users" element={<Users />} />
        <Route
          path="/maintenanceCompanies/:id"
          element={<MaintenanceCompanyEdit />}
        />
        <Route
          path="/maintenanceCompanies"
          element={<MaintenanceCompanies />}
        />
        <Route path="/locations" element={<Locations />} />
        <Route path="/locations/:id" element={<LocationEdit />} />
        <Route path="/trades/:id" element={<TradeEdit />} />
        <Route path="/trades" element={<Trades />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
      <Footer />
    </>
  );
};

export default AppWork;
