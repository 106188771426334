import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import formHelper from "../../util/formHelper";
import workOrderService from "../../services/workOrderService";
import WorkOrder from "../../models/workOrder";

const WorkOrderEdit: React.FC = () => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isNew, setIsNew] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [workOrder, setWorkOrder] = useState<WorkOrder>(new WorkOrder());

  useEffect(() => {
    const initEdit = async (idWorkOrder: number) => {
      try {
        const workOrder = await workOrderService.getWorkOrderById(idWorkOrder);

        if (workOrder) {
          setWorkOrder(workOrder);
        }
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    const isNew = location.pathname === "/work/work-orders/new";
    setIsNew(isNew);

    if (!isNew) {
      const idWorkOrder = Number(idParam);
      initEdit(idWorkOrder);
    }
  }, [idParam]);

  const onSubmit = async (event: any) => {
    event.preventDefault();

    // TODO
  };

  const onCancel = () => {
    if (isNew) {
      navigate("/work/work-orders");
    } else {
      navigate(`/work/work-orders/${workOrder.idWorkOrder}`);
    }
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>{isNew ? "Create" : "Edit"} Work Order</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <form
                className="row g-3"
                onSubmit={onSubmit}
                onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
              >
                <div className="col-12">
                  <div>
                    <label htmlFor="account" className="form-label">
                      Account
                    </label>
                    <select
                      className="form-select"
                      aria-label="select"
                      id="account"
                      value=""
                    >
                      <option value="" disabled>
                        Please select
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-12">
                  <div>
                    <label htmlFor="location" className="form-label">
                      Location
                    </label>
                    <select
                      className="form-select"
                      aria-label="select"
                      id="location"
                      value=""
                    >
                      <option value="" disabled>
                        Please select
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-12">
                  <h5 className="card-title">Caller Details</h5>
                </div>

                <div className="col-12">
                  <label htmlFor="callerName" className="form-label">
                    Caller Name / Reported by
                  </label>
                  <input type="text" className="form-control" id="callerName" />
                </div>

                <div className="col-md-4">
                  <label htmlFor="callerPhoneNumber" className="form-label">
                    Caller Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="callerPhoneNumber"
                  />
                </div>

                <div className="col-md-3">
                  <label
                    htmlFor="callerPhoneNumberExtension"
                    className="form-label"
                  >
                    Extension
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="callerPhoneNumberExtension"
                  />
                </div>

                <div className="col-md-5">
                  <label htmlFor="callerEmail" className="form-label">
                    Caller Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="callerEmail"
                  />
                </div>

                <div className="col-12">
                  <h5 className="card-title">General Details</h5>
                </div>

                <div className="col-4">
                  <div>
                    <label htmlFor="trade" className="form-label">
                      Trade
                    </label>
                    <select
                      className="form-select"
                      aria-label="select"
                      id="trade"
                      value=""
                    >
                      <option value="" disabled>
                        Please select
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-4">
                  <div>
                    <label htmlFor="problem" className="form-label">
                      Problem
                    </label>
                    <select
                      className="form-select"
                      aria-label="select"
                      id="problem"
                      value=""
                    >
                      <option value="" disabled>
                        Please select
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-4">
                  <div>
                    <label htmlFor="workOrderType" className="form-label">
                      Work Order Type
                    </label>
                    <select
                      className="form-select"
                      aria-label="select"
                      id="workOrderType"
                      value=""
                    >
                      <option value="" disabled>
                        Please select
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-4">
                  <label htmlFor="dneAmount" className="form-label">
                    DNE Amount
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="dneAmount"
                    />
                    <span className="input-group-text">$</span>
                  </div>
                </div>

                <div className="col-4">
                  <label htmlFor="poNumber" className="form-label">
                    PO #
                  </label>
                  <input type="text" className="form-control" id="poNumber" />
                </div>

                <div className="col-4">
                  <label htmlFor="dueDate" className="form-label">
                    Due Date
                  </label>
                  <input type="date" className="form-control" id="dueDate" />
                </div>

                <div className="col-4">
                  <label htmlFor="referenceCode" className="form-label">
                    Refrerence Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="referenceCode"
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="additionalDetails" className="form-label">
                    Additional Details
                  </label>
                  <textarea
                    className="form-control"
                    id="additionalDetails"
                    style={{ height: "100px" }}
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="additionalDetails" className="form-label">
                    Upload Files
                  </label>
                  <div
                    className="d-flex"
                    style={{ height: "200px", border: "1px dashed black" }}
                  >
                    <div className="mx-auto my-auto">
                      Upload Files Area - TODO
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isBusy}
                      >
                        {isNew ? "Create" : "Save"}
                      </button>{" "}
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row text-center">
                  {errorMessage !== "" && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default WorkOrderEdit;
