const Footer: React.FC<{ isFullWidth?: boolean }> = ({
  isFullWidth = false,
}) => {
  return (
    <div>
      <footer
        id="footer"
        className={"footer" + (isFullWidth ? " footerfull" : "")}
      >
        <div className="copyright">
          Copyright &copy; {new Date().getFullYear()}{" "}
          <strong>
            <span>Work Order Express</span>
          </strong>
          . All Rights Reserved
        </div>
      </footer>

      <a
        href="# "
        className="back-to-top d-flex align-items-center justify-content-center"
        style={{ cursor: "pointer" }}
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </div>
  );
};

export default Footer;
