import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MaintenanceCompany from "../../models/maintenanceCompany";
import maintenanceCompanyService from "../../services/maintenanceCompanyService";
//import timeService from "../../services/timeService";
import formHelper from "../../util/formHelper";
// import AddressEdit from "../../components/common/AddressEdit";
// import Address from "../../models/address";
import ModalWindow from "../../components/common/ModalWindow";
import ValidationManager from "../../components/common/validationManager";
import Validator from "../../components/common/Validator";
// import PhoneNumberEdit from "../../components/common/PhoneNumberEdit";
// import PhoneNumber from "../../models/phoneNumber";
// import { ItemsListEditor } from "../../components/common/ItemsListEditor";
// import EmailAddress from "../../models/emailAddress";
// import EmailAddressEdit from "../../components/common/EmailAddressEdit";
// import PaymentTerm from "../../models/paymentTerm";
// import ServiceArea from "../../models/serviceArea";
// import DispatchMethod from "../../models/dispatchMethod";
// import Trade from "../../models/trade";
// import Trades from "../../components/common/Trades";

const validator = {
  companyName: "companyName",
  // timeZoneSelector: "timeZoneSelector",
  // paymentTerm: "paymentTerm",
  // serviceArea: "serviceArea",
  // dispatchMethod: "dispatchMethod",
  // trades: "trades",
};

const MaintenanceCompanyEdit: React.FC = () => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>(true);
  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [maintenanceCompany, setMaintenanceCompany] =
    useState<MaintenanceCompany>(new MaintenanceCompany());
  //const [timeZones, setTmeZones] = useState<string[]>([]);
  const [validationManager] = useState<ValidationManager>(
    new ValidationManager()
  );

  useEffect(() => {
    // const getTimeZones = async () => {
    //   const { data: timeZones } = await timeService.getTimeZones();

    //   if (timeZones) {
    //     setTmeZones(timeZones);
    //   }
    // };

    const initEdit = async (idMaintenanceCompany: number) => {
      try {
        const { data: maintenanceCompany } =
          await maintenanceCompanyService.getMaintenanceCompanyById(
            idMaintenanceCompany
          );

        if (maintenanceCompany) {
          setMaintenanceCompany(maintenanceCompany);
        }

        // validationManager.setIsValid(validator.companyName, true);
        // if (maintenanceCompany.timeZone) {
        //   validationManager.setIsValid(validator.timeZoneSelector, true);
        // }

        // validationManager.setIsValid(validator.paymentTerm, true);
        // validationManager.setIsValid(validator.serviceArea, true);
        // validationManager.setIsValid(validator.dispatchMethod, true);
        // validationManager.setIsValid(validator.trades, true);
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    const isNew = idParam === "new";
    setIsNew(isNew);
    //getTimeZones();

    if (!isNew) {
      const idMaintenanceCompany = Number(idParam);
      initEdit(idMaintenanceCompany);
    }
  }, [idParam, validationManager]);

  const onChange = (event: any) => {
    const { id, name, value } = formHelper.getDataFromEvent(event.target);
    const newMaintenanceCompany = { ...maintenanceCompany, [name]: value };
    setMaintenanceCompany(newMaintenanceCompany);

    if (id === validator.companyName) {
      const isValid = value.length !== 0;
      validationManager.setIsValid(validator.companyName, isValid);
    }
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setErrorMessage("");

    if (!validationManager.validate()) {
      return;
    }

    try {
      if (isNew) {
        try {
          await maintenanceCompanyService.createMaintenanceCompany(
            maintenanceCompany
          );
        } catch (error: any) {
          const errorMessage = error.response.data;
          setErrorMessage(errorMessage);
          return;
        }
      } else {
        await maintenanceCompanyService.updateMaintenanceCompany(
          maintenanceCompany
        );
      }
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    navigate("/work/maintenanceCompanies");
  };

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = () => {
    setShowDeleteWindow(false);
    onDelete();
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const onDelete = async () => {
    await maintenanceCompanyService.deleteMaintenanceCompany(
      maintenanceCompany
    );
    navigate("/work/maintenanceCompanies");
  };

  const onCancel = () => {
    navigate("/work/maintenanceCompanies");
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>{isNew ? "Create" : "Edit"} Maintenance Company</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <form
                className="row g-3"
                onSubmit={onSubmit}
                onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
              >
                <div className="col-4">
                  <Validator
                    name={validator.companyName}
                    errorMessage="Please enter a maintenance company name."
                    validationManager={validationManager}
                  >
                    <div>
                      <label htmlFor="companyName" className="form-label">
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyName"
                        name={formHelper.nameOf<MaintenanceCompany>(
                          (t) => t.companyName
                        )}
                        value={maintenanceCompany.companyName}
                        onChange={onChange}
                      />
                    </div>
                  </Validator>
                </div>
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div>
                      <button type="submit" className="btn btn-primary">
                        {isNew ? "Create" : "Save"}
                      </button>{" "}
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </button>
                    </div>
                    {!isNew && (
                      <div>
                        <button
                          type="reset"
                          className="btn btn-danger"
                          onClick={() => onDeleteConfirm()}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row text-center">
                  {errorMessage !== "" && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>{`Are you sure you want to delete the Service Provider?`}</div>
        </ModalWindow>
      )}
    </main>
  );
};

export default MaintenanceCompanyEdit;
