import { useNavigate } from "react-router-dom";
import Dispatch from "../../models/dispatch";
import DispatchStatus from "../../models/dispatchStatus";
import WorkOrderLink from "./WorkOrderLink";
import dispatchStatusHelper from "../../models/dispatchStatusHelper";

const DispatchDetails: React.FC<{
  dispatch: Dispatch | undefined;
}> = ({ dispatch }) => {
  const navigate = useNavigate();

  if (!dispatch) {
    return <div>No work order dispatch</div>;
  }

  const onForwardDispatchClick = () => {
    // TODO - implement this with url params not state, so browser refresh would work all the time
    navigate("/work/dispatches/new", {
      state: {
        workOrder: dispatch.workOrder,
        parentDispatch: dispatch,
      },
    });
  };

  const onBackToDispatchesClick = () => {
    navigate(`/work/work-orders/${dispatch.idWorkOrder}`);
  };

  return (
    <>
      <div className="mb-3">
        <div className="d-flex align-items-center">
          <div>
            <i
              style={{
                fontSize: "50px",
                color: "var(--accent-color)",
                marginTop: "4px",
              }}
              className="bx bxs-notepad"
            ></i>
          </div>
          <div style={{ fontSize: "36px" }}>{dispatch.dispatchNumber}</div>

          <div>
            <button
              className="btn btn-primary"
              style={{ marginLeft: "20px" }}
              onClick={() => onForwardDispatchClick()}
            >
              Forward Dispatch
            </button>
          </div>
        </div>

        <span
          className={dispatchStatusHelper.statusToBadgeClass(
            dispatch.dispatchStatus
          )}
        >
          {dispatch.dispatchStatus}
        </span>
      </div>

      <section className="section profile card p-4">
        <div className="row profile-overview">
          <div className="col-md-6">
            <div className="row mb-3">
              <label className="col-lg-2 col-md-4 label">Dispatch</label>
              <div className="col-lg-10 col-md-8">
                {dispatch.dispatchNumber}
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-lg-2 col-md-4 label">From</label>
              <div className="col-lg-10 col-md-8">{dispatch.dispatchFrom}</div>
            </div>

            <div className="row mb-3">
              <label className="col-lg-2 col-md-4 label">To</label>
              <div className="col-lg-10 col-md-8">{dispatch.dispatchTo}</div>
            </div>

            <div className="row mb-3">
              <label className="col-lg-2 col-md-4 label">From Name</label>
              <div className="col-lg-10 col-md-8">TODO</div>
            </div>

            <div className="row mb-3">
              <label className="col-lg-2 col-md-4 label">To Name</label>
              <div className="col-lg-10 col-md-8">TODO</div>
            </div>

            <div className="row mb-3">
              <label className="col-lg-2 col-md-4 label">Status</label>
              <div className="col-lg-10 col-md-8">
                <span
                  className={dispatchStatusHelper.statusToBadgeClass(
                    dispatch.dispatchStatus
                  )}
                >
                  {dispatch.dispatchStatus}
                </span>
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-lg-2 col-md-4 label">Work Order</label>
              <div className="col-lg-10 col-md-8">
                <WorkOrderLink workOrder={dispatch.workOrder} />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label className="label">Scope of Work</label>
              <i
                className="ri-pencil-fill"
                style={{
                  marginLeft: "8px",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              ></i>
              <div>bla, bla, bla, bla</div>
            </div>
          </div>

          <div className="col-md-12">
            {dispatch.dispatchStatus === DispatchStatus.Requested ||
              (dispatch.dispatchStatus === DispatchStatus.InDiscussion && (
                <>
                  <button
                    className="btn btn-success"
                    disabled={dispatch.proposals.length === 0}
                  >
                    Accept Dispatch
                  </button>{" "}
                  <button className="btn btn-danger">Reject Dispatch</button>{" "}
                </>
              ))}
            <button
              className="btn btn-secondary"
              onClick={() => onBackToDispatchesClick()}
            >
              Back to Dispatches
            </button>
          </div>
        </div>
      </section>

      {/* <div className="card-body profile">
        <ul className="nav nav-tabs nav-tabs-bordered">
          <li className="nav-item">
            <button
              className="nav-link active"
              data-bs-toggle="tab"
              data-bs-target="#work-order-overview"
            >
              Overview
            </button>
          </li>

          <li className="nav-item">
            <button
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#work-order-files"
            >
              Files
            </button>
          </li>
        </ul>

        <div className="tab-content pt-2">
          <div
            className="tab-pane fade profile-overview active show"
            id="work-order-overview"
          >
            <div className="row">
              <div className="col-md-6">
                <h5 className="card-title">General Details</h5>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Account</div>
                  <div className="col-lg-9 col-md-8">
                    <AccountLink account={workOrder.account} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Location</div>
                  <div className="col-lg-9 col-md-8">
                    <LocationLink location={workOrder.location} />
                    <div>Address</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Caller Name</div>
                  <div className="col-lg-9 col-md-8">TODO</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">
                    Caller Phone / Ext
                  </div>
                  <div className="col-lg-9 col-md-8">TODO</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Caller Email</div>
                  <div className="col-lg-9 col-md-8">TODO</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Priority</div>
                  <div className="col-lg-9 col-md-8">
                    {workOrder.workOrderPriority}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Trade</div>
                  <div className="col-lg-9 col-md-8">
                    {workOrder.trade.tradeName}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Type</div>
                  <div className="col-lg-9 col-md-8">
                    {workOrder.workOrderType}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <h5 className="card-title">Additional Details</h5>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Completed Date</div>
                  <div className="col-lg-9 col-md-8">-</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">DNE Amount</div>
                  <div className="col-lg-9 col-md-8">TODO</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">Due Date</div>
                  <div className="col-lg-9 col-md-8">TODO</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">
                    Reference Number
                  </div>
                  <div className="col-lg-9 col-md-8">TODO</div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-4 label">
                    Additional Details
                  </div>
                  <div className="col-lg-9 col-md-8">-</div>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-pane fade pt-3" id="work-order-files">
            TODO
          </div>
        </div>
      </div> */}
    </>
  );
};

export default DispatchDetails;
