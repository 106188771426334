enum PaymentTerm {
  None = "None",
  Net15 = "Net 15",
  Net30 = "Net 30",
  Net35 = "Net 35",
  Net45 = "Net 45",
  UponCompletionEOD = "Upon Completion EOD",
}

export default PaymentTerm;
