import ClientAccountStats from "../models/clientAccountStats";
import httpService from "./httpService";

const apiEndpoint = "/clientAccounts";

const getClientAccountStats = (idClientAccount: number) => {
  return httpService.get<ClientAccountStats>(
    `${apiEndpoint}/getClientAccountStats?idClientAccount=${idClientAccount}`
  );
};

const clientAccountService = {
  getClientAccountStats,
};

export default clientAccountService;
