const useProd: boolean = true;

const appVersion: string = "1.0.0.0";

const serverUrlProd = "https://workorderexpress.com";
const serverUrlDev = "http://localhost:5050";
//const serverUrlDev = "http://amo-dell1:5050";

const serverUrl = useProd ? serverUrlProd : serverUrlDev;

const appSettings = {
  useProd,
  serverUrl,
  appVersion,
};

export default appSettings;
