import WorkOrder from "../../models/workOrder";

import WorkOrderDetails from "../../components/work-order/WorkWorderDetails";

const WorkExecutions: React.FC<{ workOrder: WorkOrder | undefined }> = ({
  workOrder,
}) => {
  if (!workOrder) {
    return <div>No work order</div>;
  }

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Work Order</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <WorkOrderDetails workOrder={workOrder} />
            </div>
          </div>
        </div>
      </section>

      <div className="pagetitle">
        <h1>Work Executions</h1>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg">
            <div className="card p-4">
              <div>TODO</div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default WorkExecutions;
