import { Navigate } from "react-router-dom";

const ProtectedRoute: React.FC<{
  isLoggedin: boolean;
  isPortalAdmin?: boolean | undefined;
  denyDemoUser?: boolean | undefined;
  children: any;
}> = ({
  isLoggedin,
  isPortalAdmin = undefined,
  denyDemoUser = undefined,
  children,
}) => {
  if (!isLoggedin) {
    return <Navigate to="/login" replace />;
  }

  if (isPortalAdmin !== undefined) {
    if (!isPortalAdmin) {
      return <Navigate to="/unauthorized" />;
    }
  }

  if (denyDemoUser !== undefined) {
    if (denyDemoUser) {
      return <Navigate to="/unauthorized" />;
    }
  }

  return children;
};

export default ProtectedRoute;
