import Account from "../models/account";
import Address from "../models/address";
import Dispatch from "../models/dispatch";
import DispatchStatus from "../models/dispatchStatus";
import DispatchType from "../models/dispatchType";
import MaintenanceCompany from "../models/maintenanceCompany";
import ProposalStatus from "../models/proposalStatus";
import ServiceProvider from "../models/serviceProvider";
import WorkOrder from "../models/workOrder";
import WorkOrderPriority from "../models/workOrderPriority";
import WorkOrderStatus from "../models/workOrderStatus";
import WorkOrderType from "../models/workOrderType";

const workOrders: WorkOrder[] = [
  {
    idWorkOrder: 1,
    idAccount: 1,
    idLocation: 0,
    idTrade: 1,
    idProblem: 1,
    workOrderTitle: "Work Order 1",
    workOrderNumber: "WO#1",
    workOrderStatus: WorkOrderStatus.NotDispatched,
    workOrderType: WorkOrderType.OnDemand,
    workOrderPriority: WorkOrderPriority.P14H,
    callDate: new Date(Date.now()),
    trade: {
      idTrade: 1,
      tradeName: "HVAC",
      problems: [
        {
          idProblem: 1,
          idTrade: 1,
          problemName: "HVAC Problem 1",
          //problemCode: "HVAC_PR1",
        },
        {
          idProblem: 2,
          idTrade: 1,
          problemName: "HVAC Problem 2",
          //problemCode: "HVAC_PR2",
        },
      ],
      serviceProviders: [],
    },
    problem: {
      idProblem: 1,
      idTrade: 1,
      problemName: "HVAC Problem 1",
      //problemCode: "HVAC_PR1",
    },
    account: {
      idAccount: 1,
      idClientAccount: 1,
      accountName: "Account 1",
      onSitePropertyManager: "",
      onSiteManagerPhoneNumber: "",
      onSiteManagerEmail: "",
      serviceInstructions: "",
      locations: [],
      shippingAddress: new Address(),
      billingAddress: new Address(),
      isSameShippingBillingAddress: true,
      currency: "USD",
    },
    location: {
      idLocation: 1,
      idAccount: 0,
      idAddress: 0,
      locationName: "Location 1",
      locationNumber: "LN1",
      onSitePropertyManager: "",
      onSiteManagerPhoneNumber: "",
      onSiteManagerEmail: "",
      siteInstructions: "",
      account: new Account(),
      address: {
        idAddress: 0,
        addressLine1: "My Home Address",
        addressLine2: "",
        city: "Las Vegas",
        state: "Nevada",
        zip: "123456",
        county: "",
        country: "USA",
      },
      surveys: [],
    },
    dispatches: [
      {
        idDispatch: 1,
        idWorkOrder: 1,
        dispatchNumber: "D#1",
        idServiceProvider: undefined,
        idMaintenanceCompany: 1,
        idEmployee: undefined,
        dispatchFrom: DispatchType.Account,
        dispatchTo: DispatchType.MaintenanceCompany,
        dispatchStatus: DispatchStatus.Requested,
        idParentDispatch: undefined,
        parentDispatch: undefined,
        workOrder: new WorkOrder(),
        serviceProvider: new ServiceProvider(),
        maintenanceCompany: {
          idMaintenanceCompany: 0,
          companyName: "MaintenanceCompany 1",
        },
        proposals: [],
      },
      {
        idDispatch: 11,
        idWorkOrder: 1,
        dispatchNumber: "D#11",
        idServiceProvider: undefined,
        idMaintenanceCompany: 11,
        idEmployee: undefined,
        dispatchFrom: DispatchType.MaintenanceCompany,
        dispatchTo: DispatchType.ServiceProvider,
        dispatchStatus: DispatchStatus.InDiscussion,
        idParentDispatch: 1,
        parentDispatch: new Dispatch(),
        workOrder: new WorkOrder(),
        serviceProvider: new ServiceProvider(),
        maintenanceCompany: {
          idMaintenanceCompany: 0,
          companyName: "Service Provider 1",
        },
        proposals: [
          {
            idProposal: 1,
            proposalFrom: DispatchType.ServiceProvider,
            proposalDate: new Date(Date.now()),
            proposalText: "I will do it for 1000$.",
            proposalStatus: ProposalStatus.Proposed,
          },
        ],
      },
      {
        idDispatch: 2,
        idWorkOrder: 1,
        dispatchNumber: "D#2",
        idServiceProvider: undefined,
        idMaintenanceCompany: 2,
        idEmployee: undefined,
        dispatchFrom: DispatchType.Account,
        dispatchTo: DispatchType.MaintenanceCompany,
        dispatchStatus: DispatchStatus.Requested,
        idParentDispatch: undefined,
        parentDispatch: undefined,
        workOrder: new WorkOrder(),
        serviceProvider: new ServiceProvider(),
        maintenanceCompany: {
          idMaintenanceCompany: 0,
          companyName: "MaintenanceCompany 2",
        },
        proposals: [],
      },
      {
        idDispatch: 3,
        idWorkOrder: 1,
        dispatchNumber: "D#3",
        idServiceProvider: undefined,
        idMaintenanceCompany: 3,
        idEmployee: undefined,
        dispatchFrom: DispatchType.Account,
        dispatchTo: DispatchType.MaintenanceCompany,
        dispatchStatus: DispatchStatus.Rejected,
        idParentDispatch: undefined,
        parentDispatch: undefined,
        workOrder: new WorkOrder(),
        serviceProvider: new ServiceProvider(),
        maintenanceCompany: {
          idMaintenanceCompany: 0,
          companyName: "MaintenanceCompany 3",
        },
        proposals: [],
      },
    ],
  },
  {
    idWorkOrder: 2,
    idAccount: 2,
    idLocation: 0,
    idTrade: 1,
    idProblem: 2,
    workOrderTitle: "Work Order 2",
    workOrderNumber: "WO#2",
    workOrderStatus: WorkOrderStatus.Dispatched,
    workOrderType: WorkOrderType.OnDemand,
    workOrderPriority: WorkOrderPriority.P224H,
    callDate: new Date(Date.now()),
    trade: {
      idTrade: 1,
      tradeName: "HVAC",
      problems: [
        {
          idProblem: 1,
          idTrade: 1,
          problemName: "",
          //problemCode: "HVAC_PR1",
        },
        {
          idProblem: 2,
          idTrade: 1,
          problemName: "",
          //problemCode: "HVAC_PR2",
        },
      ],
      serviceProviders: [],
    },
    problem: {
      idProblem: 2,
      idTrade: 1,
      problemName: "HVAC Problem 2",
      //problemCode: "HVAC_PR2",
    },
    account: {
      idAccount: 2,
      idClientAccount: 1,
      accountName: "Account 2",
      onSitePropertyManager: "",
      onSiteManagerPhoneNumber: "",
      onSiteManagerEmail: "",
      serviceInstructions: "",
      locations: [],
      shippingAddress: new Address(),
      billingAddress: new Address(),
      isSameShippingBillingAddress: true,
      currency: "USD",
    },
    location: {
      idLocation: 2,
      idAccount: 0,
      idAddress: 0,
      locationName: "Location 2",
      locationNumber: "LN2",
      onSitePropertyManager: "",
      onSiteManagerPhoneNumber: "",
      onSiteManagerEmail: "",
      siteInstructions: "",
      account: new Account(),
      address: {
        idAddress: 0,
        addressLine1: "My Home Address 2",
        addressLine2: "",
        city: "Las Vegas",
        state: "Nevada",
        zip: "123456",
        county: "",
        country: "USA",
      },
      surveys: [],
    },
    dispatches: [
      {
        idDispatch: 41,
        idWorkOrder: 2,
        dispatchNumber: "D#41",
        idServiceProvider: undefined,
        idMaintenanceCompany: 41,
        idEmployee: undefined,
        dispatchFrom: DispatchType.Account,
        dispatchTo: DispatchType.ServiceProvider,
        dispatchStatus: DispatchStatus.Accepted,
        idParentDispatch: undefined,
        parentDispatch: undefined,
        workOrder: new WorkOrder(),
        serviceProvider: new ServiceProvider(),
        maintenanceCompany: {
          idMaintenanceCompany: 0,
          companyName: "Service Provider 41",
        },
        proposals: [
          {
            idProposal: 33,
            proposalFrom: DispatchType.ServiceProvider,
            proposalDate: new Date(Date.now()),
            proposalText: "It will cost 500$.",
            proposalStatus: ProposalStatus.Proposed,
          },
        ],
      },
      {
        idDispatch: 42,
        idWorkOrder: 2,
        dispatchNumber: "D#42",
        idServiceProvider: undefined,
        idMaintenanceCompany: 42,
        idEmployee: undefined,
        dispatchFrom: DispatchType.Account,
        dispatchTo: DispatchType.ServiceProvider,
        dispatchStatus: DispatchStatus.Requested,
        idParentDispatch: undefined,
        parentDispatch: undefined,
        workOrder: new WorkOrder(),
        serviceProvider: new ServiceProvider(),
        maintenanceCompany: {
          idMaintenanceCompany: 0,
          companyName: "Service Provider 42",
        },
        proposals: [],
      },
    ],
  },
];

const maintenanceCompaies: MaintenanceCompany[] = [
  {
    idMaintenanceCompany: 1,
    companyName: "MaintenanceCompany 1",
  },
  {
    idMaintenanceCompany: 2,
    companyName: "MaintenanceCompany 2",
  },
  {
    idMaintenanceCompany: 3,
    companyName: "MaintenanceCompany 3",
  },
];

const getWorkOrders = (): Promise<WorkOrder[]> => {
  return Promise.resolve<WorkOrder[]>(workOrders);
};

const getWorkOrderById = (
  idWorkOrder: number
): Promise<WorkOrder | undefined> => {
  const workOrder = workOrders.find((wo) => wo.idWorkOrder === idWorkOrder);
  return Promise.resolve<WorkOrder | undefined>(workOrder);
};

const getDispatchById = (idDispatch: number): Promise<Dispatch | undefined> => {
  const dispatch = workOrders
    .find((w) => w.dispatches.find((d) => d.idDispatch === idDispatch))
    ?.dispatches.find((d) => d.idDispatch === idDispatch);
  return Promise.resolve<Dispatch | undefined>(dispatch);
};

const getMaintenanceCompanies = (): Promise<MaintenanceCompany[]> => {
  return Promise.resolve<MaintenanceCompany[]>(maintenanceCompaies);
};

const getServiceProviders = (): Promise<ServiceProvider[]> => {
  const serviceProviders: ServiceProvider[] = [];

  let serviceProvider = new ServiceProvider();
  serviceProvider.idServiceProvider = 1;
  serviceProvider.serviceProviderName = "Service Provider 1";
  serviceProviders.push(serviceProvider);

  serviceProvider = new ServiceProvider();
  serviceProvider.idServiceProvider = 2;
  serviceProvider.serviceProviderName = "Service Provider 2";
  serviceProviders.push(serviceProvider);

  serviceProvider = new ServiceProvider();
  serviceProvider.idServiceProvider = 3;
  serviceProvider.serviceProviderName = "Service Provider 3";
  serviceProviders.push(serviceProvider);

  return Promise.resolve<ServiceProvider[]>(serviceProviders);
};

const workOrderService = {
  getWorkOrders,
  getWorkOrderById,
  getDispatchById,
  getMaintenanceCompanies,
  getServiceProviders,
};

export default workOrderService;
