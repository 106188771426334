import Address from "./address";
import DispatchMethod from "./dispatchMethod";
import EmailAddress from "./emailAddress";
import PaymentTerm from "./paymentTerm";
import PhoneNumber from "./phoneNumber";
import ServiceArea from "./serviceArea";
import Trade from "./trade";

export default class ServiceProvider {
  public idServiceProvider: number = 0;
  public idShippingAddress: number = 0;
  public idBillingAddress: number = 0;
  public serviceProviderName: string = "";
  public serviceProviderNumber: string = "";
  public timeZone: string = "";
  public paymentTerm: PaymentTerm = PaymentTerm.None;
  public dispatchInstructions: string = "";
  public isWorkValidationRequired: boolean = false;
  public isMarkAsPreferred: boolean = false;
  public isAutoAcceptWorkOrderDispatches: boolean = false;
  public isSameShippingBillingAddress: boolean = false;
  public serviceArea: ServiceArea = ServiceArea.None;
  public dispatchMethod: DispatchMethod = DispatchMethod.None;
  public isEnableManualEmailProcess: boolean = false;
  public isUseApplicationDefaultLaborRates: boolean = false;
  public isLoadProposalDetailsAndCreateInvoiceOnStatusCompleted: boolean =
    false;
  public phoneNumbers: PhoneNumber[] = [];
  public emailAddresses: EmailAddress[] = [];
  public shippingAddress: Address = new Address();
  public billingAddress: Address = new Address();
  public trades: Trade[] = [];

  static emailsToString(serviceProvider: ServiceProvider) {
    if (serviceProvider.emailAddresses.length === 0) {
      return "";
    }
    return serviceProvider.emailAddresses.map((e) => e.email).join(", ");
  }

  static tradesToString(serviceProvider: ServiceProvider) {
    if (serviceProvider.trades.length === 0) {
      return "";
    }
    return serviceProvider.trades.map((t) => t.tradeName).join(", ");
  }
  static phonesToString(serviceProvider: ServiceProvider) {
    if (serviceProvider.phoneNumbers.length === 0) {
      return "";
    }
    return serviceProvider.phoneNumbers.map((e) => e.number).join(", ");
  }
}
