import ClientAccount from "./clientAccount";
import UserGroup from "./userGroup";

export default class User {
  public idUser: number = 0;
  public username: string = ""; //
  public email: string = ""; //
  public password: string = ""; //
  public firstName: string = "";
  public lastName: string = "";
  public isAdmin: boolean = false;
  public isPortalAdmin: boolean = false;
  public isDemoUser: boolean = false;
  public phone: string = "";
  public phoneExtension: string = "";
  public title: string = "";
  public currentClientAccount: ClientAccount = new ClientAccount();
  public userGroups: UserGroup[] = [];

  public onUserInfoChanged: any;

  // public getName = () => {
  //   return `${this.firstName} ${this.lastName}`;
  // };

  // public getName(): string {
  //   return `${this.firstName} ${this.lastName}`;
  // }

  // public updateName(): void {
  //   this.name = `${this.firstName} ${this.lastName}`;
  // }   /// oana commented this because i was having compiling error when creating a new user by using spread operator
}
