export default class Address {
  public idAddress: number = 0;
  public addressLine1: string = "";
  public addressLine2: string = "";
  public city: string = "";
  public state: string = "";
  public zip: string = "";
  public county: string = "";
  public country: string = "";

  static toString(address: Address) {
    if (address == null) {
      return "";
    }

    return `${address.addressLine1 ?? ""} ${address.addressLine2 ?? ""} ${
      address.city ?? ""
    } ${address.zip ?? ""} ${address.state ?? ""} ${address.county ?? ""} ${
      address.country ?? ""
    }`;
  }
}
