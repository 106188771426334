import Account from "./account";
import Address from "./address";
import Survey from "./survey";

export default class Location {
  public idLocation: number = 0;
  public idAccount: number = 0;
  public idAddress: number = 0;
  public locationName: string = "";
  public locationNumber: string = "";
  public onSitePropertyManager: string = "";
  public onSiteManagerPhoneNumber: string = "";
  public onSiteManagerEmail: string = "";
  public siteInstructions: string = "";
  public account: Account = new Account();
  public address: Address = new Address();
  public surveys: Survey[] = [];
}
