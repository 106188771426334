import Moment from "react-moment";
import Dispatch from "../../models/dispatch";
import Proposal from "../../models/proposal";
import { useEffect, useState } from "react";
import DispatchType from "../../models/dispatchType";

const Proposals: React.FC<{
  dispatch: Dispatch;
}> = ({ dispatch }) => {
  const [showAddNew, setShowAddNew] = useState<boolean>(false);

  const [showNoProposals, setShowNoProposals] = useState<boolean>(false);

  const [proposalFrom, setProposalFrom] = useState<DispatchType>();
  const [proposalTo, setProposalTo] = useState<DispatchType>();

  const onAddNewProposalClick = () => {
    setShowAddNew(true);
  };

  const onCancelAddNew = () => {
    setShowAddNew(false);
  };

  useEffect(() => {
    if (dispatch.proposals.length === 0) {
      setShowNoProposals(true);
    }

    setProposalFrom(dispatch.dispatchTo);
    setProposalTo(dispatch.dispatchFrom);
  }, []);

  return (
    <div>
      <div className="mb-3">
        <button
          className="btn btn-primary"
          onClick={() => onAddNewProposalClick()}
        >
          Add New Proposal
        </button>
      </div>
      {showAddNew && (
        <div className="card">
          <div className="card-body">
            <h5 className="card-title-no-subtitle">New Proposal</h5>
            <div className="d-flex align-items-center mb-3">
              <label style={{ marginRight: "10px", width: "100px" }}>
                From
              </label>
              <div>
                <b>{proposalFrom}</b>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <label style={{ marginRight: "10px", width: "100px" }}>To</label>
              <div>
                <b>{proposalTo}</b>
              </div>
            </div>
            <textarea
              className="form-control mb-3"
              style={{ height: "100px" }}
            />
            <button className="btn btn-primary">Submit Proposal</button>{" "}
            <button
              className="btn btn-secondary"
              onClick={() => onCancelAddNew()}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {showNoProposals && (
        <div className="card">
          <div className="card-body">
            <h5 className="card-title-only">No Proposals</h5>
          </div>
        </div>
      )}
      {dispatch.proposals.map((proposal: Proposal) => (
        <div key={proposal.idProposal.toString()} className="card">
          <div className="card-body">
            <h5 className="card-title">
              {`Proposal from ${proposal.proposalFrom}`}
            </h5>
            <h6 className="card-subtitle mb-2 text-muted">
              <Moment format="MMM D YYYY hh:mm A">
                {proposal.proposalDate}
              </Moment>
            </h6>
            <p className="card-text">{proposal.proposalText}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Proposals;
